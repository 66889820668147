import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { error } from "console";

@Injectable({
  providedIn: 'root'
})

export class AzureService {
  clientid:any=null;
  issuer:any=null
 
  constructor(private oauthService: OAuthService, private router: Router) {
      if (window.location.hostname == 'prodigious.teamium.net'){
          this.clientid = '5f1fa8e6-dd4e-4613-bd2c-a4e5719e078f'
          this.issuer = 'd52c9ea1-7c21-47b1-82a3-33a74b1f74b8'
        } else {
          this.clientid = '8bcc341f-698d-417d-91e4-435b1054c9b7'
          this.issuer = 'ede4b84a-ae0d-4d37-a1c4-32f7e7fe5614'
        }
  
  }

  public okta: AuthConfig = {
      issuer: `https://login.microsoftonline.com/${this.issuer}/v2.0`,
      oidc: true,
      redirectUri: window.location.origin +'/signin',
      clientId: this.clientid,
      scope: 'openid profile email',
      showDebugInformation: true,
  };





  public configure() {
    if (window.location.hostname == 'prodigious.teamium.net'){
      this.clientid = '5f1fa8e6-dd4e-4613-bd2c-a4e5719e078f'
      this.issuer = 'd52c9ea1-7c21-47b1-82a3-33a74b1f74b8'
      let issue  = `https://login.microsoftonline.com/${this.issuer}/v2.0`
      this.okta.issuer = issue
    } else {
      this.clientid = '8bcc341f-698d-417d-91e4-435b1054c9b7'
      this.issuer = 'ede4b84a-ae0d-4d37-a1c4-32f7e7fe5614'
      let issue  = `https://login.microsoftonline.com/${this.issuer}/v2.0`
      this.okta.issuer = issue
    }
 
      const oauthService = this.oauthService;
      const router = this.router;
      this.okta.clientId = this.clientid;
      oauthService.configure(this.okta);
      oauthService.tokenValidationHandler = new JwksValidationHandler();
      oauthService.showDebugInformation = true;
      oauthService.strictDiscoveryDocumentValidation = false;
      oauthService.loadDiscoveryDocument().then((doc) => {
        console.log(doc)
          oauthService.tryLogin({
              onTokenReceived: (context) => {
                // console.log(context)
                  router.navigate(['/signin']);
              },
              onLoginError: (context) => {
                  console.log(context)
              }
          });
      });
  }

  public getClaims() {
      return this.oauthService.getIdentityClaims();
  }
}
