import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HttpService } from "./http.service";
import { ToastrTranslateService } from "./toastr-translate.service";
import { UrlsService } from "./urls.service";
import { exhaust, exhaustMap, shareReplay, switchMap, take } from 'rxjs/operators';
declare var $: any;

@Injectable({
  providedIn: "root",
})
export class CommonUtilService {

  private requestQueue: any[] = [];
  private isProcessing = false;

  private requestQueueAllStaff: any[] = [];
  private isProcessingAllStaff = false;

  private requestQueueAllStaffSearch: any[] = [];
  private isProcessingAllStaffSearch = false;

  private requestQueueBasicStaff: any[] = [];
  private isProcessingBasicStaff = false;

  private requestQueueAvailableFunction: any[] = [];
  public isProcessingAvailableFunction = false;

  private requestQueueAvailableresources: any[] = [];
  public isProcessingAvailableresources = false;

  private requestQueueAvailableresourcesPersonal: any[] = [];
  public isProcessingAvailableresourcesPersonal = false;

  private requestQueueAvailableresourcesContract: any[] = [];
  public isProcessingAvailableresourcesContract = false;

  public requestQueueAvailableresourcesPersonalSkill: any[] = [];
  public isProcessingAvailableresourcesPersonalSkill = false;

  public requestQueueAvailabledefaults: any[] = [];
  public isProcessingAvailabledefaults = false;

  public requestQueuegetAllPersonelProjectById: any[] = [];
  public isProcessinggetAllPersonelProjectById = false;
  private dataCacheMain: any;

  public requestQueuegetAllPersonelProjectLocation: any[] = [];
  public isProcessinggetAllPersonelProjectLocation = false;
  private dataCacheLocation: any;

  public requestQueuegetAllPersonelProjectdirector: any[] = [];
  public isProcessinggetAllPersonelProjectdirector = false;
  private dataCacheDirector: any;

  public requestQueuegetAllPersonelProjectByAllUserSetting: any[] = [];
  public isProcessinggetAllPersonelProjectByAllUserSetting = false;
  private dataCacheAllUserSetting: any;

  public requestQueuegetAllPersonelProjectByAllContractSetting: any[] = [];
  public isProcessinggetAllPersonelProjectByAllContractSetting = false;
  private dataCacheAllContractSetting: any;  

  public requestQueuegetAllPersonelProjectByAllResource: any[] = [];
  public isProcessinggetAllPersonelProjectByAllResource = false;
  private dataCacheAllResource: any;  



  constructor(
    private httpService: HttpService,
    private toastr: ToastrTranslateService,
    private http: HttpClient,
    private url: UrlsService
  ) {}
  getDayDiffBetweenTwoDays(firstDate: Date, secondDate: Date): number {
    var diff = Math.abs(firstDate.getTime() - secondDate.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    console.log(diffDays);
    return diffDays;
  }

  serialize(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  getTimeDiffOneHour(startDate, endDate, message) {
    console.log("called");
    let endTime = new Date(endDate);
    let startTime = new Date(startDate);

    if (endTime.getDate() == startTime.getDate()) {
      let diffOfTime = endTime.valueOf() - startTime.valueOf();
      if (diffOfTime / 60e3 < 60) {
        this.toastr.warning(message);
        return true;
      } else {
        return false;
      }
    }
  }
  isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getTemplates(): Observable<any> {
    return this.httpService.callApi("getRequestTemplate", {});
  }
  getClients(): Observable<any> {
    return this.httpService.callApi("getAllClientsBasic", {});
  }

  getCompanyList(): Observable<any> {
    return this.httpService.callApi("getCompanyDropdown", {});
  }

  getColors() {
    return this.http
      .get(`${this.url.urlString}configuration/color`)
      .toPromise();
  }

  getProjectList() {
    return this.http
      .get(`${this.url.urlString}configuration/project-status`)
      .toPromise();
  }

  getClientAndCompany() {
    return this.http.get(`${this.url.urlString}budget/dropdown`).toPromise();
  }

  addExlusiveKit(body){
    return this.http.post(`${this.url.urlString}budget/dropdown`,body).toPromise()
  }

  deleteExxlusiveKit(body){
    return this.http.post(`${this.url.urlString}budget/dropdown`,body).toPromise()
  }

  saveQuickFilters(formdata) {
    return this.http.post(`${this.url.urlString}quickfilter`,formdata).toPromise();
  }

  getQuickFilters(userId) {
    return this.http.get(`${this.url.urlString}quickfilter/user/${userId}`).toPromise();
  }

  deleteQuickFilters(userId) {
    return this.http.delete(`${this.url.urlString}quickfilter/${userId}`).toPromise();
  }

  setBookingMenu(formData) {
    return this.http
      .post(`${this.url.urlString}staff/bookingMenu`, formData)
      .toPromise();
  }

  importPref(userId,templateId) {
    return this.http
      .post(`${this.url.urlString}userTemplate/copytemplate/${userId}/${templateId}`,{})
      .toPromise();
  }



  setWeekendFalg(formData) {
    return this.http
      .post(`${this.url.urlString}project/weekoff?${this.serialize(formData)}`, {})
      .toPromise();
  }
  setcronJob(formData) {
    return this.http
      .post(`${this.url.urlString}schedule/taskdef`, formData)
      .toPromise();
  }
  setcronJobTest(formData) {
    return this.http
      .post(`${this.url.urlString}schedule/taskNow`, formData)
      .toPromise();
  }
  getCronJob(formData) {
    return this.http
      .get(`${this.url.urlString}schedule/${formData}`)
      .toPromise();
  }

  removeJob(formData) {
    return this.http
      .get(`${this.url.urlString}schedule/remove/${formData}`)
      .toPromise();
  }

  resumeJob(formData) {
    return this.http
      .post(`${this.url.urlString}schedule/resume`,formData)
      .toPromise();
  }

  getBookingMenu() {
    return this.http.get(`${this.url.urlString}staff/bookingMenu`).toPromise();
  }

  acceptOrdeclinereason(formData: any) {
    return this.http
      .post(`${this.url.urlString}notification/app-notification`, formData)
      .toPromise();
  }

  sendPushNotification(formData) {
    $.ajax({
      url: "https://fcm.googleapis.com/fcm/send",
      type: "post",
      data: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "key=AAAAMK9NpfE:APA91bG-sQfvUg9M_Hncr9IocszpeL5rOqsoNimZFXkJaFqntSrSxViiIoX5SebwOS_l7NxlAJTauTjvq1bfE9SbCTl4BdumAEdJ0Dd0-_r_lAIk6V1R_qSPp77ncmc5De5vELnBxSyJ",
      },
      dataType: "json",
      success: function (data) {
        // console.info(data);
      },
    });
  }

  getProjectSubStatus() {
    return this.http
      .get(`${this.url.urlString}configuration/project-status`)
      .toPromise();
  }

  getTransmissionOrder(projectId: any) {
    return this.http
      .get(
        `${this.url.urlString}dashboard/process-transmission-order?recordId=${projectId}`
      )
      .toPromise();
  }

  getProjectMileStone() {
    return this.http
      .get(`${this.url.urlString}configuration/milestone/project`)
      .toPromise();
  }

  getWallCategory() {
    return this.http
      .get(`${this.url.urlString}configuration/wall-category`)
      .toPromise();
  }

  getProjectFees() {
    return this.http
      .get(`${this.url.urlString}configuration/project-fees`)
      .toPromise();
  }

  getDeliverable() {
    return this.http
      .get(`${this.url.urlString}configuration/format`)
      .toPromise();
  }

  getNetwork() {
    return this.http
      .get(`${this.url.urlString}configuration/network`)
      .toPromise();
  }

  getProjectFeild() {
    return this.http
      .get(`${this.url.urlString}configuration/project-field`)
      .toPromise();
  }

  getProjectCategory() {
    return this.http
      .get(`${this.url.urlString}configuration/category`)
      .toPromise();
  }

  getProjectExtra() {
    return this.http
      .get(`${this.url.urlString}configuration/project-extra`)
      .toPromise();
  }

  getProjectTemplate() {
    return this.http
      .get(`${this.url.urlString}configuration/edition-template`)
      .toPromise();
  }

  getShowType() {
    return this.http
      .get(`${this.url.urlString}configuration/show-type`)
      .toPromise();
  }

  getEventStatus() {
    return this.http
      .get(`${this.url.urlString}event/booking-event-status`)
      .toPromise();
  }

  getClientsApi(obj) {
    return this.http.get(`${this.url.urlString}client?${this.serialize(obj)}`).toPromise()
  }

  getlogs() {
    return this.http.get(`${this.url.urlString}profilebuilder`).toPromise()
  }

  getClientsApiBasic() {
    return this.http.get(`${this.url.urlString}client/basic-info`).toPromise()
  }

  getClientDropDown() {
    return this.http.get(`${this.url.urlString}client/dropdown`).toPromise();
  }

  getVendors() {
    return this.http.get(`${this.url.urlString}vendor`).toPromise();
  }

  getVendorDropDown() {
    return this.http.get(`${this.url.urlString}vendor/dropdown`).toPromise();
  }

  getRateCard() {
    return this.http.get(`${this.url.urlString}rate`).toPromise();
  }

  getRateCardDropDown() {
    return this.http.get(`${this.url.urlString}rate/dropdown`).toPromise();
  }

  getConfigurationGroup() {
    return this.http
      .get(`${this.url.urlString}configuration/group`)
      .toPromise();
  }

  getAllMarketPlaceDropDown() {
    return this.http
      .get(`${this.url.urlString}configuration/getAllMarketPlace1ForDropDown`)
      .toPromise();
  }

  getGanttViewType(dates) {
    return this.http.get(`${this.url.urlString}budget/gantt/client?${this.serialize(dates)}`).toPromise();
  }

  setupdateRule(obj){
    return this.http.post(`${this.url.urlString}staff/update-labourRule`,obj).toPromise();
  }

  getGanttChartData(dates) {
    return this.http.get(`${this.url.urlString}budget/gantt?${this.serialize(dates)}`).toPromise();
  }

  getGanttChartDataSearch(dates) {
    return this.http.get(`${this.url.urlString}budget/gantt/search?${this.serialize(dates)}`).toPromise();
  }

  createLinkEvents(formData,eventID){
    return this.http.post(`${this.url.urlString}event/add-link/${eventID}`,formData).toPromise();
  }
  addLinkEvents(formData,eventID){
    return this.http.post(`${this.url.urlString}event/add-link/${eventID}`,formData).toPromise();
  }

  saveTempData(formData){
    return this.http.post(`${this.url.urlString}userTemplate/create`,formData).toPromise();
  }




  isBilled(formdata) {
    return this.http.post(`${this.url.urlString}project/line/saveBilled`,formdata).toPromise();
  }

  isBilling(formdata) {
    return this.http.post(`${this.url.urlString}project/line/saveBilling`,formdata).toPromise();
  }

 

  getAllPersonelBasiAccountManger() {
    return this.http.get(`${this.url.urlString}staff/basic-info/account-manager`).toPromise();
  }

  setPersonel(formData: any) {
    return this.http.post(`${this.url.urlString}staff`, formData).toPromise();
  }

  changePassword(formData: any) {
    return this.http.post(`${this.url.urlString}staff/change-password`, formData).toPromise();
  }

  getActiveRole() {
    return this.http
      .get(`${this.url.urlString}user-role/role/active`)
      .toPromise();
  }

  getPersonelDropDown() {
    return this.http.get(`${this.url.urlString}staff/dropdown`).toPromise();
  }

  getEquipment() {
    return this.http.get(`${this.url.urlString}equipment`).toPromise();
  }

  getEquipmentBundle() {
    return this.http.get(`${this.url.urlString}equipment/bundle`).toPromise();
  }


  getEquipmentByStaffId(staffId:any,obj) {
    return this.http.get(`${this.url.urlString}equipment/filter?${this.serialize(obj)}`).toPromise();
  }

  getEquipmentsearch(staffId:any,obj) {
    return this.http.get(`${this.url.urlString}equipment/getname?${this.serialize(obj)}`).toPromise();
  }


  getEquipmentByStaffIdCount() {
    return this.http.get(`${this.url.urlString}equipment/count`).toPromise();
  }


  updateStaffData(formData) {
    return this.http.post(`${this.url.urlString}staff/basic-info`,formData).toPromise();
  }

  updateEquipmentData(formData) {
    return this.http.post(`${this.url.urlString}equipment/basic-info`,formData).toPromise();
  }


  updateStaffUserSetting(formData,staffId) {
    return this.http.post(`${this.url.urlString}staff/basic-info/user-setting/${staffId}`,formData).toPromise();
  }

  getEquipmentStaffById(id:any) {
    return this.http.get(`${this.url.urlString}equipment/equipmentByStaffId/${id}`).toPromise();
  }

  getEquipmentDropDown() {
    return this.http.get(`${this.url.urlString}equipment/dropdown`).toPromise();
  }

  getProjectInfo() {
    return this.http.get(`${this.url.urlString}project/info`).toPromise();
  }


  checkForAssignedResource(id) {
    return this.http.get(`${this.url.urlString}project/bookedResource/${id}`).toPromise();
  }



  getAllLinkEventID() {
    return this.http.get(`${this.url.urlString}event/link`).toPromise();
  }

  setAllLinkEventID(formData: any) {
    return this.http
      .post(`${this.url.urlString}event/link`, formData)
      .toPromise();
  }

  getAllBookingEventStatus() {
    return this.http
      .get(`${this.url.urlString}event/booking-event-status`)
      .toPromise();
  }

  getAllSchedulerView() {
    return this.http
      .get(`${this.url.urlString}configuration/schedule-timeline-view`)
      .toPromise();
  }

  getShowListForScheduler() {
    return this.http
      .get(`${this.url.urlString}budget/scheduler/shows`)
      .toPromise();
  }

  setlandingPage(formData: any) {
    return this.http
      .post(`${this.url.urlString}user/user-preferences`, formData)
      .toPromise();
  }

  getAllKitListByLoggedInUser() {
    return this.http.get(`${this.url.urlString}configuration/kit/user`).toPromise();
  }

  getKitResources(kitId) {
    return this.http.get(`${this.url.urlString}configuration/kit/user/${kitId}`).toPromise();
  }

  getGlobalKitView() {
    return this.http.get(`${this.url.urlString}configuration/kit`).toPromise();
  }

  getKitViewConfiguration() {
    return this.http
      .get(`${this.url.urlString}configuration/kit-view-configuration`)
      .toPromise();
  }

  getBudgetEvent(parmData: any) {
    return this.http.get(`${this.url.urlString}budget/event?${this.serialize(parmData)}`).toPromise();
  }

  getBudgetEventSearch(parmData: any) {
    return this.http.get(`${this.url.urlString}budget/event/search?${this.serialize(parmData)}`).toPromise();
  }

  getLoggedInUser() {
    return this.http.get(`${this.url.urlString}staff/logged-in-user`).toPromise();

  }

  getLoggedInUserTimeReport(formData:any) {
    return this.http.get(`${this.url.urlString}user-booking/report/by-week?${this.serialize(formData)}`).toPromise();

  }

  getSelectedUserTimeReport(formData:any) {
    return this.http.get(`${this.url.urlString}user-booking/time-report/booking?${this.serialize(formData)}`).toPromise();

  }

  getCurrentWeekNominalHOurs(formData:any) {
    return this.http.get(`${this.url.urlString}dashboard/weekly-personnel/time-balance/?${this.serialize(formData)}`).toPromise();

  }

  getExtraHOurs(formData:any) {
    return this.http.get(`${this.url.urlString}dashboard/weekly-personnel/ExtraHours/?${this.serialize(formData)}`).toPromise();

  }

  getoffWorkHOurs(formData:any) {
    return this.http.get(`${this.url.urlString}dashboard/weekly-personnel/Holidays/?${this.serialize(formData)}`).toPromise();

  }

  setFCMToken(formData,staffId){
    return this.http.post(`${this.url.urlString}staff/fmc_token/${staffId}`, formData,{ responseType: 'text' }).toPromise();
  }

  setGoogleFreshToken(formData,staffId){
    return this.http.post(`${this.url.urlString}staff/google_refresh/${staffId}`, formData,{ responseType: 'text' }).toPromise();
  }

  setOutlookFreshToken(formData,staffId){
    return this.http.post(`${this.url.urlString}staff/outlook_refresh/${staffId}`, formData,{ responseType: 'text' }).toPromise();
  }

  findUserBookingByUser(userId) {
    return this.http
      .get(`${this.url.urlString}user-booking/${userId}`)
      .toPromise();
  }

  fetchAllInProgressProjects() {
    return this.http
      .get(`${this.url.urlString}project/dashboard/progress`)
      .toPromise();
  }

  getBookingsByLoggedInUser() {
    return this.http
      .get(`${this.url.urlString}booking/by-logged-in-user/`)
      .toPromise();
  }

  getProjectBasicDetails(projectId) {
    return this.http
      .get(`${this.url.urlString}budget/project-baisc-details/${projectId}`)
      .toPromise();
  }

  getProjectBasicDetailsRequest(projectId) {
    return this.http
      .get(`${this.url.urlString}project/project-baisc-details/${projectId}`)
      .toPromise();
  }

  getProjectResources(projectId) {
    return this.http
      .get(`${this.url.urlString}booking/function/by-project/${projectId}`)
      .toPromise();
  }

  getProjectsEvent(projectId) {
    return this.http
      .get(`${this.url.urlString}event/by-project/${projectId}`)
      .toPromise();
  }

  getMilestonesByProjectId(projectId) {
    return this.http
      .get(`${this.url.urlString}budget/milestone/${projectId}`)
      .toPromise();
  }

  getAllPhaseBookings() {
    return this.http
      .get(`${this.url.urlString}configuration/different-booking-phase`)
      .toPromise();
  }

  getAllProjectSchedulerView() {
    return this.http
      .get(`${this.url.urlString}configuration/project-schedule-timeline-view`)
      .toPromise();
  }

  createPlanningEventStatus(formData: any) {
    return this.http
      .post(`${this.url.urlString}event/planning-event-status`, formData)
      .toPromise();
  }

  getAllPlanningEventStatusList() {
    return this.http
      .get(`${this.url.urlString}event/planning-event-status`)
      .toPromise();
  }

  deletePlanningEventStatus(formData: any) {
    return this.http
      .delete(`${this.url.urlString}event/planning-event-status/${formData}`)
      .toPromise();
  }

  getProjectListForFilter() {
    return this.http
      .get(`${this.url.urlString}budget/getDropDownProjectList`)
      .toPromise();
  }
  getShowListForFilter() {
    return this.http.get(`${this.url.urlString}show/status`).toPromise();
  }
  getClientListForFilter() {
    return this.http
      .get(`${this.url.urlString}client/getClientDropDownList`)
      .toPromise();
  }

  getClientUserDefineField() {
    return this.http
      .get(`${this.url.urlString}configuration/client-field`)
      .toPromise();
  }

  getAllService() {
    return this.http
      .get(`${this.url.urlString}configuration/servicefunction`)
      .toPromise();
  }

  getAllPack() {
    return this.http.get(`${this.url.urlString}configuration/pack`).toPromise();
  }

  getAvailableData(funId: any, startDate: any, endDate: any) {
    return this.http
      .get(
        `${this.url.urlString}resource/equipment/${funId}/${startDate}/${endDate}`
      )
      .toPromise();
  }

  getAllFUnctionData() {
    return this.http.get(`${this.url.urlString}dropdown/functions`).toPromise();
  }

  
  getTableDataPersonal(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/people?${this.serialize(formData)}`).toPromise();
  }

  getTableDataFunction(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/function?${this.serialize(formData)}`).toPromise();
  }

  getTableDataProject(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/project?${this.serialize(formData)}`).toPromise();
  }

  getTableDataSearchPersonal(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/search?${this.serialize(formData)}`).toPromise();
  }

  getTableDataChart(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/chart?${this.serialize(formData)}`).toPromise();
  }

  getTableDataEquipment(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/resource?${this.serialize(formData)}`).toPromise();
  }

  getTableDataAllProject(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/projectView?${this.serialize(formData)}`).toPromise();
  }

  getTableDataChartAllProject(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/project/chart?${this.serialize(formData)}`).toPromise();
  }

  getTableDataAllClient(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/projectView/client?${this.serialize(formData)}`).toPromise();
  }

  getTableDataChartAllClient(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/client/chart?${this.serialize(formData)}`).toPromise();
  }
  
  getTableDataSearchEquipment(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/resource/search?${this.serialize(formData)}`).toPromise();
  }

  getTableDataChartEquipment(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/resource/chart?${this.serialize(formData)}`).toPromise();
  }

  getTableDataTimeOff(formData) {
    return this.http.get(`${this.url.urlString}mreport/v2/dashboard/timeoff?${this.serialize(formData)}`).toPromise();
  }


  setLeaveDropIndex(currentIndex: number, previousIndex: number) {
    return this.http
      .post(
        `${this.url.urlString}leave-type/dropanddrag/${currentIndex}/${previousIndex}`,
        {}
      )
      .toPromise();
  }

  setPlanningStatusDropIndex(previousIndex: number, currentIndex: number) {
    return this.http
      .post(
        `${this.url.urlString}event/planning-event-status/dropanddrag/${previousIndex}/${currentIndex}`,
        {}
      )
      .toPromise();
  }

  setBookingStatusDropIndex(previousIndex: number, currentIndex: number) {
    return this.http
      .post(
        `${this.url.urlString}event/booking-event-status/dropanddrag/${previousIndex}/${currentIndex}`,
        {}
      )
      .toPromise();
  }

  poolDropIndex(previousIndex: number, currentIndex: number) {
    return this.http
      .post(
        `${this.url.urlString}configuration/kit/draganddrop/${previousIndex}/${currentIndex}`,
        {}
      )
      .toPromise();
  }

  // working print quotation

  // getprintQuotation(projectId: any, personId: any, flag: any): any {
  //   let url = `${this.url.urlString}project/print-quotation/${projectId}/${personId}?isQuotation=${flag}`;
  //   var authorization = 'Bearer ' + sessionStorage.getItem("token");

  //   //  const headers = new HttpHeaders({ responseType : 'blob'});

  //   return this.http.get<Blob>(url, { responseType: 'blob' as 'json' });
  // }

  //  test quotation

  getprintQuotation(projectId: any, personId: any, flag: any): any {
    let url = `${this.url.urlString}budget/pdf?budgetId=${projectId}&contactId=${personId}&terms=abc&isQuotation=${flag}`;
    var authorization = "Bearer " + sessionStorage.getItem("token");
    return this.http.get<Blob>(url, { responseType: "blob" as "json" });
  }

  printPackageList(projectId: any,isExcel:boolean): any {
    let url = `${this.url.urlString}budget/pdf/package-list/${projectId}?isExcel=${isExcel}`;
    var authorization = "Bearer " + sessionStorage.getItem("token");
    return this.http.get<Blob>(url, { responseType: "blob" as "json" });
  }

  downloadCallSheet(projectId: any, callSheetId: any): any {
    let url = `${this.url.urlString}call-sheet/pdf/callsheetData/${projectId}/${callSheetId}`;
    return this.http.get<Blob>(url, { responseType: "blob" as "json" });
  }

  getprintEstimate(projectId: any, personId: any, flag: any): any {
    let url = `${this.url.urlString}budget/pdf/estimate?budgetId=${projectId}&contactId=${personId}&terms=abc&isQuotation=${flag}`;
    var authorization = "Bearer " + sessionStorage.getItem("token");
    return this.http.get<Blob>(url, { responseType: "blob" as "json" });
  }

  showgetprintEstimate(projectId: any, personId: any, flag: any): any {
    let url = `${this.url.urlString}show/pdf/estimate?budgetId=${projectId}&contactId=${personId}&terms=abc&isQuotation=${flag}`;
    var authorization = "Bearer " + sessionStorage.getItem("token");
    return this.http.get<Blob>(url, { responseType: "blob" as "json" });
  }

  getprintRequest(projectId: any, personId: any, flag: any): any {
    let url = `${this.url.urlString}budget/pdf/request?budgetId=${projectId}&contactId=${personId}&terms=abc&isQuotation=${flag}`;
    var authorization = "Bearer " + sessionStorage.getItem("token");
    return this.http.get<Blob>(url, { responseType: "blob" as "json" });
  }

  // http://localhost:8180/budget/pdf?budgetId=688&contactId=2554&terms=abc&isQuotation=false

  printOrder(orderId: any, personId: any, functionName: any): any {
    return this.http
      .get<Blob>(
        `${this.url.urlString}order/pdf?orderId=${orderId}&contactId=${personId}&functionName=${functionName}`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
  }

  billOfMaterials(projectId: any): any {
    return this.http
      .get<Blob>(
        `${this.url.urlString}budget/pdf/packing-list/qty/${projectId}`,
        { responseType: "blob" as "json" }
      )
      .toPromise();
  }

  // downloadWallAttachment(){
  //   return this.http.get<Blob>(`${this.url.urlString}budget/pdf/packing-list/qty/${projectId}`, { responseType: 'blob' as 'json' }).toPromise();

  // }

  checkInPdf(projectId: any): any {
    return this.http
      .get<Blob>(`${this.url.urlString}budget/pdf/check-in/${projectId}`, {
        responseType: "blob" as "json",
      })
      .toPromise();
  }

  getUserEvents(userId, paramData): any {
    return this.http
      .get(`${this.url.urlString}user-booking/${userId}${paramData}`)
      .toPromise();
  }

  setUserEvents(formData: any): any {
    return this.http
      .post(`${this.url.urlString}user-booking`, formData)
      .toPromise();
  }

  buildTimeReportUser(personId: any, currYear: any): any {
    return this.http
      .get(
        `${this.url.urlString}user-booking/build-report?staff=${personId}&year=${currYear}`,
        { responseType: "text" }
      )
      .toPromise();
  }

  buildTimeMasterReport(currYear: any): any {
    return this.http
      .get(
        `${this.url.urlString}user-booking/build-masterreport?year=${currYear}`,
        { responseType: "text" }
      )
      .toPromise();
  }

  resetExported(id: any): any {
    return this.http
      .post(
        `${this.url.urlString}booking/reset-export?bookingId=${id}`,
        { responseType: "text" }
      )
      .toPromise();
  }

  deleteResource(id: any): any {
    return this.http
      .delete(
        `${this.url.urlString}resource/unavailbility/${id}`,
        { responseType: "text" }
      )
      .toPromise();
  }

  getTimeTrack(projectName): any {
    return this.http
      .get(`${this.url.urlString}user-booking/ProjectManagement/${projectName}`)
      .toPromise();
  }

  setProjectEstimate(formData: any): any {
    return this.http
      .post(
        `${
          this.url.urlString
        }project/update-project-estimated-time?${this.serialize(formData)}`,
        {}
      )
      .toPromise();
  }

  getUserBasic(userId): any {
    return this.http
      .get(`${this.url.urlString}staff/user-basic/${userId}`)
      .toPromise();
  }

  getHolidays(): any {
    return this.http
      .get(`${this.url.urlString}configuration/holiday`)
      .toPromise();
  }

  findUserLeaveRecordByStaffId(userId: any): any {
    return this.http
      .get(`${this.url.urlString}user-leave-record/${userId}`)
      .toPromise();
  }

  getResurceDuty(resource: any): any {
    return this.http
      .get(`${this.url.urlString}roster/timeline-event/resource/${resource}`)
      .toPromise();
  }

  copyPastRoaster(formData: any): any {
    return this.http
      .post(`${this.url.urlString}roster/timeline-event/copy`, formData)
      .toPromise();
  }

  saveRoasterDutyShift(formData: any) {
    return this.http
      .post(`${this.url.urlString}configuration/rostershift`, formData)
      .toPromise();
  }


  saveOrupdateFunction(formData: any) {
    return this.http
      .post(`${this.url.urlString}booking/update-function/events`, formData)
      .toPromise();
  }

  setJiraIntegration(token,id: any) {
    return this.http
      .post(`${this.url.urlString}staff/Jira_token/${id}`, token)
      .toPromise();
  }

  checkIfJiraSetup(id: any){
    return this.http.get(`${this.url.urlString}staff/Jira_token/${id}`).toPromise()
  }

  getAllBoard(id: any){
    return this.http.get(`${this.url.urlString}jira/${id}/board`).toPromise()
  }

  getAllBacklog(id: any){
    return this.http.get(`${this.url.urlString}jira/${id}/board/3/backlog`).toPromise()
  }

  getCheckBoardSprints(id: any,boardId){
    return this.http.get(`${this.url.urlString}jira/${id}/board/${boardId}/sprint`).toPromise()
  }

  getSprintsIssue(id: any,sprintId){
    return this.http.get(`${this.url.urlString}jira/${id}/board/sprint/${sprintId}`).toPromise()
  }





  getAllRoasterDutyShift() {
    return this.http
      .get(`${this.url.urlString}configuration/rostershift`)
      .toPromise();
  }

  deleteRoasterDutyShift(shiftId) {
    return this.http
      .delete(`${this.url.urlString}configuration/rostershift/${shiftId}`, {
        responseType: "text",
      })
      .toPromise();
  }

  deleteResourceKit(id,kitId) {
    return this.http
      .delete(`${this.url.urlString}configuration/kitResources/${id}/${kitId}`, {
        responseType: "text",
      })
      .toPromise();
  }

  saveCalendarKey(formData: any) {
    return this.http
      .post(`${this.url.urlString}configuration/calenderIntegration`, formData)
      .toPromise();
  }

  getCalendarKey() {
    return this.http
      .get(`${this.url.urlString}configuration/calenderIntegration`)
      .toPromise();
  }

  notificationMeeting(meetingId: any) {
    return this.http
      .post(
        `${this.url.urlString}notification/notify-meeting-all/${meetingId}`,
        {}
      )
      .toPromise();
  }

  setApproveSupervisor(superVisorId: any, formData: any) {
    return this.http
      .post(
        `${this.url.urlString}user-leave-record/leave-request/supervisor/${superVisorId}`,
        formData
      )
      .toPromise();
  }

  deleteDirectReport(id: any) {
    return this.http
      .delete(`${this.url.urlString}staff/delete-supervisor/${id}`, {
        responseType: "text",
      })
      .toPromise();
  }

  getAllProjectNotes(projectId: any) {
    return this.http
      .get(`${this.url.urlString}project-note/getMeetingByProject/${projectId}`)
      .toPromise();
  }

  createCalendar(token: string, calendarName: string) {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };
  
    const body = {
      name: calendarName,
      color: "auto", // Optional: you can specify a color if needed
      owner: {
        "@odata.type": "microsoft.graph.user",
        id: "YOUR_USER_ID" // Optional: specify the user ID if needed
      }
    };
  
    return this.http
      .post(`https://graph.microsoft.com/v1.0/me/calendars`, body, httpOptions)
      .toPromise();
  }

  createMsalEvent(token, body,calendarId) {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };

    console.log(token);

    return this.http
      .post(`https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events`, body, httpOptions)
      .toPromise();
  }

  updateMsalEvent(token, body,calendarId,eventId) {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };

    console.log(token);

    return this.http
      .post(`https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events/${eventId}`, body, httpOptions)
      .toPromise();
  }



  deleteMsalEvent(token, eventId,calendarId) {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };

    return this.http
      .get(`https://graph.microsoft.com/v1.0/meme/calendars/${calendarId}/events/${eventId}`, httpOptions)
      .toPromise();
  }

  exchangeCodeForTokens(authCode: string,auth) {
  const  scopes = "Calendars.Read.Shared Calendars.ReadWrite Calendars.ReadWrite.Shared openid profile User.Read User.Read.All User.ReadBasic.All email offline_access"

    const body = new HttpParams()
      .set('client_id', auth.auth.clientId)
      .set('grant_type', 'authorization_code')
      .set('code', authCode)
      .set('redirect_uri', auth.auth.redirectUri)
      .set('scope', scopes)
      .set('code_verifier', auth.auth.codeVerifier);
    
    // Optionally add client_secret for confidential apps
    // .set('client_secret', 'YOUR_CLIENT_SECRET');
    let tokenEndpoint = `https://login.microsoftonline.com/${auth.auth.tenantId}/oauth2/v2.0/token`;
    // let tokenEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/token`;
    return this.http.post(tokenEndpoint, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded',skip: "true" }
    }).toPromise();

  }

  refreshAccessToken(refreshToken: string,auth){
    const body = new HttpParams()
      .set('client_id', auth.auth.clientId)
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('scope', 'openid profile offline_access');
      let tokenEndpoint = `https://login.microsoftonline.com/${auth.auth.tenantId}/oauth2/v2.0/token`;
      // let tokenEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/token`;
    return this.http.post(tokenEndpoint, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded',skip: "true" }
    }).toPromise();
  }

  getAllCalendars(token) {
    console.log(token)
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };

    return this.http
      .get(`https://graph.microsoft.com/v1.0/me/calendars`, httpOptions)
      .toPromise();
  }

  // scopes = ["Calendars.Read.Shared",
  //   "Calendars.ReadWrite",
  //   "Calendars.ReadWrite.Shared",
  //   "Contacts.Read",
  //   "Directory.AccessAsUser.All",
  //   "Directory.Read.All",
  //   "openid",
  //   "profile",
  //   "User.Read",
  //   "User.Read.All",
  //   "User.ReadBasic.All",
  //   "email"]

  // tenantId = 'f8cdef31-a31e-4b4a-93e4-5f571e91255a'  

  createCalendars(token,newCalendar) {
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };

    return this.http
      .post(`https://graph.microsoft.com/v1.0/me/calendars`,newCalendar, httpOptions)
      .toPromise();
  }

  AddActulizeBalance(formData: any) {
    return this.http
      .post(
        `${this.url.urlString}time-report/actualize-user-timereport`,
        formData
      )
      .toPromise();
  }

  getActulizeBalance(userId: any) {
    return this.http
      .get(
        `${this.url.urlString}time-report/actualize-user-timereport/${userId}`
      )
      .toPromise();
  }

  getProjectCardView() {
    return this.http
      .get(`${this.url.urlString}configuration/projectCard`)
      .toPromise();
  }

  setProjectCardView(formData: any) {
    return this.http
      .post(`${this.url.urlString}configuration/projectCard`, formData)
      .toPromise();
  }

  saveRegion(formData: any) {
    return this.http
      .post(`${this.url.urlString}configuration/region`, formData)
      .toPromise();
  }

  saveAccountManager(formData: any) {
    return this.http
      .post(
        `${this.url.urlString}project/update-project-accountmanager`,
        formData
      )
      .toPromise();
  }

  saveProjectDeskValues(formdata:any){
    return  this.http.post(`${this.url.urlString}project/update-project-desk?${this.serialize(formdata)}`,{}).toPromise()
  }

  saveProjectOwner(formdata:any){
    return  this.http.post(`${this.url.urlString}project/update-projectowner`,formdata).toPromise()
  }

  saveProfileUpdates(formdata:any){
    return  this.http.post(`${this.url.urlString}staff/update-remarks`,formdata).toPromise()
  }

  saveCustomFeilds(formdata:any){
    return  this.http.post(`${this.url.urlString}budget/customizedfield`,formdata).toPromise()
  }

  sendSelctedPerosnal(formdata:any){
    return  this.http.post(`${this.url.urlString}profilebuilder/sendprofile`,formdata).toPromise()
  }

  setPrimaryForLine(formdata:any){
    return  this.http.post(`${this.url.urlString}booking/phaseowner`,formdata).toPromise()
  }

  getAllRegion() {
    return this.http
      .get(`${this.url.urlString}configuration/region`)
      .toPromise();
  }
  

  deleteRegion(id: any) {
    return this.http
      .delete(`${this.url.urlString}configuration/region/${id}`)
      .toPromise();
  }

  // getEventsUnscheduled(formData:any){
  //   return this.http.get(`${this.url.urlString}event/by-function/34/${formData}`).toPromise();
  // }

  // httpOptions

  // removed pagition request by ashok 03-11-2023

  // getEventsUnscheduled() {
  //   return this.http
  //     .get(`${this.url.urlString}event/unschedule/`)
  //     .toPromise();
  // }

  getEventsUnscheduled(offSet: any, size: any,obj:any) {
    return this.http.get(`${this.url.urlString}event/unschedule/${offSet}/${size}?${this.serialize(obj)}`).toPromise();
  }

  getEventsUnscheduledByName(obj:any) {
    return this.http.get(`${this.url.urlString}event/unschedule/getname/?${this.serialize(obj)}`).toPromise();
  }

  getEventsScheduled(fnId: any, dateParams: any) {
    return this.http
      .get(`${this.url.urlString}event/by-function/${fnId}${dateParams}`)
      .toPromise();
  }

  getEventsByid(id: any) {
    return this.http.get(`${this.url.urlString}event/${id}`).toPromise();
  }

  addPersonalColumn(formData: any) {
    return this.http
      .post(`${this.url.urlString}staff/createList`, formData, {
        responseType: "text",
      })
      .toPromise();
  }

  getPersonalColumn(personId: any) {
    return this.http
      .get(`${this.url.urlString}staff/getList/${personId}`)
      .toPromise();
  }

  deletePersonalColumn(columnId: any) {
    return this.http
      .delete(`${this.url.urlString}staff/deleteListbyId/${columnId}`, {
        responseType: "text",
      })
      .toPromise();
  }

  addUserAccountColumn(formData: any) {
    return this.http
      .post(`${this.url.urlString}staff/useraccounts/createList`, formData, {
        responseType: "text",
      })
      .toPromise();
  }

  getUserAccountColumn(personId: any) {
    return this.http
      .get(`${this.url.urlString}staff/useraccounts/getList/${personId}`)
      .toPromise();
  }

  deleteUserAccountColumn(columnId: any) {
    return this.http
      .delete(`${this.url.urlString}staff/useraccounts/deleteListbyId/${columnId}`, {
        responseType: "text",
      })
      .toPromise();
  }

  getPersonalLicenceData(obj) {
    return this.http
      .get(`${this.url.urlString}staff/licence-count?${this.serialize(obj)}`)
      .toPromise();
  }

  addCheckColumn(formData: any) {
    return this.http
      .post(`${this.url.urlString}staff/checkout/createList`, formData, {
        responseType: "text",
      })
      .toPromise();
  }

  getCheckColumn(personId: any) {
    return this.http
      .get(`${this.url.urlString}staff/checkout/getList/${personId}`)
      .toPromise();
  }

  deleteCheckColumn(columnId: any) {
    return this.http
      .delete(`${this.url.urlString}staff/checkout/deleteListbyId/${columnId}`, {
        responseType: "text",
      })
      .toPromise();
  }

  getEquipmentByBardode(barcode: any) {
    return this.http
      .get(`${this.url.urlString}equipment/barcode/${barcode}`)
      .toPromise();
  }

  updateStatusEquipment(formData: any) {
    return this.http
      .post(`${this.url.urlString}equipment/update-status`, formData)
      .toPromise();
  }

  addEquipmentColumn(formData: any) {
    return this.http
      .post(`${this.url.urlString}equipment/createList`, formData, {
        responseType: "text",
      })
      .toPromise();
  }

  getEquipmentColumn(personId: any) {
    return this.http
      .get(`${this.url.urlString}equipment/getList/${personId}`)
      .toPromise();
  }
  
  deleteEquipmentColumn(columnId: any) {
    return this.http
      .delete(`${this.url.urlString}equipment/deleteListbyId/${columnId}`, {
        responseType: "text",
      })
      .toPromise();
  }

  getAllFunction() {
    return this.http
      .get(`${this.url.urlString}function/getAllFunctionQualifiedName`,)
      .toPromise();
  }

  // google apis

  fetchGoogleToken(formData: any) {
    return this.http
      .post(`https://accounts.google.com/o/oauth2/token`, formData)
      .toPromise();
  }

  getcalenderListwithToken(apiKey: any) {
    let token = sessionStorage.getItem("google_access_token");
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
        // "Access-Control-Allow-Origin": "http://localhost:4200",
        "Access-Control-Allow-Credentials": "true",
      }),
    };
    return this.http
      .get(
        `https://www.googleapis.com/calendar/v3/users/me/calendarList?key=${apiKey}`,
        httpOptions
      )
      .toPromise();
  }

  getAllAvailableFunction() {
    console.log('api call 1')
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAvailableFunction.push({ resolve, reject });
      console.log(this.isProcessingAvailableFunction)
      if (!this.isProcessingAvailableFunction) {
        this.processQueueAvailableFunction();
      }
    });
    // return this.http.get(`${this.url.urlString}resource/functions/available`).toPromise();
  }

  private async processQueueAvailableFunction(){
    if (this.requestQueueAvailableFunction.length === 0) {
      this.isProcessingAvailableFunction = false;
      return;
    }

    this.isProcessingAvailableFunction = true;
    const { resolve, reject } = this.requestQueueAvailableFunction.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}resource/functions/available/functions`).toPromise();
      resolve(response);
    } catch (error) {
      console.log(error)
      reject(error);
    }
  }

  getAllAvailableResourcesequipment() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAvailableresources.push({ resolve, reject });
      if (!this.isProcessingAvailableresources) {
        this.processQueueAvailableresources();
      }
    });
    // return this.http.get(`${this.url.urlString}resource/functions/available`).toPromise();
  }

  private async processQueueAvailableresources(){
    if (this.requestQueueAvailableresources.length === 0) {
      this.isProcessingAvailableresources = false;
      return;
    }

    this.isProcessingAvailableresources = true;
    const { resolve, reject } = this.requestQueueAvailableresources.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}resource/functions/available/equipmentResources`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    }
  }

  getAllAvailableResourcesPersonal() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAvailableresourcesPersonal.push({ resolve, reject });
      if (!this.isProcessingAvailableresourcesPersonal) {
        this.processQueueAvailableresourcesPersonal();
      }
    });
    // return this.http.get(`${this.url.urlString}resource/functions/available`).toPromise();
  }

  private async processQueueAvailableresourcesPersonal(){
    if (this.requestQueueAvailableresourcesPersonal.length === 0) {
      this.isProcessingAvailableresourcesPersonal = false;
      return;
    }

    this.isProcessingAvailableresourcesPersonal = true;
    const { resolve, reject } = this.requestQueueAvailableresourcesPersonal.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}resource/functions/available/staffResources`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    }
  }

  getAllAvailableResourcesContarct() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAvailableresourcesContract.push({ resolve, reject });
      if (!this.isProcessingAvailableresourcesContract) {
        this.processQueueAvailableresourcesContract();
      }
    });
    // return this.http.get(`${this.url.urlString}resource/functions/available`).toPromise();
  }

  private async processQueueAvailableresourcesContract(){
    if (this.requestQueueAvailableresourcesContract.length === 0) {
      this.isProcessingAvailableresourcesContract = false;
      return;
    }

    this.isProcessingAvailableresourcesContract = true;
    const { resolve, reject } = this.requestQueueAvailableresourcesContract.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}resource/functions/available/staffResources/contract`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    }
  }

  getAllAvailableResourcesSkillPersonal() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAvailableresourcesPersonalSkill.push({ resolve, reject });
      if (!this.isProcessingAvailableresourcesPersonalSkill) {
        this.processQueueAvailableresourcesSkillPersonal();
      }
    });
    // return this.http.get(`${this.url.urlString}resource/functions/available`).toPromise();
  }

  private async processQueueAvailableresourcesSkillPersonal(){
    if (this.requestQueueAvailableresourcesPersonalSkill.length === 0) {
      this.isProcessingAvailableresourcesPersonalSkill = false;
      return;
    }

    this.isProcessingAvailableresourcesPersonalSkill = true;
    const { resolve, reject } = this.requestQueueAvailableresourcesPersonalSkill.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}resource/functions/available/staffResources/skill`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    }
  }


  getAllAvailableDefaults() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAvailabledefaults.push({ resolve, reject });
      if (!this.isProcessingAvailabledefaults) {
        this.processQueueAvailabledefaults();
      }
    });
    // return this.http.get(`${this.url.urlString}resource/functions/available`).toPromise();
  }

  private async processQueueAvailabledefaults(){
    if (this.requestQueueAvailabledefaults.length === 0) {
      this.isProcessingAvailabledefaults = false;
      return;
    }

    this.isProcessingAvailabledefaults = true;
    const { resolve, reject } = this.requestQueueAvailabledefaults.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}resource/functions/available/defaults`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    }
  }

  getAllPersonel(obj) {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAllStaff.push({ resolve, reject });
      if (!this.isProcessingAllStaff) {
        this.processQueueAllStaff(obj);
      }
    });
    // return this.http.get(`${this.url.urlString}staff`).toPromise();
  }

  private async processQueueAllStaff(obj){
    if (this.requestQueueAllStaff.length === 0) {
      this.isProcessingAllStaff = false;
      return;
    }

    this.isProcessingAllStaff = true;
    const { resolve, reject } = this.requestQueueAllStaff.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}staff/pagewise?${this.serialize(obj)}`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      this.processQueueAllStaff(obj); // Continue processing the next request
    }
  }

  getPersonalsearch(obj) {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueAllStaffSearch.push({ resolve, reject });
      if (!this.isProcessingAllStaffSearch) {
        this.processQueueAllStaffSearch(obj);
      }
    });
    // return this.http.get(`${this.url.urlString}staff`).toPromise();
  }

  private async processQueueAllStaffSearch(obj){
    if (this.requestQueueAllStaffSearch.length === 0) {
      this.isProcessingAllStaffSearch = false;
      return;
    }

    this.isProcessingAllStaffSearch = true;
    const { resolve, reject } = this.requestQueueAllStaffSearch.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}staff/getname?${this.serialize(obj)}`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      this.processQueueAllStaffSearch(obj); // Continue processing the next request
    }
  }

  getAllPersonelBasi() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueueBasicStaff.push({ resolve, reject });
      if (!this.isProcessingBasicStaff) {
        this.processQueueBasicStaff();
      }
    });
    // return this.http.get(`${this.url.urlString}staff/basic-info`).toPromise();
  }

  getAllPersonelBasiById(personId: any) {
    return this.http.get(`${this.url.urlString}staff/basic-info/${personId}`).toPromise();
  }

  getAllPersonelCount() {
    return this.http.get(`${this.url.urlString}staff/count`).toPromise();
  }

  getAllUserAccount(obj) {
    return this.http.get(`${this.url.urlString}staff/accounts?${this.serialize(obj)}`).toPromise();
  }

  getAllPersonelManagers(managerType) {
    return this.http.get(`${this.url.urlString}staff/basic-info/managers/?details=${managerType}`).toPromise();
  }

  

  // getAllPersonelProjectById() {
  //   return this.http.get(`${this.url.urlString}staff/ProjectBooking`).toPromise();
  // }

  getAllPersonelProjectById() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueuegetAllPersonelProjectById.push({ resolve, reject });
      if (!this.isProcessinggetAllPersonelProjectById) {
        this.processQueuegetAllPersonelProjectById();
      }
    });
  }

  private async processQueuegetAllPersonelProjectById(){
   
      if (this.requestQueuegetAllPersonelProjectById.length === 0) {
        this.isProcessinggetAllPersonelProjectById = false;
        return;
      }
  
      this.isProcessinggetAllPersonelProjectById = true;
      const { resolve, reject } = this.requestQueuegetAllPersonelProjectById.shift();
      if (!this.dataCacheMain) {
      try {
        const response = await this.http.get(`${this.url.urlString}staff/ProjectBooking`).toPromise();
        this.dataCacheMain = response
        resolve(response);
      } catch (error) {
        reject(error);
      }

    } else {
      resolve(this.dataCacheMain);
    }
  }



  refreshDataMain(): void {
    this.dataCacheMain = null;
    this.getAllPersonelProjectById()
  }

  // getAllPersonelProjectLocation() {
  //   return this.http.get(`${this.url.urlString}staff/ProjectBooking/location`).toPromise();
  // }

  getAllPersonelProjectLocation() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueuegetAllPersonelProjectLocation.push({ resolve, reject });
      if (!this.isProcessinggetAllPersonelProjectLocation) {
        this.processQueuegetAllPersonelProjectLocation();
      }
    });
  }

  private async processQueuegetAllPersonelProjectLocation(){
  
      if (this.requestQueuegetAllPersonelProjectLocation.length === 0) {
        this.isProcessinggetAllPersonelProjectLocation = false;
        return;
      }
  
      this.isProcessinggetAllPersonelProjectLocation = true;
      const { resolve, reject } = this.requestQueuegetAllPersonelProjectLocation.shift();
      if (!this.dataCacheLocation) {
      try {
        const response = await this.http.get(`${this.url.urlString}staff/ProjectBooking/location`).toPromise();
        this.dataCacheLocation = response
        resolve(response);
      } catch (error) {
        reject(error);
      }

    } else {
      resolve(this.dataCacheLocation)
    }
  }



  refreshDataLocation(): void {
    this.dataCacheLocation = null;
    this.getAllPersonelProjectLocation()
  }


  

  // getAllPersonelProjectdirector(): Observable<any> {
  //   if (!this.dataCacheDirector$) {
  //     this.dataCacheDirector$ = this.refreshDataDirector$
  //       .pipe(
  //         // Make the API call when refreshData$ emits
  //         // Use shareReplay(1) to share the response among multiple subscribers
  //         // and cache it for future subscribers
  //         switchMap(() => this.http.get(`${this.url.urlString}staff/ProjectBooking/director`)),
  //         shareReplay(1)
  //       );
  //   }

  //   // Trigger refreshData$ to make the API call if not already cached
  //   this.refreshDataDirector$.next();

  //   return this.dataCacheDirector$;
  // }

  refreshDataDirector(): void {
    this.dataCacheDirector = null;
    this.getAllPersonelProjectdirector()
  }

  getAllPersonelProjectdirector() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueuegetAllPersonelProjectdirector.push({ resolve, reject });
      if (!this.isProcessinggetAllPersonelProjectdirector) {
        this.processQueuegetAllPersonelProjectdirector();
      }
    });
  }

  private async processQueuegetAllPersonelProjectdirector(){
   
      if (this.requestQueuegetAllPersonelProjectdirector.length === 0) {
        this.isProcessinggetAllPersonelProjectdirector = false;
        return;
      }
  
      this.isProcessinggetAllPersonelProjectdirector = true;
      const { resolve, reject } = this.requestQueuegetAllPersonelProjectdirector.shift();
      if (!this.dataCacheDirector) {
      try {
        const response = await this.http.get(`${this.url.urlString}staff/ProjectBooking/director`).toPromise();
        this.dataCacheDirector = response
        resolve(response);
      } catch (error) {
        reject(error);
      }

    } else {
      resolve(this.dataCacheDirector)
    }
  }

  // getAllPersonelProjectdirector() {
  //   return this.http.get(`${this.url.urlString}staff/ProjectBooking/director`).toPromise();
  // }

  // getAllPersonelProjectmainfunction() {
  //   return this.http.get(`${this.url.urlString}staff/ProjectBooking/mainfunction`).toPromise();
  // }

  // getAllPersonelProjectByAllUserSetting() {
  //   return this.http.get(`${this.url.urlString}staff/AllUserSetting`).toPromise();
  // }



  refreshDataAllUserSetting(): void {
    this.dataCacheAllUserSetting = null;
    this.getAllPersonelProjectByAllUserSetting()
  }

  getAllPersonelProjectByAllUserSetting() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueuegetAllPersonelProjectByAllUserSetting.push({ resolve, reject });
      if (!this.isProcessinggetAllPersonelProjectByAllUserSetting) {
        this.processQueuegetAllPersonelProjectByAllUserSetting();
      }
    });
  }

  private async processQueuegetAllPersonelProjectByAllUserSetting(){
   
      if (this.requestQueuegetAllPersonelProjectByAllUserSetting.length === 0) {
        this.isProcessinggetAllPersonelProjectByAllUserSetting = false;
        return;
      }
  
      this.isProcessinggetAllPersonelProjectByAllUserSetting = true;
      const { resolve, reject } = this.requestQueuegetAllPersonelProjectByAllUserSetting.shift();
      if (!this.dataCacheAllUserSetting) {
      try {
        const response = await this.http.get(`${this.url.urlString}staff/AllUserSetting`).toPromise();
        this.dataCacheAllUserSetting = response
        resolve(response);
      } catch (error) {
        reject(error);
      }

    } else {
      resolve(this.dataCacheAllUserSetting)
    }
  }

  // getAllPersonelProjectByAllContractSetting() {
  //   return this.http.get(`${this.url.urlString}staff/AllContractSetting`).toPromise();
  // }


  // getAllPersonelProjectByAllContractSetting(): Observable<any> {
  //   if (!this.dataCacheAllContractSetting$) {
  //     this.dataCacheAllContractSetting$ = this.refreshDataAllContractSetting$
  //       .pipe(
  //         // Make the API call when refreshData$ emits
  //         // Use shareReplay(1) to share the response among multiple subscribers
  //         // and cache it for future subscribers
  //         switchMap(() => this.http.get(`${this.url.urlString}staff/AllContractSetting`)),
  //         shareReplay(1)
  //       );
  //   }

  //   // Trigger refreshData$ to make the API call if not already cached
  //   this.refreshDataAllContractSetting$.next();

  //   return this.dataCacheAllContractSetting$;
  // }

  getAllPersonelProjectByAllContractSetting() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueuegetAllPersonelProjectByAllContractSetting.push({ resolve, reject });
      if (!this.isProcessinggetAllPersonelProjectByAllContractSetting) {
        this.processQueuegetAllPersonelProjectByAllContractSetting();
      }
    });
  }

  private async processQueuegetAllPersonelProjectByAllContractSetting(){
   
      if (this.requestQueuegetAllPersonelProjectByAllContractSetting.length === 0) {
        this.isProcessinggetAllPersonelProjectByAllContractSetting = false;
        return;
      }
  
      this.isProcessinggetAllPersonelProjectByAllContractSetting = true;
      const { resolve, reject } = this.requestQueuegetAllPersonelProjectByAllContractSetting.shift();
      if (!this.dataCacheAllContractSetting) {
      try {
        const response = await this.http.get(`${this.url.urlString}staff/AllContractSetting`).toPromise();
        this.dataCacheAllContractSetting = response
        resolve(response);
      } catch (error) {
        reject(error);
      }

    } else {
      resolve(this.dataCacheAllContractSetting)
    }
  }

  refreshDataAllContractSetting(): void {
    this.dataCacheAllContractSetting = null;
    this.getAllPersonelProjectByAllContractSetting();
  }

  // getAllPersonelProjectByAllResource() {
  //   return this.http.get(`${this.url.urlString}staff/AllResource`).toPromise();
  // }



  getAllPersonelProjectByAllResource() {
    return new Promise<any>((resolve, reject) => {
      this.requestQueuegetAllPersonelProjectByAllResource.push({ resolve, reject });
      if (!this.isProcessinggetAllPersonelProjectByAllResource) {
        this.processQueuegetAllPersonelProjectByAllResource();
      }
    });
  }

  private async processQueuegetAllPersonelProjectByAllResource(){
    
      if (this.requestQueuegetAllPersonelProjectByAllResource.length === 0) {
        this.isProcessinggetAllPersonelProjectByAllResource = false;
        return;
      }
  
      this.isProcessinggetAllPersonelProjectByAllResource = true;
      const { resolve, reject } = this.requestQueuegetAllPersonelProjectByAllResource.shift();
      if (!this.dataCacheAllResource) {
      try {
        const response = await this.http.get(`${this.url.urlString}staff/AllResource`).toPromise();
        this.dataCacheAllResource = response
        resolve(response);
      } catch (error) {
        reject(error);
      }
      
    } else {
      resolve(this.dataCacheAllResource)
    }
  }
  
  rosterAllDataResource(){
    return this.http.get(`${this.url.urlString}staff/AllResource`).toPromise();
  }

  refreshDataAllResource(): void {
    this.dataCacheAllResource = null;
    this.getAllPersonelProjectByAllResource();
  }

  private async processQueueBasicStaff(){
    if (this.requestQueueBasicStaff.length === 0) {
      this.isProcessingBasicStaff = false;
      return;
    }

    this.isProcessingBasicStaff = true;
    const { resolve, reject } = this.requestQueueBasicStaff.shift();

    try {
      const response = await this.http.get(`${this.url.urlString}staff/basic-info`).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      this.processQueueBasicStaff(); // Continue processing the next request
    }
  }

  setfilters(formData: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.requestQueue.push({ formData, resolve, reject });
      if (!this.isProcessing) {
        this.processQueue();
      }
    });
  }

  private async processQueue() {
    if (this.requestQueue.length === 0) {
      this.isProcessing = false;
      return;
    }

    this.isProcessing = true;
    const { formData, resolve, reject } = this.requestQueue.shift();

    try {
      const response = await this.http.post(`${this.url.urlString}filters`, formData).toPromise();
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      this.processQueue(); // Continue processing the next request
    }
  }

  // setfilters(formData: any) {
  //   return this.http.post(`${this.url.urlString}filters`, formData).toPromise();
  // }

  getfilters(userId: any) {
    return this.http.get(`${this.url.urlString}filters/${userId}`).toPromise();
  }

  getGoogleSheetProjectSheets() {
    return this.http
      .get(`${this.url.urlString}addons/googleSheet/project`)
      .toPromise();
  }

  getGoogleSheetClientSheets() {
    return this.http
      .get(`${this.url.urlString}addons/googleSheet/client`)
      .toPromise();
  }

  getGoogleSheetResourceSheets() {
    return this.http
      .get(`${this.url.urlString}addons/googleSheet/resource`)
      .toPromise();
  }

  getGoogleSheetPersonalSheets() {
    return this.http
      .get(`${this.url.urlString}addons/googleSheet/personnel`)
      .toPromise();
  }

  setGoogleSheetProjectSheets(formData: any) {
    return this.http
      .post(`${this.url.urlString}addons/googleSheet/project`, formData)
      .toPromise();
  }

  setGoogleSheetClientSheets(formData: any) {
    return this.http
      .post(`${this.url.urlString}addons/googleSheet/client`, formData)
      .toPromise();
  }

  setGoogleSheetResourceSheets(formData: any) {
    return this.http
      .post(`${this.url.urlString}addons/googleSheet/resource`, formData)
      .toPromise();
  }

  setGoogleSheetPersonalSheets(formData: any) {
    return this.http
      .post(`${this.url.urlString}addons/googleSheet/personnel`, formData)
      .toPromise();
  }

  getBookingCustomStatus() {
    return this.http
      .get(`${this.url.urlString}configuration/booking-status`)
      .toPromise();
  }

  setBookingCustomStatus(formData: any) {
    return this.http
      .post(`${this.url.urlString}configuration/booking-status`, formData)
      .toPromise();
  }

  setBookingCustomStatusIndex(eventId, previousIndex, currentIndex) {
    return this.http
      .post(
        `${this.url.urlString}configuration/booking-status/dropanddrag/${eventId}/${previousIndex}/${currentIndex}`,
        {}
      )
      .toPromise();
  }

  parentChildFNIndexing(parentId, previousIndex, currentIndex) {
    return this.http
      .post(
        `${this.url.urlString}function/drag/function/${parentId}/${previousIndex}/${currentIndex}`,
        {}
      )
      .toPromise();
  }

  parentFNIndexing(functionID, parentId) {
    return this.http
      .post(
        `${this.url.urlString}function/draganddrop/${functionID}/${parentId}`,
        {},
        { responseType: "text" }
      )
      .toPromise();
  }

  swapFNIndexing(functionID, parentId) {
    return this.http
      .post(
        `${this.url.urlString}function/swap/${functionID}/${parentId}`,
        {},
        { responseType: "text" }
      )
      .toPromise();
  }

  folderIndexing(parentId, previousIndex, currentIndex) {
    return this.http
      .post(
        `${this.url.urlString}function/drag/root/${parentId}/${previousIndex}/${currentIndex}`,
        {},
        { responseType: "text" }
      )
      .toPromise();
  }

  statusIndexing(funId, previousIndex, currentIndex) {
    return this.http
      .post(
        `${this.url.urlString}configuration/project-status/dropanddrag/${funId}/${previousIndex}/${currentIndex}`,
        {},
        { responseType: "text" }
      )
      .toPromise();
  }

  listIndexChange(id, keywordId, previousIndex, currentIndex) {
    return this.http
      .post(
        `${this.url.urlString}configuration/dragdrop/KeywordList/${id}/${keywordId}/${previousIndex}/${currentIndex}`,
        {},
        { responseType: "text" }
      )
      .toPromise();
  }

  keywordIndexChange(id, previousIndex, currentIndex) {
    return this.http
      .post(
        `${this.url.urlString}configuration/dragdrop/booking-order-form/${id}/${previousIndex}/${currentIndex}`,
        {},
        { responseType: "text" }
      )
      .toPromise();
  }

  getTimeCardDetail(staff, week, year) {
    return this.http
      .get(
        `${this.url.urlString}user-booking/by-week-number?staff=${staff}&weekNumber=${week}&year=${year}`
      )
      .toPromise();
  }

  getAuth() {
    let obj = {
      grant_type: "password",
      client_id: "yLO6pcV892zQfYqexXOWplIbPXSi1sL8",
      client_secret:
        "yppMpGAzsXI3jxj6OoL_eeuJvUm1Uao4eQ_OXvyY7bkG38xGRSOw6Oic4kzsHFZ_",
      scope: "write:packages openid",
      username: "info@nvlogistics.com",
      password: "1211@€roport$$",
      shiprecURL: "https://auth.shiprec.io",
    };
    return this.http
      .post(`${this.url.urlString}shiprec/token`, obj)
      .toPromise();
  }

  getListOfPackage() {
    let obj = {
      access_token: sessionStorage.getItem("shiprecToken"),
    };
    return this.http
      .post(`${this.url.urlString}shiprec/listpackage`, obj)
      .toPromise();
  }

  addPackage(deviceId: any) {
    let body = {
      deviceId: deviceId,
      access_token: sessionStorage.getItem("shiprecToken"),
    };
    return this.http
      .post(`${this.url.urlString}shiprec/addpackage`, body, {
        responseType: "text",
      })
      .toPromise();
  }

  trackPackage(token: any) {
    let obj = {
      token: token,
    };
    return this.http
      .post(`${this.url.urlString}shiprec/trackpackage`, obj)
      .toPromise();
  }

  deviceMetaData(token: any) {
    let obj = {
      access_token: sessionStorage.getItem("shiprecToken"),
      device_token: token,
    };
    return this.http
      .post(`${this.url.urlString}shiprec/devicedata`, obj)
      .toPromise();
  }

  globalSearchApi(formData: any) {
    return this.http.get(`${this.url.urlString}globalkeywords/search?${this.serialize(formData)}`,).toPromise();
  }

  getAllKeywordsTags(){
    return this.http.get(`${this.url.urlString}globalkeywords`).toPromise();
  }

  checkSharedProject(personId:any){
    return this.http.get(`${this.url.urlString}staff/shared-project/${personId}`).toPromise();
  }

  getUserLogin(){
    return this.http.get(`${this.url.urlString}staff/getUserLogin`).toPromise();
  }

  createFrameIoAuth(formData:any){
  
    return this.http.post(`${this.url.urlString}frameio/signin`,formData).toPromise();
  }
  
  toggleStatusPersonnel(formData:any){
  
    return this.http.post(`${this.url.urlString}staff/activate-user`,formData).toPromise();
  }

  create_auth_url(formData){
    const httpOptions: Object = {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${token}`,
        "Content-Type": 'text/html',
        // skip: "true",
      }),
    };
    return this.http.get(`${this.url.urlString}frameio/token`,httpOptions).toPromise();

  }

  setUrlPathFrameio(formData){
   
    return this.http.get(`${this.url.urlString}frameio/callback?${formData}`,).toPromise();

  }

  saveAccessTokenFrameio(formData){
   
    return this.http.post(`${this.url.urlString}frameio/saveRefeshToken`,formData).toPromise();

  }


  getTableTimeFLag(){
    return this.http.get(`${this.url.urlString}configuration/TableTime`).toPromise();

  }

  tableTimeFlagChange(formData){
    return this.http.post(`${this.url.urlString}configuration/TableTime`,formData).toPromise();

  }

  getpolicyPath(){
    return this.http.get(`${this.url.urlString}configuration/manage-policy`).toPromise();

  }

  setpolicyPath(formData){
    return this.http.post(`${this.url.urlString}configuration/manage-policy`,formData).toPromise();

  }


  gerAccessTokenFrameio(formData){
   
    return this.http.get(`${this.url.urlString}frameio/refresh/token?${this.serialize(formData)}`,).toPromise();

  }

  createProjectFromFrameio(teamId:any,formData:any){
   let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };
    return this.http.post(`https://api.frame.io/v2/teams/${teamId}/projects`,formData,httpOptions).toPromise();
  }

  markCommentComplete(commentId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };
    return this.http.post(`https://api.frame.io/v2/comments/${commentId}/complete`,{},httpOptions).toPromise();
  }

  markUnCommentComplete(commentId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };
    return this.http.delete(`https://api.frame.io/v2/comments/${commentId}/complete`,httpOptions).toPromise();
  }

  getframeioTeams(accountId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/accounts/${accountId}/teams`,httpOptions).toPromise();
  }

  getframeioCollab(projectId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "skip": "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/projects/${projectId}/collaborators`,httpOptions).toPromise();
  }
  getframeioAssests(projectId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "skip": "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/assets/${projectId}/children`,httpOptions).toPromise();
  }

  getframeioAssestSingle(projectId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "skip": "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/assets/${projectId}`,httpOptions).toPromise();
  }


  getAllReviewLinks(projectID:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "skip": "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/projects/${projectID}/review_links`,httpOptions).toPromise();
  }


  createFrameioReviewLinks(projectID:any,body){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "skip": "true",
      }),
    };
    return this.http.post(`https://api.frame.io/v2/projects/${projectID}/review_links`,body,httpOptions).toPromise();
  }
  
  getallCommentsFromFrameio(assetId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "skip": "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/assets/${assetId}/comments?`,httpOptions).toPromise();
  }

  getUserAccountInfo(){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/me`,httpOptions).toPromise();
  }

  getAllProjectsByTeam(teamId:any){
    let token =  sessionStorage.getItem('frameioToken')
    const httpOptions: Object = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        skip: "true",
      }),
    };
    return this.http.get(`https://api.frame.io/v2/teams/${teamId}/projects?filter[archived]=all`,httpOptions).toPromise();
  }
}
