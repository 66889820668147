import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SidebarMenuItem } from '../core/entity/sidebarmenuitem';
import { Router, ActivatedRoute, UrlTree, PRIMARY_OUTLET, UrlSegmentGroup, UrlSegment } from '@angular/router';
import { DataService } from '../core/services/data.service';
import { Location } from '@angular/common';
import { variable } from '@angular/compiler/src/output/output_ast';
import { HttpService } from '../core/services/http.service';
import { ToastrTranslateService } from '../core/services/toastr-translate.service';
import { UserRoleService } from '../core/services/user-role.service';
import { CommonUtilService } from '../core/services/common-util-service';
import { SharedService } from '../core/services/shared-service/shared.service';
import { ThemeService } from '../core/services/theme/theme.service';
import { UserDetailsService } from '../core/services/user-details.service';
import { SettingsService } from '../core/services/settings.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menu = [];
  activeMenuEmitter:any=false;
  public activeMenu: any;
  public activeSubMenu: any = null;
  public currentUrl: string;
  public innerCurrentUrl: string;
  public previousActiveMenu: string;
  public projectId: string;
  public showDropdown: boolean;
  public previousLink: string;
  public showSidebarModal: boolean = false;
  public openModalRef: any;
  filterData:any
  id:any;
  items:any=[
    // "/teamium/dashboard/agenda",
    // "/teamium/dashboard/widgets",
    // "/teamium/dashboard/process",
    // "/teamium/dashboard/project",
    // "/teamium/dashboard/kpi",
    // "/teamium/dashboard/vendor"
  ];
  showMenu:boolean;
  intervalTime:any;
  myRole:any;
  loggedInUserDetails:any=null;
  pictureUrl:any='';

  constructor(private location: Location,private themeService:ThemeService ,private translate: 
    TranslateService,private commonService:CommonUtilService, private router: Router, private dataService: DataService, private route: ActivatedRoute,
    private httpService:HttpService,private toasterService:ToastrTranslateService, private userRoleService: UserRoleService,private ud:UserDetailsService,
    private commonUtilService:CommonUtilService,private cd: ChangeDetectorRef,private sharedService:SharedService,private settingsService:SettingsService) {

  }

  ngOnInit() {
    this.settingsService.call(() => {
      this.ud.call(() => {
        this.appNgOnInit();

      });
    });
  }

  appNgOnInit() {

    this.dataService.submenuSourceForBackItem.subscribe(router => {
    console.log(router)

      this.loadMenu(router); 
    });
    this.dataService.openSubmenuItem.subscribe(message => {
      if (this.activeMenu) {
        if (message) {
          let s = message.slice(1, message.length).split('/');
          this.openMenu(s, message)
          this.router.navigate([message]);
        } else {
          this.previousActiveMenu = this.activeMenu;
          this.activeMenuEmitter = true;
          this.currentUrl = this.activeMenu.defaultSubmenuUrl;

          let newUrl: string = this.currentUrl;
          let childLink = this.activeMenu.children.find(el => el.link == this.currentUrl);
          if (childLink.pathAttributes && this.dataService.pathVariable) {
            let variables = '';
            this.dataService.pathVariable.forEach(element => {
              childLink.pathAttributes.forEach(el => {
                if (element[el]) {
                  variables += '/' + element[el];
                }
              });
            });
            newUrl += variables
          }
          this.router.navigate([newUrl]);
        }
      }
    });
    this.intervalTime = setInterval(() => {
      this.myRole = this.userRoleService.myRoles;
      if(this.myRole){
        this.stopInterval();
      }
      }, 2000);
    // this.blockedPanel = true;
    // this.getprojectListInfo();
    // this.getclientsDropdown();
    // this.getvendors();
    // this.getVendorsDropdown();
    // this.getRateCard();
    // this.getRateCardDropdown();
    // this.getAllActiveRole();
    // this.getConfigGroup();
    // this.getAllMarketPlace();
    // this.getAllPersonel();
    // this.getPersonelDropDown();
    // this.getEquipmentDropDown();
    // this.getEquipment();
    // this.getAllfunctionListApi();
    // this.getAllLinkEvent();
    // this.getAllKitListByLoggedInUser();
    // this.getAllBookingEventStatus();
    // this.getKitViewConfiguration();
    // this.getBudgetEvent();
    // this.getBudgetDropDown();
    // this.getShowListForScheduler();
    // this.getAllPlanningEventStatusList();
    // this.getAllNetworks();
    this.getAllService();
    this.getFilters();
    this.loggedInUserDetails = this.sharedService.getLoggedInUserData()
    this.pictureUrl = this.loggedInUserDetails.photo ? this.loggedInUserDetails.photo.url : '';
    console.log(this.loggedInUserDetails)
    // this.getAllAvailableFunction()
  }

 

  stopInterval(){
    if (this.myRole) 
      clearInterval(this.intervalTime);
      this.menu = SidebarMenuItem;
      this.loadMenu(this.router);
  }

  loadMenu(router) {
    this.activeMenu = null;
    this.currentUrl = '';
    this.innerCurrentUrl = "";
    this.previousActiveMenu = '';
    this.showDropdown = true;
    this.activeMenuEmitter = false;

    if (router.url) {
      const tree: UrlTree = router.parseUrl(router.url);
      const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      const s: UrlSegment[] = g.segments;

      let s1 = [];
      s.forEach(e => s1.push(e.path));
      console.log(router.url)
      this.openMenu(s1, router.url);
    }

    console.log(this.showDropdown,this.activeSubMenu)

    

  }

  changeTheme(e){


    if(e == true){  
      this.saveOrUpdateDarkMode(true);
      this.themeService.setDarkMode()
    }
    else {
      this.saveOrUpdateDarkMode(false);
      this.themeService.setLisghtMode()
    }
  }
  
  saveOrUpdateDarkMode(darkMode: boolean){
  let body = {
    "id": this.loggedInUserDetails.id,
    "darkMode":darkMode,
  }

  this.httpService.callApi('saveOrUpdateDarkMode', {body: body }).subscribe((response) => {
    this.loggedInUserDetails.darkMode =  response.darkMode
    this.sharedService.setLoggedInUserData(this.loggedInUserDetails)
    
  }, (error) => {
  }); 
  }

  openProfile(){
    this.router.navigate(['teamium/user/user-profile']);
  }

  logout() {
    // this.doOffline();
    this.setuserFiltersLogout()
    this.httpService.callApi('logout', {}).subscribe((response) => {
      sessionStorage.clear();
      sessionStorage.clear();
   
      this.sharedService.kanbanOffSet = undefined;
      this.sharedService.kanbanData = undefined;
      this.sharedService.setkanbanDoneData(undefined);
      this.sharedService.kanbanDoneOffSet = 0;
      this.sharedService.setkanbanTODoData(undefined);
      this.sharedService.kanbanToDoOffSet = 0;
      this.sharedService.setProjectInfo([]);
      this.sharedService.multipleCallRemover([],'personalList');
      this.sharedService.multipleCallRemover([],'equipmentList');
      let blankArr = []
      this.sharedService.setAllKitListByLoggedInUser(blankArr);
      this.userRoleService.clear();
   
    }, error => {
      
      sessionStorage.clear();
      sessionStorage.clear();

      this.userRoleService.clear();
    });
    this.httpService.logout();
    this.userRoleService.logout();
  

  }

  async setuserFiltersLogout() {
    try {
  
      let userid = sessionStorage.getItem('userId');
      let filterData:any = this.sharedService.getuserFilters()
      if(filterData.staffMemberId != undefined && filterData.staffMemberId != null){
        if(filterData.values != null){
          for (const property in filterData.values) {
            for (let i = 0; i < filterData.values[property].length; i++) {
             if(filterData.values[property][i].filterId != undefined){       
              delete filterData.values[property][i].filterId
             }
             if(filterData.values[property][i].id != undefined){
              delete filterData.values[property][i].id
             }
             console.log(filterData.values[property])
            //  return
             if(filterData.values[property][i].recordKey == "pendingBooking"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dateRangeFeild"){
              continue
             }
             if(filterData.values[property][i].recordKey == "projectTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "tableTimeFlag"){
              continue
             }
             if(filterData.values[property][i].recordKey == "timeFormatUser"){
              continue
             }

             if(filterData.values[property][i].recordKey == "dateFormatUser"){
              continue
             }
             if(filterData.values[property][i].recordKey == "PersistantRange"){
              continue
             }
             if(filterData.values[property][i].mapId == "CUSTOMBORAD"){
              continue
             }
             if(filterData.values[property][i].recordKey == "schedulerTimeZone"){
              continue
             }
             if(filterData.values[property][i].recordKey == "schedulerLabel"){
              continue
             }
             if(filterData.values[property][i].recordKey == "statusTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "extendedContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "moveEvent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "resizeEvent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "weekendContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "buissnessHour"){
              continue
             }
             if(filterData.values[property][i].recordKey == "rosterContent"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedKitId"){
              continue
             }
             if(filterData.values[property][i].recordKey == "board"){
              continue
             }
             if(filterData.values[property][i].recordKey == "list"){
              continue
             }
             if(filterData.values[property][i].recordKey == "kanban"){
              continue
             }
             if(filterData.values[property][i].recordKey == "router"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerClient"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerEquipment"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerPersonal"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerSideNav"){
              continue
             }
             if(filterData.values[property][i].recordKey == "caledarView"){
              continue
             }
             if(filterData.values[property][i].recordKey == "caledarViewScheduleProject"){
              continue
             }
             if(filterData.values[property][i].recordKey == "routerPkanban"){
              continue
             }
             if(filterData.values[property][i].recordKey == "projectCard"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dSubclient"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dDate"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dTheme"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dProjectOwner"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dLocation"){
              continue
             }
             if(filterData.values[property][i].recordKey == "dBuissnessUnit"){
              continue
             }
             if(filterData.values[property][i].recordKey == "LISTFILTERARR"){
              continue
             }
             if(filterData.values[property][i].recordKey == "displayView"){
              continue
             }
             if(filterData.values[property][i].recordKey == "showChartR"){
              continue
             }
             if(filterData.values[property][i].recordKey == "lastDateRange"){
              continue
             }
             if(filterData.values[property][i].recordKey == "reportView"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedPeriod"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedTimeframe"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedYearOption"){
              continue
             }
             if(filterData.values[property][i].recordKey == "selectedMonth"){
              continue
             }
             if(filterData.values[property][i].recordKey == "isPeriodOrTimeframeChanged"){
              continue
             }
             if(filterData.values[property][i].recordKey == "isYearOrMonthChanged"){
              continue
             }
             if(filterData.values[property][i].recordKey == "orderForms"){
              continue
             }
             if(filterData.values[property][i].recordKey == "headerDview"){
              continue
             }
             if(filterData.values[property][i].recordKey == "showTx"){
              continue
             }
            
             filterData.values[property][i].recordValue = ""
              
            }
           
          }
        }
      }
  
      if (filterData?.staffMemberId == null) {
        filterData.staffMemberId = userid
      }
     
      const res: any = await this.commonUtilService.setfilters(filterData)
    
    } catch (error) {
      console.log(error)
    }

  } 

  getShortName(fullName) {
    if(fullName != undefined){
      return fullName
      .split(" ")
      .map((n) => n[0])
      .join("");
    }
   
  }

  openMenu(s: any, url) {
    this.showMenu = false;
    if (url && url.includes('/teamium/rate/rate-card')) {
      url = "/teamium/rate/rate-card";
    }
    if (url && url.includes('/teamium/show/show-estimate')) {
      url = "/teamium/show/show-list";
    }
    if (!s[1]) {
      return;
    }

    if(this.showMenu == false){
      this.items.forEach(element=>{
        if(url==element){
          this.showMenu = true;
          return;
        }
      });
      if(this.showMenu){
        this.activeMenu=this.menu[0];
        return;
      }
        
     }

     if(!this.showMenu){
      for (let dt of this.menu) {
        this.activeMenu = dt;

        if (dt.defaultUrl && dt.defaultUrl == url) {
          if (dt.defalutSubmenuOpen) {
            this.previousLink = dt.defaultSubmenuUrl
            this.activeSubMenu = null;
            this.dataService.openSubmenu();
          }
          return;
        }

        for (let ch of dt.children) {
          if (ch.link && ch.link.includes(s[1])) {
            let newUrl: string = "";
            if (ch.pathAttributes) {
              let arr = new Array(s.length - ch.pathAttributes.length);
              let j = 0;
              for (let i of arr) {
                newUrl += "/" + s[j];
                j = j + 1;
              }
            } else {
              newUrl = url;
            }
            if (ch.link == newUrl) {
              this.activeSubMenu = null;
              this.previousLink = newUrl;
              this.currentUrl = ch.link;
              this.activeMenuEmitter = true;

              this.showDropdown = false;
              return;
            }
          }
          if (ch.children) {
            for (let ch1 of ch.children) {
              if (ch1.link && ch1.link.includes(s[1])) {
                let newUrl: string = "";
                if (ch1.pathAttributes) {
                  let arr = new Array(s.length - ch.pathAttributes.length);
                  let j = 0;
                  for (let i of arr) {
                    newUrl += "/" + s[j];
                    j = j + 1;
                  }
                } else {
                  newUrl = url;
                }
                if (ch1.link == newUrl) {
                  this.activeSubMenu = ch;
                  
                  this.previousLink = newUrl;
                  this.innerCurrentUrl = ch1.link;
                  this.currentUrl = ch.link;
                  this.activeMenuEmitter = true;

                  this.showDropdown = true;
                  return;
                }
              }
            }
          }
        }
      }
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
    sessionStorage.setItem("selectedLanguage", language);
  }

  changeMenuItem(item) {
    console.log(item,this.previousActiveMenu)
    this.activeMenu = item;
    // this.dataService.fromBooking = false
    if (this.previousActiveMenu || this.previousActiveMenu != this.activeMenu) {
      this.activeMenuEmitter = false;

    }
    if (item.defalutSubmenuOpen) {
      console.log(item.defalutSubmenuOpen)
      this.dataService.openSubmenu();
    } else {
      this.currentUrl = item.defaultUrl;
      this.router.navigate([this.currentUrl]);
    }
    this.activeSubMenu = null;
  }

  async getFilters(){
    try {
      let userid = sessionStorage.getItem('userId');
      const res:any = await this.commonService.getfilters(userid);
        let filterData = res;
        if(filterData.staffMemberId != undefined && filterData.staffMemberId != null){
          if(filterData.values != null){
            for (const property in filterData.values) {
              for (let i = 0; i < filterData.values[property].length; i++) {
               if(filterData.values[property][i].filterId != undefined){
                delete filterData.values[property][i].filterId
               }
               if(filterData.values[property][i].id != undefined){
                delete filterData.values[property][i].id
               }
              }
            }
          }
        }
        this.filterData =  filterData;
     
    } catch (error) {
      console.log(error)
    }
}
  



  async setuserFilters() {
    try {
  
      let userid = sessionStorage.getItem('userId');

      if (this.filterData?.staffMemberId == null) {
        this.filterData.staffMemberId = userid
      }


  

      const res: any = await this.commonUtilService.setfilters(this.filterData)
      let filterData = res;
      if(filterData.staffMemberId != undefined && filterData.staffMemberId != null){
        if(filterData.values != null){
          for (const property in filterData.values) {
            console.log(filterData.values[property])
            for (let i = 0; i < filterData.values[property].length; i++) {
             if(filterData.values[property][i].filterId != undefined){
              delete filterData.values[property][i].filterId
             }

             if(filterData.values[property][i].id != undefined){
              delete filterData.values[property][i].id
             }
              
            }
           
          }
        }
      }
      console.log(filterData)
      this.sharedService.setuserFilters(filterData);
    } catch (error) {
      console.log(error)
    }

  } 

  navigate(submenu) {
    if(submenu.title == 'Resources'){
      this.setuserFilters()
      setTimeout(() => {
        
    let variables = '';
    if (submenu.pathAttributes && this.dataService.pathVariable) {
      this.dataService.pathVariable.forEach(element => {
        submenu.pathAttributes.forEach(el => {
          if (element[el]) {
            variables += '/' + element[el];
            this.projectId = variables;
          }
        });
      });
  }
  if(submenu.link=='/teamium/project-invoice/invoice-details'){
    this.httpService.callApi('getProjectByBudget', { pathVariable: '/' + this.projectId }).subscribe(response=>{
      this.callFunction(submenu);
    },error=>{
      this.toasterService.warning('Invoice can not be genrated as project booking is not created yet','Invoicing');
      return;
    })
  }
  if(submenu.title == "Scheduling" && submenu.link !="/teamium/project-scheduler"){
    if(this.dataService.showScheduler == true){
      let scheduleUrl = '/teamium/schedule/schedule-program';
      let vari = '';
      vari += '/' + this.dataService.schedulerShowId;
      scheduleUrl += vari;
    this.router.navigate([scheduleUrl]);
    }
    else 
      return;
  }
  else{
    this.callFunction(submenu);
  }
      }, 800);
    } else {
      
    let variables = '';
    if (submenu.pathAttributes && this.dataService.pathVariable) {
      this.dataService.pathVariable.forEach(element => {
        submenu.pathAttributes.forEach(el => {
          if (element[el]) {
            variables += '/' + element[el];
            this.projectId = variables;
          }
        });
      });
  }
  if(submenu.link=='/teamium/project-invoice/invoice-details'){
    this.httpService.callApi('getProjectByBudget', { pathVariable: '/' + this.projectId }).subscribe(response=>{
      this.callFunction(submenu);
    },error=>{
      this.toasterService.warning('Invoice can not be genrated as project booking is not created yet','Invoicing');
      return;
    })
  }
  if(submenu.title == "Scheduling" && submenu.link !="/teamium/project-scheduler"){
    if(this.dataService.showScheduler == true){
      let scheduleUrl = '/teamium/schedule/schedule-program';
      let vari = '';
      vari += '/' + this.dataService.schedulerShowId;
      scheduleUrl += vari;
    this.router.navigate([scheduleUrl]);
    }
    else 
      return;
  }
  else{
    this.callFunction(submenu);
  }
    }



    
  }
  callFunction(submenu){
    console.log(this.previousLink)
    if (submenu && submenu.link) {
      this.activeSubMenu = submenu;
      let variables = '';
      let newUrl: string = submenu.link;
      if(submenu.title == 'Show'){
        if(this.dataService.showScheduler == false){
        this.dataService.pathVariable = null;
        variables += '/' + null;
        newUrl += variables;
       }
      }
      if (submenu.pathAttributes && this.dataService.pathVariable) {
        this.dataService.pathVariable.forEach(element => {
          submenu.pathAttributes.forEach(el => {
            if (element[el]) {
              variables += '/' + element[el];
              this.projectId = variables;
            }
          });
        });
        newUrl += variables
      }
      if (this.previousLink == submenu.link) {
        if (submenu.children) {
          this.showDropdown = !this.showDropdown;
          if (this.showDropdown) {
            let url = submenu.defaultSubmenuUrl + "" + (submenu.subPathAttributes ? variables : "");
            this.router.navigate([url]);
          }
        }
        return;
      }
    
      this.previousLink = submenu.link;
      console.log(newUrl)
      this.router.navigate([newUrl]);
    }
    else {
      this.activeSubMenu = null;
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  onMouseOver = () => {
    this.openModalRef = setTimeout(() => {
      this.showSidebarModal = true;
    }, 2000)
  }

  onMouseOut = () => {
    clearTimeout(this.openModalRef);
  }

  hideSidebarModal = ($event) => {
    this.showSidebarModal = false;
  }

 //One Time API CAlls


//  async getprojectListInfo(){
//   try {
//     const res: any = await this.commonUtilService.getProjectInfo();
//     if(res.length > 0){
//       this.sharedService.setProjectInfo(res);
//       }
//   } catch (error) {
//    
//   }
//  }


 async getAllService(){
  try {
    const res: any = await this.commonUtilService.getAllService();
   this.sharedService.setAllService(res);  
  } catch (error) {
    
  }
 }



}