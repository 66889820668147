import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { ToastrTranslateService } from '../../core/services/toastr-translate.service';
import { SidebarMenuItem } from '../entity/sidebarmenuitem';
import { timingSafeEqual } from 'crypto';
import { Router } from '@angular/router';
import { SharedService } from './shared-service/shared.service';
import { UserDetailsService } from './user-details.service';
import { CommonUtilService } from './common-util-service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private initialized: boolean = false;
  public myRoles = null;
  public keys: any[];
  private menuAccess:any;
  private funcs = [];
  private errorCount: number = 0;

  constructor(private httpService: HttpService,private sharedService:SharedService,private commonService:CommonUtilService,private userdetailService:UserDetailsService,
    private router: Router,
    private toastr: ToastrTranslateService ) {
      if (sessionStorage.getItem('token')) {
        this.initialize();
      }
  }

  initialize() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this._initialize();
  }

  private _initialize() {

    if (!sessionStorage.getItem('token')) {
      setTimeout(() => this._initialize(), 4000);
      return;
    }
    this.myRoles = null;
    this.setMenuAccess();
    
    this.httpService.callApi('getMyRoles', {body: ''}).subscribe(resp => {
    

      this.myRoles = resp;
      // this.getFilters()
      this.checkSharedProject();
        this.setupAccess();
      sessionStorage.setItem('userRole',JSON.stringify(resp))
      this.sharedService.setuserRole(resp);
      
      let temp = this.funcs;
      this.funcs = [];
      temp.forEach(fn => fn());
      this.errorCount = 0;
      // this.setRoleForPlanningEvent(resp[0])

    }, err => {
      if (this.errorCount === 0 && this.router.url.indexOf('/signin') < 0) {
        this.toastr.warning(err.error.message, 'Unable to get role');
      }
      this.errorCount++;
      // TODO: Log out
      sessionStorage.clear();
      sessionStorage.clear();

      setTimeout(() => this._initialize(), 4000);
    });
    
  }

  setRoleForPlanningEvent(roles:any){
    this.httpService.callApi('getRoles', {body: ''}).subscribe(resp => {
        let rolesArr = resp || []
        if(rolesArr.length > 0){
          rolesArr.forEach(element => {
            if(roles.roleId == element.roleId){
               sessionStorage.setItem('userRole',JSON.stringify(element))
            }
          });
        }

    }, err => {
 
      console.log(err)
    });

  }

  async checkSharedProject(){
    try {
      let userData = this.sharedService?.getLoggedInUserData()
      const res:any =  await this.commonService.checkSharedProject(userData.id)
      if(res.length > 0){
        sessionStorage.setItem('sharedProject',JSON.stringify(true))
      } else {
        sessionStorage.setItem('sharedProject',JSON.stringify(false))
      }
      this.setupAccess();
      // console.log(res);
    } catch (error) {
      console.log(error)
    }
  }

  call(fn: () => any) {
    if (this.myRoles == null) {
      this.funcs.push(fn);
    } else {
      fn();
    }
  }

  logout() {
    this.initialized = false;
    this.myRoles = null;
    this.setMenuAccess();
    this.funcs = [];
    this.errorCount = 0;
    sessionStorage.clear()
    sessionStorage.clear()
    this.userdetailService.loggedInUserDetails=null;
    this.router.navigate(['signin']).then(() => {
      console.log('reload called')
      document.location.reload();
    });
  }

  setMenuAccess(){
    this.menuAccess = {
      dashboard: false,
      shows: false,
      projects: false,
      personnel: false,
      equipment: false,
      schedule: false,
      // supply: false,
      client:false,
      report: false,
      config: false,
      assets:false,
      // contract:false,
      projectSubModules:{},
      showSubModules:{},
      schedulingSubModules:{},
      equipmentSubModules:{},
      personnelSubModules:{},
      settingsSubModules:{},
      dashboardSubModule:{}
    };
    this.keys = [
      'dashboard',
      'shows',
      'projects',
      'personnel',
      // 'contract',
      'equipment',
      'schedule',
      // 'supply',
      'client',
      'report',
      'config',
      'assets'
    ];
  }

  public clear(){
    this.myRoles = null;
    this.logout();
  }

  private setupAccess() {
    let userData = this.sharedService?.getLoggedInUserData()
    let sharedProjectData = sessionStorage.getItem('sharedProject')
    // this.myRoles.forEach(element => {
      // if ((!element.vendorR || !element.vendorW) && element.roleId > 0) {
      this.menuAccess.dashboard = this.sharedService.checkValueInArray(true,this.myRoles,'dashboardR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'dashboardW',null);
      this.menuAccess.shows = this.sharedService.checkValueInArray(true,this.myRoles,'showsR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'showsW',null);
      this.menuAccess.projects = this.sharedService.checkValueInArray(true,this.myRoles,'projectsR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'projectsW',null);
      this.menuAccess.personnel = this.sharedService.checkValueInArray(true,this.myRoles,'personnelR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'personnelW',null);
      this.menuAccess.schedule = this.sharedService.checkValueInArray(true,this.myRoles,'scheduleR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'scheduleW',null); 
      this.menuAccess.equipment = this.sharedService.checkValueInArray(true,this.myRoles,'equipmentR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'equipmentW',null);
      // this.menuAccess.supply = this.menuAccess.supply || element.suppliesR || element.suppliesW;
      this.menuAccess.report = this.sharedService.checkValueInArray(true,this.myRoles,'reportR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'reportW',null); 
      this.menuAccess.config = this.sharedService.checkValueInArray(true,this.myRoles,'configR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'configW',null); 
      this.menuAccess.assets = this.sharedService.checkValueInArray(true,this.myRoles,'assetR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'assetW',null); 
      this.menuAccess.client = this.sharedService.checkValueInArray(true,this.myRoles,'clientR',null) || this.sharedService.checkValueInArray(true,this.myRoles,'assetW',null); 
      this.menuAccess.contract =  true;

      this.menuAccess.showSubModules  = {
        estimate:true,
        budget: true,
        project: true,
      };

      this.menuAccess.projectSubModules  = {
        planning: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','planning') ,
        budgeting: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','budgeting') ,
        booking: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','booking'), 
        scheduling: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','scheduling'),
        wall: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','wall'),
        notes: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','notes'),
        history: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','history'),
        // contract: false || (element.projectSubModules!=null?   element.projectSubModules.contract:true),
        procurement: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','procurement') ,
        financial: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','financial') ,
        invoicing: this.sharedService.checkValueInArray(true,this.myRoles,'projectSubModules','invoicing')
      };

      this.menuAccess.schedulingSubModules  = {
        resource: this.sharedService.checkValueInArray(true,this.myRoles,'schedulingSubModules','resource') ,
        projectCal: this.sharedService.checkValueInArray(true,this.myRoles,'schedulingSubModules','projectCal') ,
        show: this.sharedService.checkValueInArray(true,this.myRoles,'schedulingSubModules','show') ,
        roster: this.sharedService.checkValueInArray(true,this.myRoles,'schedulingSubModules','roster') ,
        event: this.sharedService.checkValueInArray(true,this.myRoles,'schedulingSubModules','event') ,
        planning: this.sharedService.checkValueInArray(true,this.myRoles,'schedulingSubModules','planning')
      };

      this.menuAccess.equipmentSubModules  = {
        edit: this.sharedService.checkValueInArray(true,this.myRoles,'equipmentSubModules','edit') ,
        epackage: this.sharedService.checkValueInArray(true,this.myRoles,'equipmentSubModules','epackage') ,
        checkIn: this.sharedService.checkValueInArray(true,this.myRoles,'equipmentSubModules','checkIn'),
        maintenance: this.sharedService.checkValueInArray(true,this.myRoles,'equipmentSubModules','maintenance')
      };

      this.menuAccess.personnelSubModules  = {
        edit: this.sharedService.checkValueInArray(true,this.myRoles,'personnelSubModules','edit'),
        showReel: this.sharedService.checkValueInArray(true,this.myRoles,'personnelSubModules','showReel'),
        contract: this.sharedService.checkValueInArray(true,this.myRoles,'personnelSubModules','contract'),
        expenses: this.sharedService.checkValueInArray(true,this.myRoles,'personnelSubModules','expenses'),
        leaveManagement: this.sharedService.checkValueInArray(true,this.myRoles,'personnelSubModules','leaveManagement'),
        timeReport: this.sharedService.checkValueInArray(true,this.myRoles,'personnelSubModules','timeReport')
      };

      this.menuAccess.settingsSubModules  = {
        vendors: this.sharedService.checkValueInArray(true,this.myRoles,'settingsSubModules','vendors')
      };

      this.menuAccess.dashboardSubModule  = {
        agenda: this.menuAccess.dashboard,
        schedule: this.menuAccess.dashboard,
        leave: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','leave'),
        roster: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','roster'),
        project: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','project'),
        task: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','task'),
        workOrder: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','workOrder'),
        transmissionOrder: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','transmissionOrder') ,
        rundown: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','rundown') ,
        editorialRundown: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','editorialRundown') ,
        financialRundown: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','finanicalRundown'),
        kpi: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','kpi') ,
        whareHouse: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','whareHouse'),
        checkInPage: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','checkInPage') ,
        vendor: this.sharedService.checkValueInArray(true,this.myRoles,'dashboardSubModule','vendor'),
      };
    //  }
    //  });

   

    
    SidebarMenuItem[0].show = this.menuAccess.dashboard || userData.userType ==  2 || userData.userType ==  4;
    SidebarMenuItem[1].show = this.menuAccess.shows;
    SidebarMenuItem[2].show = this.menuAccess.projects || userData.userType ==  4 ||  sharedProjectData == 'true' // Acceess to regular user and client;
    SidebarMenuItem[3].show = this.menuAccess.schedule;
    SidebarMenuItem[4].show = this.menuAccess.equipment;
    SidebarMenuItem[5].show = this.menuAccess.personnel;
    // SidebarMenuItem[6].show = this.menuAccess.contract;
    // SidebarMenuItem[6].show = this.menuAccess.supply;
    SidebarMenuItem[6].show = this.menuAccess.assets;
    SidebarMenuItem[7].show = this.menuAccess.client;
    SidebarMenuItem[8].show = userData?.userSettingDTO?.notificationSettingAccess && (userData.userType ==  1 || userData.userType ==  6 );
    SidebarMenuItem[9].show = this.menuAccess.report;
    SidebarMenuItem[10].show = this.menuAccess.config;

    SidebarMenuItem[0].children[0].show = this.menuAccess.dashboardSubModule.agenda || userData.userType ==  2  // Acceess to regular user and Collaborator ;
    SidebarMenuItem[0].children[1].show = this.menuAccess.dashboardSubModule.schedule ||  (userData.userType ==  2 && userData.contractSettingDTO.contractType == "INTERNAL")  // Acceess to regular user and Collaborator and Client
    SidebarMenuItem[0].children[2].show =  (userData.userType ==  2 && userData.contractSettingDTO.contractType == "INTERNAL") || userData.userType ==  4  || ((userData.userType ==  1 || userData.userType ==  6) && this.menuAccess.dashboardSubModule.roster)
    SidebarMenuItem[0].children[3].show = (userData.userType ==  2 && userData.contractSettingDTO.contractType == "INTERNAL") || userData.userType ==  4  || ((userData.userType ==  1 || userData.userType ==  6) && this.menuAccess.dashboardSubModule.leave)
    SidebarMenuItem[0].children[4].show = this.menuAccess.dashboardSubModule.project;
    SidebarMenuItem[0].children[5].show = this.menuAccess.dashboardSubModule.task;
    SidebarMenuItem[0].children[6].show = this.menuAccess.dashboardSubModule.workOrder;
    SidebarMenuItem[0].children[7].show = this.menuAccess.dashboardSubModule.transmissionOrder;
    SidebarMenuItem[0].children[8].show = this.menuAccess.dashboardSubModule.rundown;
    SidebarMenuItem[0].children[9].show = this.menuAccess.dashboardSubModule.editorialRundown;
    SidebarMenuItem[0].children[10].show = this.menuAccess.dashboardSubModule.financialRundown;
    SidebarMenuItem[0].children[11].show = this.menuAccess.dashboardSubModule.kpi;
    SidebarMenuItem[0].children[12].show = this.menuAccess.dashboardSubModule.whareHouse;
    SidebarMenuItem[0].children[13].show = this.menuAccess.dashboardSubModule.checkInPage;
    SidebarMenuItem[0].children[14].show = this.menuAccess.dashboardSubModule.vendor;
    
    //for setting show sub menu
    // SidebarMenuItem[1].children[0].show = this.menuAccess.showSubModules.estimate;
    // SidebarMenuItem[1].children[1].show = this.menuAccess.showSubModules.budget;
    // SidebarMenuItem[1].children[1].show = this.menuAccess.showSubModules.project;

    // for setting project sub menu
    SidebarMenuItem[2].children[0].show = this.menuAccess.projectSubModules.budgeting || userData.userType ==  4 ;
    SidebarMenuItem[2].children[1].show = this.menuAccess.projectSubModules.booking ||  sharedProjectData == 'true';
  
    // SidebarMenuItem[2].children[2].show = this.menuAccess.projectSubModules.wall;
    // SidebarMenuItem[2].children[3].show = this.menuAccess.projectSubModules.notes;
    // SidebarMenuItem[2].children[4].show = this.menuAccess.projectSubModules.procurement;
    // SidebarMenuItem[2].children[5].show = this.menuAccess.projectSubModules.invoicing;
    // SidebarMenuItem[2].children[6].show = this.menuAccess.projectSubModules.financial;

    // for setting scheduler sub menu
    // SidebarMenuItem[3].children[0].show = this.menuAccess.schedulingSubModules.resource;
    // SidebarMenuItem[3].children[1].show = this.menuAccess.schedulingSubModules.projectCal;
    // SidebarMenuItem[3].children[2].show = this.menuAccess.schedulingSubModules.show;
    // SidebarMenuItem[3].children[1].show = this.menuAccess.schedulingSubModules.roster;
    // SidebarMenuItem[3].children[2].show = this.menuAccess.schedulingSubModules.event;
    // SidebarMenuItem[3].children[5].show = this.menuAccess.schedulingSubModules.planning;

    // for setting resource sub menu
    // SidebarMenuItem[4].children[0].show = this.menuAccess.equipmentSubModules.edit;
    // SidebarMenuItem[4].children[1].show = this.menuAccess.equipmentSubModules.epackage;

    // for setting personnel sub menu
    // SidebarMenuItem[5].children[0].show = this.menuAccess.personnelSubModules.edit;
    // SidebarMenuItem[5].children[1].show = this.menuAccess.personnelSubModules.contract;
    // SidebarMenuItem[5].children[2].show = this.menuAccess.personnelSubModules.expenses;
    // SidebarMenuItem[5].children[3].show = this.menuAccess.personnelSubModules.leaveManagement;
    // SidebarMenuItem[5].children[4].show = this.menuAccess.personnelSubModules.timeReport;

    //for setting  settings sub menu
    // SidebarMenuItem[10].children[0].show = this.menuAccess.settingsSubModules.vendors;   

    
  }

  
  private hasCompanyAccess(group: string, companyId: number, accessType: string) {
    let ret:boolean = false;
    this.myRoles.forEach(element => {
      if (companyId == element.companyId) {
        ret = ret || element[group + accessType];
      }
    });
    return ret;
  }

  hasCompanyWriteAccess(group: string, companyId: number) {
    return this.hasCompanyAccess(group, companyId, 'W');
  }

  hasGlobalWriteAccess(group: string) {
    return this.hasCompanyWriteAccess(group, 0);
  }

  hasCompanyReadAccess(group: string, companyId: number) {
    return this.hasCompanyAccess(group, companyId, 'R');
  }

  hasGlobalReadAccess(group: string) {
    return this.hasCompanyReadAccess(group, 0);
  }

  hasReadAccess(group: string, companyId: number) {
    return this.hasGlobalReadAccess(group) || this.hasCompanyReadAccess(group, companyId);
  }

  hasWriteAccess(group: string, companyId: number) {
    return this.hasGlobalWriteAccess(group) || this.hasCompanyWriteAccess(group, companyId);
  }

  hasAnyCompanyWriteAccess(group: string) {
    return this.myRoles.find(element => element[group + 'W']) !== undefined;
  }


  
}
