import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class UrlsService {
  urlObject: any;
  urlString: any;
  constructor(configService: ConfigService) {
    
    if(window.location.hostname == 'localhost'){
      // this.urlString ="https://516e-3-131-112-230.ngrok.io/teamiumapp/" 
      this.urlString ="https://www.teamium.net/teamiumapp/" 
        // this.urlString ="http://localhost:8080/"

    } else { 
      this.urlString = `${window.location.protocol}//${window.location.hostname}/teamiumapp/`
    }

    this.urlObject = {
      login: {
        method: "POST",
        url: this.urlString + "oauth/token",
      },
      logout: {
        method: "POST",
        url: this.urlString + "user/logout",
      },
      validateToken: {
        method: "GET",
        url: this.urlString + "sso/okta-sso-login",
      },
      validateTokenAzure: {
        method: "GET",
        url: this.urlString + "sso/azure-sso-login",
      },
      changestatus: {
        method: "PUT",
        url: this.urlString + "user/login-status",
      },
      getpolicyPath: {
        method: "GET",
        url: this.urlString + "privacypolicy/",
      },
      translate: {
        method: "GET",
        url: this.urlString + "configuration/getAllLanguages/fr.json",
      },
      getAllMilestoneTypes: {
        method: "GET",
        url: this.urlString + "project/milestone-types",
      },
      getstatus: {
        method: "GET",
        url: this.urlString + "user/login-status",
      },
      getEquipments: {
        method: "GET",
        url: this.urlString + "equipment",
      },
      getUserProjects: {
        method: "GET",
        url: this.urlString + "project/logged-in-user-projects",
      },
      getPersonals: {
        method: "GET",
        url: this.urlString + "staff",
      },
      getAvailablePersonals: {
        method: "GET",
        url: this.urlString + "staff/available",
      },
      getPersonalById: {
        method: "GET",
        url: this.urlString + "staff",
      },
      getPersonalDropdownData: {
        method: "GET",
        url: this.urlString + "staff/dropdown",
      },
      getPersonalReel: {
        method: "GET",
        url: this.urlString + "staff",
      },
      savePersonalReel: {
        method: "POST",
        url: this.urlString + "staff",
      },
      getEquipmentById: {
        method: "GET",
        url: this.urlString + "equipment/",
      },
      getEquipmentBundle: {
        method: "GET",
        url: this.urlString + "equipment/bundle",
      },
      getEquipmentDropdownData: {
        method: "GET",
        url: this.urlString + "equipment/dropdown",
      },
      getEquipmentDropdataOnchange: {
        method: "Post",
        url: this.urlString + "equipment/getEquipmentBarcodeByType",
      },
      getEquipmentForPackage: {
        method: "GET",
        url: this.urlString + "package/equipment/",
      },
      deleteEquipment: {
        method: "DELETE",
        url: this.urlString + "equipment/",
      },
      deleteEquipmentCheck: {
        method: "GET",
        url: this.urlString + "configuration/servicefunction/checkequipment/",
      },
      deleteEquipmentkitcheck: {
        method: "GET",
        url: this.urlString + "equipment/linkedKit/",
      },
      savePackage: {
        method: "POST",
        url: this.urlString + "package/",
      },
      saveExclusive: {
        method: "POST",
        url: this.urlString + "package/exclusive/",
      },
      getEquipmentForSeletedPacakge: {
        method: "GET",
        url: this.urlString + "package/",
      },
      getEquipmentForSeletedPacakgeExclusive: {
        method: "GET",
        url: this.urlString + "package/exclusive/",
      },
      getPersonnelById: {
        method: "GET",
        url: this.urlString + "staff/",
      },
      getWorkedSunday: {
        method: "GET",
        url: this.urlString + "user-leave-record/",
      },
      saveEquipment: {
        method: "POST",
        url: this.urlString + "equipment",
      },
      uploadPicture: {
        method: "POST",
        url: this.urlString + "upload/picture/",
      },
      uplaodPictureForProjectWall: {
        method: "POST",
        url: this.urlString + "upload/uplaodPictureForProjectWall/",
      },
      uploadAttachment: {
        method: "POST",
        url: this.urlString + "upload/attachment/",
      },
      uploadMultipleAttachment: {
        method: "POST",
        url: this.urlString + "upload/attachment-list/",
      },

      uploadAttachmentForProjectWall: {
        method: "POST",
        url: this.urlString + "upload/uploadAttachmentForProjectWall/",
      },
      deleteAttachments: {
        method: "DELETE",
        url: this.urlString + "upload/attachment/",
      },
      deleteWOAttachments: {
        method: "DELETE",
        url: this.urlString + "upload/attachment-order/",
      },
      getPersonnelDropdownData: {
        method: "GET",
        url: this.urlString + "staff/dropdown",
      },
      getRoleDropdownData: {
        method: "GET",
        url: this.urlString + "user-role/role/active",
      },
      deleteReel: {
        method: "DELETE",
        url: this.urlString + "staff/reel/",
      },
      getLoggedInUser: {
        method: "GET",
        url: this.urlString + "staff/logged-in-user",
      },
      savePersonnel: {
        method: "POST",
        url: this.urlString + "staff",
      },
      savePersonnelInfo: {
        method: "POST",
        url: this.urlString + "staff/basic",
      },
      savePersonnelAddress: {
        method: "POST",
        url: this.urlString + "staff/update-address",
      },
      savePersonnelUpdateAvai: {
        method: "POST",
        url: this.urlString + "staff/update-availability",
      },
      savePersonnelSkillAndLang: {
        method: "POST",
        url: this.urlString + "staff/update-skill-lang",
      },
      savePersonnelLabpurRule: {
        method: "POST",
        url: this.urlString + "staff/update-labourRule",
      },
      updateUserCredentials: {
        method: "POST",
        url: this.urlString + "staff/user-credentials/",
      },
      deletePersonnel: {
        method: "DELETE",
        url: this.urlString + "staff/",
      },
       binPersonnel: {
        method: "DELETE",
        url: this.urlString + "staff/",
      },restorePersonnel: {
        method: "POST",
        url: this.urlString + "staff/user-restore/",
      },
      getProjectsByStatus: {
        method: "GET",
        url: this.urlString + "budget/status",
      },
      getRecentViewedProjects: {
        method: "GET",
        url: this.urlString + "budget/recent/0",
      },
      getFunctions: {
        method: "GET",
        url: this.urlString + "function",
      },
      getFunctionDropdown: {
        method: "GET",
        url: this.urlString + "function/dropdown",
      },
      getFunctionList: {
        method: "GET",
        url: this.urlString + "function/roots",
      },
      saveFunction: {
        method: "POST",
        url: this.urlString + "function",
      },
      deleteFunction: {
        method: "DELETE",
        url: this.urlString + "function",
      },
      getAllFunctions: {
        method: "GET",
        url: this.urlString + "resource/functions",
      },
      getAllAvailableFunctions: {
        method: "GET",
        url: this.urlString + "resource/functions/available",
      },
      getAllEvents: {
        method: "GET",
        url: this.urlString + "event/filter/by-function",
      },
      getAllLinkedEvents: {
        method: "GET",
        url: this.urlString + "event/by-function/linked",
      },
      getAllEventCalEvents: {
        method: "GET",
        url: this.urlString + "event/filter/by-function/event-cal",
      },
      saveOrUpdateEvent: {
        method: "POST",
        url: this.urlString + "event",
      },
      saveOrUpdateEventAll: {
        method: "POST",
        url: this.urlString + "event/shiftAll",
      },
      assignEventToLoggedInUser: {
        method: "POST",
        url: this.urlString + "event/assign/to/logged-in-user",
      },
      unassignResourceOnBooking: {
        method: "POST",
        url: this.urlString + "booking/unassign/resource/",
      },
      getProjectResources: {
        method: "GET",
        url: this.urlString + "booking/function/by-project",
      },
      addOrderFormOnBooking: {
        method: "POST",
        url: this.urlString + "booking/add/order-form/",
      },
      getAvailableOrderForms: {
        method: "GET",
        url: this.urlString + "booking/booking-order-form/by-order-type",
      },
      removeOrderFormOnBooking: {
        method: "POST",
        url: this.urlString + "booking/remove/order-form/",
      },
      removeOrderFormOnBookingId: {
        method: "POST",
        url: this.urlString + "booking/removebyid/order-form",
      },
      changeStatusOfOrderForm: {
        method: "POST",
        url: this.urlString + "booking/order-form/change-status",
      },
      getProjectsEvent: {
        method: "GET",
        url: this.urlString + "event/by-project",
      },
      getProjectById: {
        method: "GET",
        url: this.urlString + "budget/",
      },
      getProjectDropdown: {
        method: "GET",
        url: this.urlString + "budget/dropdown",
      },
      getProjectDropdownwithClientAndBU: {
        method: "GET",
        url: this.urlString + "budget/dropdown/",
      },
      getShowList: {
        method: "GET",
        url: this.urlString + "budget/dropdown/shows",
      },
      getShowListForScheduler: {
        method: "GET",
        url: this.urlString + "budget/scheduler/shows",
      },
      saveBudget: {
        method: "POST",
        url: this.urlString + "budget",
      },
      createProjectBooking: {
        method: "POST",
        url: this.urlString + "project/booking",
      },
      sendForgotPasswordLink: {
        method: "POST",
        url: this.urlString + "password/recovery-request",
      },
      verifyRecoveryLink: {
        method: "POST",
        url: this.urlString + "password/recovery-link/validate",
      },
      resetPassword: {
        method: "POST",
        url: this.urlString + "password/recover",
      },

      //ratecard
      getRatesByFunction: {
        method: "GET",
        url: this.urlString + "rate/function/",
      },

      getBookingByBookingId: {
        method: "GET",
        url: this.urlString + "project/",
      },

      linkOrUnlinBookingEvents: {
        method: "POST",
        url: this.urlString + "event/link",
      },
      getAllLinkinedBookingEvents: {
        method: "GET",
        url: this.urlString + "event/link",
      },
      getProjectByBudget: {
        method: "GET",
        url: this.urlString + "project/booking",
      },
      getInProgressProjectsByCategory: {
        method: "GET",
        url: this.urlString + "project/in-progress/category",
      },
      saveBookingEvents: {
        method: "POST",
        url: this.urlString + "booking/events",
      },
      checkCollapse: {
        method: "POST",
        url: this.urlString + "event/check-collapse",
      },
      projectBasicInfo: {
        method: "GET",
        url: this.urlString + "project/info",
      },
      copyEvent: {
        method: "POST",
        url: this.urlString + "event/copy",
      },
      findBudgetIdFromProjectId: {
        method: "GET",
        url: this.urlString + "project/budget-id",
      },
      getProjectDescriptionDate: {
        method: "GET",
        url: this.urlString + "budget/description/",
      },
      splitEvent: {
        method: "POST",
        url: this.urlString + "event/split",
      },
      findBookingEventFromLineId: {
        method: "GET",
        url: this.urlString + "booking/by-origin",
      },
      changesProjectStatus: {
        method: "Post",
        url: this.urlString + "budget/change/status",
      },
      changesShowStatus: {
        method: "Post",
        url: this.urlString + "show/change/status",
      },
      deleteBudget: {
        method: "DELETE",
        url: this.urlString + "budget/",
      },
      saveProject: {
        method: "POST",
        url: this.urlString + "project",
      },
      deleteProject: {
        method: "DELETE",
        url: this.urlString + "project/",
      },
      applyDateToAllLinesOnBuduget: {
        method: "POST",
        url: this.urlString + "budget/apply-date-to-all/",
      },
      applyDateToAllLinesOnProject: {
        method: "POST",
        url: this.urlString + "project/apply-date-to-all/",
      },
      addLineToBudget: {
        method: "POST",
        url: this.urlString + "budget/line/",
      },
      addLineToProject: {
        method: "POST",
        url: this.urlString + "project/line/",
      },
      addGroupOnBooking: {
        method: "POST",
        url: this.urlString + "booking/add-to-group/",
      },
      removeGroupOnBooking: {
        method: "POST",
        url: this.urlString + "booking/remove-group/",
      },
      getBookingsByLoggedInGroups: {
        method: "GET",
        url: this.urlString + "booking/by-logged-in-user-group",
      },
      getBookingsByLoggedInUser: {
        method: "GET",
        url: this.urlString + "booking/by-logged-in-user/",
      },
      startOrEndUserBookingTime: {
        method: "POST",
        url: this.urlString + "booking/add/start-or-end-time/",
      },
      changeCompletionTimeOnBooking: {
        method: "POST",
        url: this.urlString + "booking/change-completion/",
      },

      getClients: {
        method: "GET",
        url: this.urlString + "client",
      },
      getClient: {
        method: "GET",
        url: this.urlString + "client",
      },
      saveClient: {
        method: "POST",
        url: this.urlString + "client",
      },
      getClientDropdown: {
        method: "GET",
        url: this.urlString + "client/dropdown",
      },
      deleteClient: {
        method: "DELETE",
        url: this.urlString + "client",
      },
      getAllLinkEventID: {
        method: "GET",
        url: this.urlString + "event/link",
      },
      deleteLineFromBudget: {
        method: "DELETE",
        url: this.urlString + "budget/line/",
      },
      deleteLineFromBokking: {
        method: "DELETE",
        url: this.urlString + "project/line/",
      },

      getRecentViewedShows: {
        method: "GET",
        url: this.urlString + "show/recent/0",
      },
      getShowsByStatus: {
        method: "GET",
        url: this.urlString + "show/status",
      },

      getVendors: {
        method: "GET",
        url: this.urlString + "vendor",
      },
      saveVendor: {
        method: "POST",
        url: this.urlString + "vendor",
      },
      deleteVendor: {
        method: "DELETE",
        url: this.urlString + "vendor",
      },

      saveShow: {
        method: "POST",
        url: this.urlString + "show",
      },
      addLineToShow: {
        method: "POST",
        url: this.urlString + "show/line/",
      },
      deleteLineFromShow: {
        method: "DELETE",
        url: this.urlString + "show/line/",
      },
      createAllSessions: {
        method: "POST",
        url: this.urlString + "show/session/all/",
      },
      createSession: {
        method: "POST",
        url: this.urlString + "show/session/",
      },
      deleteShow: {
        method: "DELETE",
        url: this.urlString + "show/",
      },
      getShowById: {
        method: "GET",
        url: this.urlString + "show/",
      },
      getVendorDropdown: {
        method: "GET",
        url: this.urlString + "vendor/dropdown",
      },
      rosterEvent: {
        method: "GET",
        url: this.urlString + "event",
      },
      saveOrUpdateRosterEvent: {
        method: "POST",
        url: this.urlString + "roster-event",
      },
      getAllRosterEvent: {
        method: "GET",
        url: this.urlString + "roster-event",
      },
      deleteRosterEventById: {
        method: "DELETE",
        url: this.urlString + "roster-event/",
      },
      pasteRosterEvent: {
        method: "POST",
        url: this.urlString + "roster-event/copy/",
      },
      getEventForAllProject: {
        method: "GET",
        url: this.urlString + "event/project",
      },
      getProjectEvent: {
        method: "GET",
        url: this.urlString + "budget/event",
      },
      saveProjectEvent: {
        method: "POST",
        url: this.urlString + "budget/event",
      },
      getAllProgramEvent: {
        method: "GET",
        url: this.urlString + "show/event",
      },

      rate: {
        method: "GET",
        url: this.urlString + "rate",
      },
      rateById: {
        method: "GET",
        url: this.urlString + "rate/",
      },
      deleteRateById: {
        method: "DELETE",
        url: this.urlString + "rate/",
      },
      addRate: {
        method: "POST",
        url: this.urlString + "rate",
      },
      getAllDropdown: {
        method: "GET",
        url: this.urlString + "rate/dropdown",
      },
      editRateCardById: {
        method: "POST",
        url: this.urlString + "rate/",
      },
      applyDateToAllLinesOnShow: {
        method: "POST",
        url: this.urlString + "show/apply-date-to-all/",
      },
      saveNewTemplate: {
        method: "POST",
        url: this.urlString + "budget/template/",
      },
      addTemplateToBudget: {
        method: "POST",
        url: this.urlString + "budget/apply/template/",
      },
      saveNewTemplateToBooking: {
        method: "POST",
        url: this.urlString + "project/template/",
      },
      addTemplateToBooking: {
        method: "POST",
        url: this.urlString + "project/apply/template/",
      },
      printPDFForBudget: {
        method: "GET",
        url: this.urlString + "budget/pdf",
      },

      createOrder: {
        method: "POST",
        url: this.urlString + "order",
      },
      createOrderInline: {
        method: "POST",
        url: this.urlString + "order/inLine/",
      },
      updateOrder: {
        method: "POST",
        url: this.urlString + "order/update",
      },
      loggedInUser: {
        method: "GET",
        url: this.urlString + "staff/logged-in-user",
      },
      updateAdmin2FA: {
        method: "GET",
        url: this.urlString + "configuration/toggle_admin_2fa",
      },
      getOrdersByProjectId: {
        method: "GET",
        url: this.urlString + "order/by-project/",
      },
      getProcurmentByProjectId: {
        method: "GET",
        url: this.urlString + "project/procurment/",
      },
      printPDFForBooking: {
        method: "POST",
        url: this.urlString + "project/pdf/callsheet",
      },
      printPDFForPackingList: {
        method: "POST",
        url: this.urlString + "project/pdf/packing",
      },
      printPDFForProductionStatement: {
        method: "GET",
        url: this.urlString + "project/pdf/production",
      },
      printPDFForOrder: {
        method: "GET",
        url: this.urlString + "order/pdf",
      },
      getLabourRule: {
        method: "GET",
        url: this.urlString + "labour-rule/",
      },
      financialApprovalRequired: {
        method: "GET",
        url: this.urlString + "configuration/financialapprovalrequired/",
      },
      requestApprovalRequired: {
        method: "GET",
        url: this.urlString + "configuration/notificaionapprovalrequired",
      },
      saveFinancialApprovalRequired: {
        method: "POST",
        url: this.urlString + "configuration/financialapproval/",
      },
      saveRequestApprovalRequired: {
        method: "POST",
        url: this.urlString + "configuration/notificationapproval",
      },
      saveLabourRule: {
        method: "POST",
        url: this.urlString + "labour-rule/",
      },
      saveLabourRuleHoliday: {
        method: "POST",
        url: this.urlString + "labour-rule/holiday/",
      },
      saveLabourRuleStaff: {
        method: "POST",
        url: this.urlString + "labour-rule/staff-member/",
      },
      getContractDetailsById: {
        method: "GET",
        url: this.urlString + "project",
      },
      updateContractById: {
        method: "PUT",
        url: this.urlString + "contract",
      },
      deleteLabourRuleHoliday: {
        method: "DELETE",
        url: this.urlString + "labour-rule/holiday/",
      },
      deleteLabourRuleStaff: {
        method: "DELETE",
        url: this.urlString + "labour-rule/staff-member/",
      },
      getCompany: {
        method: "GET",
        url: this.urlString + "company",
      },
      saveCompany: {
        method: "POST",
        url: this.urlString + "company",
      },
      getSignatureData: {
        method: "Get",
        url: this.urlString + "digital-signature/envelope",
      },
      uploadEquipementSheet: {
        method: "POST",
        url: this.urlString + "equipment/upload/spreadsheet",
      },
      uploadStaffSheet: {
        method: "POST",
        url: this.urlString + "staff/upload/spreadsheet",
      },
      validateStaffSheet: {
        method: "POST",
        url: this.urlString + "staff/validate/spreadsheet",
      },
      uploadClientSheet: {
        method: "POST",
        url: this.urlString + "client/upload/spreadsheet",
      },
      uploadVendorSheet: {
        method: "POST",
        url: this.urlString + "vendor/upload/spreadsheet",
      },
      getTeamplateData: {
        method: "GET",
        url: this.urlString + "configuration/edition-template",
      },
      saveSignatureData: {
        method: "POST",
        url: this.urlString + "digital-signature/envelope",
      },
      getGroups: {
        method: "GET",
        url: this.urlString + "configuration/group",
      },
      saveGroup: {
        method: "POST",
        url: this.urlString + "configuration/group",
      },
      deleteGroup: {
        method: "DELETE",
        url: this.urlString + "configuration/group",
      },
      saveOrUpdateUserBooking: {
        method: "POST",
        url: this.urlString + "user-booking",
      },
      findUserBookingByUser: {
        method: "GET",
        url: this.urlString + "user-booking",
      },
      getTooltip: {
        method: "GET",
        url: this.urlString + "project/booking/tooltip/",
      },
      deleteUserBooking: {
        method: "DELETE",
        url: this.urlString + "user-booking",
      },
      deleteUserBookingWithProject: {
        method: "DELETE",
        url: this.urlString + "user-booking/time-report",
      },
      findWeeklyWorkingDuration: {
        method: "GET",
        url: this.urlString + "user-booking/week",
      },
      deleteCompany: {
        method: "DELETE",
        url: this.urlString + "company",
      },
      deleteLabourRule: {
        method: "DELETE",
        url: this.urlString + "labour-rule",
      },
      getFormat: {
        method: "GET",
        url: this.urlString + "configuration/format",
      },
      saveFormat: {
        method: "POST",
        url: this.urlString + "configuration/format",
      },
      deleteFormat: {
        method: "DELETE",
        url: this.urlString + "configuration/format",
      },
      getCategory: {
        method: "GET",
        url: this.urlString + "configuration/category",
      },
      saveCategory: {
        method: "POST",
        url: this.urlString + "configuration/category",
      },
      deleteCategory: {
        method: "DELETE",
        url: this.urlString + "configuration/category",
      },
      getProjectMilestone: {
        method: "GET",
        url: this.urlString + "configuration/milestone/project",
      },
      saveProjectMilestone: {
        method: "POST",
        url: this.urlString + "configuration/milestone",
      },
      deleteProjectMilestone: {
        method: "DELETE",
        url: this.urlString + "configuration/milestone/project",
      },
      getAllEventsByGroup: {
        method: "GET",
        url: this.urlString + "event/by-group",
      },
      getEquipmentMilestone: {
        method: "GET",
        url: this.urlString + "configuration/milestone/equipment",
      },
      saveEquipmentMilestone: {
        method: "POST",
        url: this.urlString + "configuration/milestone",
      },
      deleteEquipmentMilestone: {
        method: "DELETE",
        url: this.urlString + "configuration/milestone/equipment",
      },
      deleteEnvelope: {
        method: "DELETE",
        url: this.urlString + "digital-signature/envelope",
      },

      getExpensesReportByStaffMemberId: {
        method: "GET",
        url: this.urlString + "perosnal-expenses-report/by-staff-member",
      },
      getProjectsForExpensesReport: {
        method: "GET",
        url:
          this.urlString +
          "perosnal-expenses-report/projects/by-staff-member/",
      },
      saveOrUpdateExpensesReport: {
        method: "POST",
        url: this.urlString + "perosnal-expenses-report",
      },
      deleteExpensesReport: {
        method: "DELETE",
        url: this.urlString + "perosnal-expenses-report/",
      },
      changesExpensesReportStatus: {
        method: "POST",
        url: this.urlString + "perosnal-expenses-report/change-status",
      },
      findAllProjectTodoOrProgress: {
        method: "GET",
        url: this.urlString + "project/progress",
      },
      saveBookingFromUserScheduler: {
        method: "POST",
        url: this.urlString + "user-booking/time-report",
      },
      getUserProjectTimeReport: {
        method: "GET",
        url: this.urlString + "user-booking/time-report",
      },
      createRecurrenceEvent: {
        method: "POST",
        url: this.urlString + "event/recurrence",
      },
      getPersonnelDocument: {
        method: "GET",
        url: this.urlString + "configuration/document-type",
      },
      savePersonnelDocument: {
        method: "POST",
        url: this.urlString + "configuration/document-type",
      },
      deletePersonnelDocument: {
        method: "DELETE",
        url: this.urlString + "configuration/document-type",
      },
      getAllChannels: {
        method: "GET",
        url: this.urlString + "configuration/channel",
      },
      saveOrUpdateChannel: {
        method: "POST",
        url: this.urlString + "configuration/channel",
      },
      getChannelById: {
        method: "GET",
        url: this.urlString + "configuration/channel",
      },
      deleteChannel: {
        method: "DELETE",
        url: this.urlString + "configuration/channel",
      },
      getAllLeaveType: {
        method: "GET",
        url: this.urlString + "leave-type",
      },
      saveOrUpdateLeaveType: {
        method: "POST",
        url: this.urlString + "leave-type",
      },
      inActivateLeaveType: {
        method: "DELETE",
        url: this.urlString + "leave-type",
      },
      deleteDaysOff: {
        method: "DELETE",
        url: this.urlString + "leave-type/delete",
      },
      findUserLeaveRecordByStaffId: {
        method: "GET",
        url: this.urlString + "user-leave-record",
      },
      saveOrUpdateUserLeaveRecord: {
        method: "POST",
        url: this.urlString + "user-leave-record/leave-record",
      },
      saveOrUpdateUserLeaveRequest: {
        method: "POST",
        url: this.urlString + "user-leave-record/leave-request",
      },

      changeStatusOfLeaveRequest: {
        method: "POST",
        url: this.urlString + "user-leave-record/leave-request/status",
      },
      deleteLeaveRequest: {
        method: "DELETE",
        url: this.urlString + "user-leave-record/leave-request/",
      },
      getBookingOrderFormList: {
        method: "GET",
        url: this.urlString + "configuration/booking-order-form",
      },
      getClientFieldFormList: {
        method: "GET",
        url: this.urlString + "configuration/client-field",
      },
      saveClientFieldForm: {
        method: "POST",
        url: this.urlString + "configuration/client-field",
      },
      deleteClientFieldFormById: {
        method: "DELETE",
        url: this.urlString + "configuration/client-field/",
      },
      saveBookingOrderForm: {
        method: "POST",
        url: this.urlString + "configuration/booking-order-form",
      },
      deleteBookingOrderFormById: {
        method: "DELETE",
        url: this.urlString + "configuration/booking-order-form/",
      },
      getProjectVolumeCharData: {
        method: "GET",
        url: this.urlString + "dashboard/booking-volume",
      },
      getProjectVolumeSpreadsheetData: {
        method: "GET",
        url: this.urlString + "dashboard/booking-volume/export",
      },
      getProjectRevenueData: {
        method: "GET",
        url: this.urlString + "dashboard/project-revenue",
      },
      getProjectRevenueSpreadsheetData: {
        method: "GET",
        url: this.urlString + "dashboard/project-revenue/export",
      },
      dashboadActualComparison: {
        method: "GET",
        url: this.urlString + "dashboard/actual-comparison",
      },
      getActualVolumnSpreadsheetData: {
        method: "GET",
        url: this.urlString + "dashboard/actual-comparison/export",
      },
      getBusinessFunctionCharData: {
        method: "GET",
        url: this.urlString + "dashboard/project-bussiness-function",
      },
      getBusinessFunctionSpreadsheetData: {
        method: "GET",
        url: this.urlString + "dashboard/project-bussiness-function/export",
      },
      getPersonnelSkill: {
        method: "GET",
        url: this.urlString + "configuration/skill",
      },
      savePersonnelSkill: {
        method: "POST",
        url: this.urlString + "configuration/skill",
      },
      deletePersonnelSkill: {
        method: "DELETE",
        url: this.urlString + "configuration/skill",
      },
      getPersonnelContract: {
        method: "GET",
        url: this.urlString + "configuration/staff_contract_type",
      },
      deletePersonnelContract: {
        method: "DELETE",
        url: this.urlString + "configuration/staff_contract_type",
      },
      savePersonnelContract: {
        method: "POST",
        url: this.urlString + "configuration/staff_contract_type",
      },
    
      dashboadActualDataApi: {
        method: "GET",
        url: this.urlString + "dashboard/project-actual",
      },
      getProjectActualSpreadsheetData: {
        method: "GET",
        url: this.urlString + "dashboard/project-actual/export",
      },
      printPDFForShowBudget: {
        method: "GET",
        url: this.urlString + "show/budget/pdf",
      },
      dashboadBudgetDataApi: {
        method: "GET",
        url: this.urlString + "dashboard/project-budgeting",
      },
      getProjectBudgetSpreadsheetData: {
        method: "GET",
        url: this.urlString + "dashboard/project-budgeting/export",
      },
      getEditionTemplates: {
        method: "GET",
        url: this.urlString + "configuration/edition-template",
      },
      saveEditionTemplate: {
        method: "POST",
        url: this.urlString + "configuration/edition-template",
      },
      getEditionTemplatesById: {
        method: "GET",
        url: this.urlString + "configuration/edition-template",
      },
      deleteEditionTemplates: {
        method: "DELETE",
        url: this.urlString + "configuration/edition-template",
      },
      getFunctionUses: {
        method: "GET",
        url: this.urlString + "dashboard/functional-uses",
      },
      getFunctionSpreadsheetData: {
        method: "GET",
        url: this.urlString + "dashboard/functional-uses/export",
      },
      getKeywordList: {
        method: "GET",
        url: this.urlString + "configuration/keyword",
      },
      getKeywordById: {
        method: "GET",
        url: this.urlString + "configuration/keyword/",
      },
      deleteKeywordById: {
        method: "DELETE",
        url: this.urlString + "configuration/keyword/",
      },
      saveKeyword: {
        method: "POST",
        url: this.urlString + "configuration/keyword",
      },
      getCurrencyList: {
        method: "GET",
        url: this.urlString + "configuration/currency",
      },
      getCurrencyById: {
        method: "GET",
        url: this.urlString + "configuration/currency/",
      },
      getActiveCurrencyList: {
        method: "GET",
        url: this.urlString + "configuration/currency/status",
      },
      saveCurrency: {
        method: "POST",
        url: this.urlString + "configuration/currency",
      },
      saveCurrencyList: {
        method: "POST",
        url: this.urlString + "configuration/currency/list/update",
      },
      getTimeReportByWeek: {
        method: "GET",
        url: this.urlString + "dashboard/week/time-report/export",
      },
      getAnnualPersonnelTimeReport: {
        method: "GET",
        url: this.urlString + "dashboard/annual-personnel/time-report/export",
      },
      getAnnualMasterTimeReport: {
        method: "GET",
        url:
          this.urlString + "dashboard/annual-personnel/DB-time-report/export",
      },
      getWeeklyPersonnelTimeReport: {
        method: "GET",
        url: this.urlString + "dashboard/weekly-personnel/time-report/export",
      },
      editUserProfile: {
        method: "POST",
        url: this.urlString + "staff/edit-profile/",
      },
      getAllTimeZone: {
        method: "GET",
        url: this.urlString + "time-zone",
      },
      getDigitalSignature: {
        method: "GET",
        url: this.urlString + "staff/digital-signature/",
      },
      "x`": {
        method: "POST",
        url: this.urlString + "staff/digital-signature",
      },
      getBookingConflictData: {
        method: "GET",
        url: this.urlString + "dashboard/booking-conflict",
      },
      getExpensesReport: {
        method: "GET",
        url: this.urlString + "perosnal-expenses-report/pdf/expensereport/",
      },
      getALLSignatureRecipient: {
        method: "GET",
        url: this.urlString + "digital-signature/get-all-recipient/",
      },
      signDocument: {
        method: "POST",
        url: this.urlString + "digital-signature/sign",
      },
      saveDirector: {
        method: "POST",
        url: this.urlString + "configuration/director",
      },
      getDirector: {
        method: "GET",
        url: this.urlString + "configuration/director/",
      },
      deleteDirector: {
        method: "DELETE",
        url: this.urlString + "configuration/director/",
      },
      saveNewShowTemplate: {
        method: "POST",
        url: this.urlString + "show/template/",
      },
      addTemplateToShow: {
        method: "POST",
        url: this.urlString + "show/apply/template/",
      },
      getProjectFinancial: {
        method: "GET",
        url: this.urlString + "project/financial/",
      },
      getATAExport: {
        method: "GET",
        url: this.urlString + "project/ata-report/export/",
      },
      getUnscheduledBooking: {
        method: "GET",
        url: this.urlString + "dashboard/unscheduled-booking",
      },
      getPackagePDF: {
        method: "POST",
        url: this.urlString + "project/pdf/package",
      },
      getInvoice: {
        method: "GET",
        url: this.urlString + "invoice/",
      },
      getInvoiceAmount: {
        method: "GET",
        url: this.urlString + "invoice/amount/",
      },
      genrateInvoice: {
        method: "POST",
        url: this.urlString + "invoice",
      },
      getInvoiceByProjectId: {
        method: "GET",
        url: this.urlString + "invoice/project/",
      },
      getGroupedInvoiceByProjectId: {
        method: "GET",
        url: this.urlString + "invoice/group/project/",
      },
      getContractPdf: {
        method: "GET",
        url: this.urlString + "contract/pdf/",
      },
      getTimeCardDetail: {
        method: "GET",
        url: this.urlString + "user-booking/by-week-number",
      },
      getContractDetail: {
        method: "POST",
        url: this.urlString + "configuration/contract-editor",
      },
      getContractType: {
        method: "GET",
        url: this.urlString + "configuration/getcontract-editor",
      },
      getSelectedContract: {
        method: "GET",
        url: this.urlString + "configuration/contract-editor/",
      },
      saveShowType: {
        method: "POST",
        url: this.urlString + "configuration/show-type",
      },
      getShowTypeList: {
        method: "GET",
        url: this.urlString + "configuration/show-type",
      },
      editShowTypeList: {
        method: "GET",
        url: this.urlString + "configuration/show-type/",
      },
      deleteShowType: {
        method: "Delete",
        url: this.urlString + "configuration/show-type/",
      },
      getEquipmentList: {
        method: "GET",
        url: this.urlString + "project/equipment-list-checkout",
      },
      recordCheckInBookings: {
        method: "GET",
        url: this.urlString + "project/equipment-check-in/",
      },

      bookingCheckout: {
        method: "POST",
        url: this.urlString + "project/equipment-check-out",
      },
      bookingCheckIn: {
        method: "POST",
        url: this.urlString + "project/equipment-check-in",
      },
      getEquipmentBarcodeCheckin: {
        method: "POST",
        url: this.urlString + "project/equipment-check-in",
      },
      getProjectSequentialLines: {
        method: "GET",
        url: this.urlString + "project/sequential-booking/",
      },
      getProjectSequentialDropdown: {
        method: "GET",
        url: this.urlString + "budget/sequential-booking/dropdown",
      },
      confirmProcessing: {
        method: "POST",
        url: this.urlString + "project/sequential-booking",
      },
      sequentialLine: {
        method: "POST",
        url: this.urlString + "project/sequential-line/",
      },
      startOrEndSequentialBookingTime: {
        method: "POST",
        url: this.urlString + "project/sequential-line-start/",
      },
      changeCompletionSequenTimeOnBooking: {
        method: "POST",
        url: this.urlString + "project/sequential-line-completion/",
      },
      unassignResourceOnSequential: {
        method: "POST",
        url: this.urlString + "project/sequential-line-status/",
      },
      getNotificationTemplate: {
        method: "GET",
        url: this.urlString + "notification/template/",
      },
      saveOrUpdateNotificationTemplate: {
        method: "POST",
        url: this.urlString + "notification/template/",
      },
      deleteNotificationTemplateById: {
        method: "DELETE",
        url: this.urlString + "notification/template/",
      },
      getLoggedInUserNotificationList: {
        method: "GET",
        url: this.urlString + "notification/app-notification/",
      },
      updateNotificationStatus: {
        method: "POST",
        url: this.urlString + "notification/app-notification-status/",
      },
      deleteNotificationById: {
        method: "DELETE",
        url: this.urlString + "notification/app-notification/",
      },
      saveColor: {
        method: "POST",
        url: this.urlString + "configuration/color",
      },
      getColors: {
        method: "GET",
        url: this.urlString + "configuration/color",
      },
      deleteColor: {
        method: "Delete",
        url: this.urlString + "configuration/color/",
      },
      saveVendorsCategory: {
        method: "POST",
        url: this.urlString + "configuration/vendor-category",
      },
      editVendorsCategoryList: {
        method: "GET",
        url: this.urlString + "configuration/vendor-category/",
      },
      getVendorsCategoryList: {
        method: "GET",
        url: this.urlString + "configuration/vendor-category",
      },
      deleteVendersCategory: {
        method: "DELETE",
        url: this.urlString + "configuration/vendor-category",
      },
      getClientCategoryList: {
        method: "GET",
        url: this.urlString + "configuration/client-category",
      },
      editClientCategoryList: {
        method: "GET",
        url: this.urlString + "configuration/client-category/",
      },
      saveClientCategory: {
        method: "POST",
        url: this.urlString + "configuration/client-category",
      },
      deleteClientCategory: {
        method: "DELETE",
        url: this.urlString + "configuration/client-category",
      },
      getCompanyModeConfiguration: {
        method: "GET",
        url: this.urlString + "configuration/mode",
      },
      projectBudget: {
        method: "GET",
        url: this.urlString + "budget/project-budget-report",
      },
      projectActualPerCategory: {
        method: "GET",
        url: this.urlString + "budget/project-actual-per-category-report",
      },
      projectActualPerFunction: {
        method: "GET",
        url: this.urlString + "budget/project-actual-per-function-report",
      },
      projectActualPerFunctionChart: {
        method: "GET",
        url: this.urlString + "budget/project-actual-per-function-report-chart",
      },
      getExtraWorkHoursByStaffId: {
        method: "GET",
        url: this.urlString + "user-leave-record/extraWorkHours",
      },
      updateNumberOfDaysByStaffId: {
        method: "POST",
        url:
          this.urlString +
          "user-leave-record/update-numberOfDays-from-extra-working-hours",
      },
      personalTimesheet: {
        method: "GET",
        url: this.urlString + "user-booking/week",
      },
      saveInlineBooking: {
        method: "POST",
        url: this.urlString + "project/planning/booknow/booking-request",
      },
      getAllPersonnelList: {
        method: "GET",
        url: this.urlString + "staff/getStaffmemberForDropDown",
      },
      getAllEquipmentList: {
        method: "GET",
        url: this.urlString + "equipment/dropdown/basic",
      },
      operatorList: {
        method: "POST",
        url: this.urlString + "booking/add-to-operator/",
      },
      removeOperatorList: {
        method: "POST",
        url: this.urlString + "booking/remove-operator/",
      },
      getAllProjectLines: {
        method: "GET",
        url: this.urlString + "dashboard/process-dashboard",
      },
      saveKitGroup: {
        method: "POST",
        url: this.urlString + "configuration/kit",
      },
      saveKitUser: {
        method: "POST",
        url: this.urlString + "configuration/kit/user",
      },
      getAllKitList: {
        method: "GET",
        url: this.urlString + "configuration/kit",
      },
      getAllKitListByLoggedInUser: {
        method: "GET",
        url: this.urlString + "configuration/kit/user",
      },
      deleteKit: {
        method: "DELETE",
        url: this.urlString + "configuration/kit",
      },
      getAllKitById: {
        method: "GET",
        url: this.urlString + "event/filter/by-kit",
      },
      getAllKitByIdLinked: {
        method: "GET",
        url: this.urlString + "event/by-kit/linked",
      },
      getAllKitByIdOrderData: {
        method: "GET",
        url: this.urlString + "event/by-kit/workorders",
      },
      getAllKitByIdOtherData: {
        method: "GET",
        url: this.urlString + "event/filter/by-kit/event-cal",
      },
    
      getAllEventsById: {
        method: "POST",
        url: this.urlString + "event/by-functions",
      },
      getAllLicenceList: {
        method: "GET",
        url: this.urlString + "configuration/manage-licence",
      },
      saveLicenceList: {
        method: "POST",
        url: this.urlString + "configuration/manage-licence",
      },
      workOrderType: {
        method: "POST",
        url: this.urlString + "project/pdf/workorder/",
      },
      projectSplitLine: {
        method: "POST",
        url: this.urlString + "project/saveSplitLine",
      },
      getSplitLinesByLineID: {
        method: "GET",
        url: this.urlString + "project/getSplitLineCount",
      },
      getSettings: {
        method: "GET",
        url: this.urlString + "configuration/settings",
      },
      setSettings: {
        method: "POST",
        url: this.urlString + "configuration/settings",
      },
      dropDownNotification: {
        method: "GET",
        url: this.urlString + "notification/dropdown/",
      },
      savePhaseBooking: {
        method: "POST",
        url: this.urlString + "configuration/different-booking-phase",
      },
      deletePhaseBooking: {
        method: "DELETE",
        url: this.urlString + "configuration/different-booking-phase",
      },
      getAllPhaseBookings: {
        method: "GET",
        url: this.urlString + "configuration/different-booking-phase",
      },
      getAllProcessBookingFilter: {
        method: "GET",
        url: this.urlString + "dashboard/process-dashboard-by-filter",
      },
      getEquipmentReport: {
        method: "GET",
        url: this.urlString + "budget/equipment-report/",
      },
      getBookingHistory: {
        method: "GET",
        url: this.urlString + "project/exportPersonnelHistoryReport/export",
      },
      saveRole: {
        method: "POST",
        url: this.urlString + "user-role",
      },
      getRoles: {
        method: "GET",
        url: this.urlString + "user-role/role/active",
      },
      getMyRoles: {
        method: "GET",
        url: this.urlString + "user-role",
      },
      getUserRoles: {
        method: "GET",
        url: this.urlString + "user-role/user/",
      },
      getCompanyDropdownForRole: {
        method: "GET",
        url: this.urlString + "company/dropdown/role",
      },
      getCompanyBasicInfo: {
        method: "GET",
        url: this.urlString + "company/basic-info",
      },
      getPrefArr: {
        method: "GET",
        url: this.urlString + "userTemplate/getAll",
      },
      getFinancialReport: {
        method: "GET",
        url: this.urlString + "project/exportFinancialReport/export",
      },
      saveLineBilling: {
        method: "POST",
        url: this.urlString + "project/line/saveBilling",
      },
      saveBudgetaryToggle: {
        method: "POST",
        url: this.urlString + "order/line/saveBudgetaryToggle",
      },
      paymentStatusDone: {
        method: "POST",
        url: this.urlString + "invoice/savePaymentDone",
      },
      notifyAll: {
        method: "POST",
        url: this.urlString + "notification/notify-all",
      },
      getWorkOrder: {
        method: "GET",
        url: this.urlString + "booking/getWorkOrderByBooking/",
      },
      getTravelOrder: {
        method: "GET",
        url: this.urlString + "booking/getTravelOrderByBooking/",
      },
      getMediaOrder: {
        method: "GET",
        url: this.urlString + "booking/getMediaOrderByBooking/",
      },
      saveOrderForm: {
        method: "POST",
        url: this.urlString + "booking/add/order-form/",
      },
      startOrStopEvent: {
        method: "POST",
        url: this.urlString + "booking/add/start-or-end-time-status/",
      },
      changePassword: {
        method: "POST",
        url: this.urlString + "staff/change-password",
      },
      saveActual: {
        method: "POST",
        url: this.urlString + "project/line/saveActual",
      },
      invoiceList: {
        method: "GET",
        url: this.urlString + "invoice/invoice-list",
      },
      agingBalance: {
        method: "GET",
        url: this.urlString + "invoice/aging-balance",
      },
      findNonArchivedProjects: {
        method: "GET",
        url: this.urlString + "budget/status/non-archived",
      },
      getClientNameForProject: {
        method: "GET",
        url: this.urlString + "budget/non-archived/client/",
      },
      getRecentProject: {
        method: "GET",
        url: this.urlString + "budget/non-archived/recent/0",
      },
      getUnassignedBookings: {
        method: "GET",
        url: this.urlString + "budget/non-archived/bookings/",
      },
      notificationCount: {
        method: "GET",
        url: this.urlString + "notification/app-notification/count",
      },
      notificationPageNo: {
        method: "GET",
        url: this.urlString + "notification/app-notification",
      },
      getRecentNotifications: {
        method: "GET",
        url: this.urlString + "notification/app-notification/recent/",
      },
      showSummary: {
        method: "GET",
        url: this.urlString + "show/status/summary/",
      },
      getAllUsersApprovedLeaves: {
        method: "GET",
        url: this.urlString + "user-leave-record/approved-leaves",
      },
      getManageByDropdownForProjects: {
        method: "GET",
        url: this.urlString + "staff/projects-manage-by-dropdown",
      },
      getManageByDropdownForShows: {
        method: "GET",
        url: this.urlString + "staff/shows-manage-by-dropdown",
      },
      getProjectBasicDetails: {
        method: "GET",
        url: this.urlString + "project/project-baisc-details",
      },
      getBookingLinesByRecordId: {
        method: "GET",
        url: this.urlString + "project/booking-lines",
      },
      getProjectIdByQuotationId: {
        method: "GET",
        url: this.urlString + "project/get-projectId-from-quotationId",
      },
      getProjectContactsById: {
        method: "GET",
        url: this.urlString + "project/contacts",
      },
      getQuotationBasicDetails: {
        method: "GET",
        url: this.urlString + "budget/project-baisc-details",
      },
      getQuotationContactsById: {
        method: "GET",
        url: this.urlString + "budget/contacts",
      },
      getInProgressRecordsForDashboard: {
        method: "GET",
        url: this.urlString + "project/dashboard/progress",
      },
      saveProjectInvoice: {
        method: "POST",
        url: this.urlString + "project/save-project-invoice",
      },
      getMilestonesByProjectId: {
        method: "GET",
        url: this.urlString + "budget/milestone/",
      },
      getTemplateByProjectId: {
        method: "GET",
        url: this.urlString + "budget/template/",
      },
      getAuditMessages: {
        method: "GET",
        url: this.urlString + "budget/audit/",
      },
      getUserPreferences: {
        method: "GET",
        url: this.urlString + "user/user-preferences",
      },
      setUserPreferences: {
        method: "POST",
        url: this.urlString + "user/user-preferences",
      },
      deleteRoleById: {
        method: "DELETE",
        url: this.urlString + "user-role/",
      },
      deleteTemplateById: {
        method: "DELETE",
        url: this.urlString + "userTemplate/",
      },
      equipmentMaintenance: {
        method: "POST",
        url: this.urlString + "equipment/maintenance",
      },
      getEquipmentMaintenance: {
        method: "GET",
        url: this.urlString + "equipment/maintenance/",
      },
      getArchivedProject: {
        method: "GET",
        url: this.urlString + "budget/status/archived",
      },
      getCancelledProject: {
        method: "GET",
        url: this.urlString + "budget/status/cancelled",
      },
      getProjectDropdownForFunctionCatalog: {
        method: "GET",
        url: this.urlString + "budget/dropdown/function-catalog/",
      },
      saveBookingBasis: {
        method: "POST",
        url: this.urlString + "configuration/event-basis",
      },
      getAllBookingEventBasis: {
        method: "GET",
        url: this.urlString + "configuration/event-basis-existing",
      },
      deleteEventBasis: {
        method: "DELETE",
        url: this.urlString + "configuration/event-basis/",
      },
      bookNow: {
        method: "POST",
        url: this.urlString + "post-production/booking/book-now",
      },
      saveMarkUp: {
        method: "POST",
        url: this.urlString + "project/financial/saveMarkUp",
      },
      saveProjectStatus: {
        method: "POST",
        url: this.urlString + "configuration/project-status",
      },
      getProjectStatus: {
        method: "GET",
        url: this.urlString + "configuration/project-status",
      },
      deleteProjectStatus: {
        method: "Delete",
        url: this.urlString + "configuration/project-status/",
      },
      updateIsSelectedOnProjectStatus: {
        method: "POST",
        url: this.urlString + "configuration/updateIsSelectedOnProjectStatus",
      },
      getProjectTitleById: {
        method: "GET",
        url: this.urlString + "project/get-title-by-id",
      },
      saveInvoiceToggle: {
        method: "POST",
        url: this.urlString + "perosnal-expenses-report/saveInvoiceToogle",
      },
      getProjectByUserDefinedStatus: {
        method: "GET",
        url: this.urlString + "project/getProjectByUserDefinedStatus",
      },
      getProjectsForUserDefinedPage: {
        method: "GET",
        url: this.urlString + "project/getProjectsForUserDefinedPage",
      },
      saveMarketPlace: {
        method: "POST",
        url: this.urlString + "staff/market-place",
      },
      getMarketPlace: {
        method: "GET",
        url: this.urlString + "staff/market-place",
      },
      getProductionGroups: {
        method: "GET",
        url: this.urlString + "configuration/production-group",
      },
      saveProductionGroup: {
        method: "POST",
        url: this.urlString + "configuration/production-group",
      },
      deleteProductionGroup: {
        method: "DELETE",
        url: this.urlString + "configuration/production-group",
      },
      saveProjectBudget: {
        method: "POST",
        url: this.urlString + "budget/saveProjectBudget",
      },
      getProjectBudget: {
        method: "GET",
        url: this.urlString + "budget/getProjectBudget",
      },
      saveUserActaul: {
        method: "POST",
        url: this.urlString + "user-booking/actualize-user-time",
      },
      getUserBookingById: {
        method: "GET",
        url: this.urlString + "user-booking/get-user-booking/",
      },
      getAllProjectFees: {
        method: "GET",
        url: this.urlString + "configuration/project-fees",
      },
      saveProjectFee: {
        method: "POST",
        url: this.urlString + "configuration/project-fees",
      },
      deleteProjectFee: {
        method: "DELETE",
        url: this.urlString + "configuration/project-fees",
      },
      getAllProjectFeesPerFunctionByRecordId: {
        method: "GET",
        url: this.urlString + "project/project-fees-per-function-per-record/",
      },
      updateFeeStatus: {
        method: "POST",
        url: this.urlString + "project/add-project-fees/",
      },
      saveInvoicExpenseToggle: {
        method: "POST",
        url: this.urlString + "invoice/saveInvoicExpenseToggle/",
      },
      getCallSheetDetailsByByBookingId: {
        method: "GET",
        url: this.urlString + "project/call-sheet/",
      },
      saveCallSheetDetails: {
        method: "POST",
        url: this.urlString + "call-sheet/update",
      }, enableDisable: {
        method: "POST",
        url: this.urlString + "call-sheet/enable",
      },
      expensesInvoice: {
        method: "POST",
        url: this.urlString + "invoice/expenses-invoice/",
      },
      saveOrUpdateMediaBitrate: {
        method: "POST",
        url: this.urlString + "configuration/media-bitrate",
      },
      getAllMediaBitrateList: {
        method: "GET",
        url: this.urlString + "configuration/media-bitrate",
      },
      getMediaBitrateById: {
        method: "GET",
        url: this.urlString + "configuration/media-bitrate/",
      },
      deleteMediaBitrate: {
        method: "DELETE",
        url: this.urlString + "configuration/media-bitrate",
      },
      saveOrUpdateMediaSupport: {
        method: "POST",
        url: this.urlString + "configuration/media-support",
      },
      getAllMediaSupportList: {
        method: "GET",
        url: this.urlString + "configuration/media-support",
      },
      getMediaSupportById: {
        method: "GET",
        url: this.urlString + "configuration/media-support/",
      },
      deleteMediaSupport: {
        method: "DELETE",
        url: this.urlString + "configuration/media-support",
      },
      saveOrUpdateMediaType: {
        method: "POST",
        url: this.urlString + "configuration/media-type",
      },
      getAllMediaTypeList: {
        method: "GET",
        url: this.urlString + "configuration/media-type",
      },
      getMediaTypeById: {
        method: "GET",
        url: this.urlString + "configuration/media-type/",
      },
      deleteMediaType: {
        method: "DELETE",
        url: this.urlString + "configuration/media-type",
      },
      getMediaDropDownData: {
        method: "GET",
        url: this.urlString + "media/getMediaDropDownData",
      },
      getAssetDropDownData: {
        method: "GET",
        url: this.urlString + "asset/getAssetDropDownData",
      },
      saveProjectField: {
        method: "POST",
        url: this.urlString + "configuration/project-field",
      },
      getAllProjectFields: {
        method: "GET",
        url: this.urlString + "configuration/project-field",
      },
      deleteProjectField: {
        method: "DELETE",
        url: this.urlString + "configuration/project-field",
      },
      saveOrUpdateAsset: {
        method: "POST",
        url: this.urlString + "asset",
      },
      getAllAssetList: {
        method: "GET",
        url: this.urlString + "asset",
      },
      deleteAssetById: {
        method: "DELETE",
        url: this.urlString + "asset/",
      },
      getAssetById: {
        method: "GET",
        url: this.urlString + "asset/getAssetById/",
      },
      getAllAssestByRecordId: {
        method: "GET",
        url: this.urlString + "asset/getAssetByRecordId/",
      },
      saveOrUpdateMedia: {
        method: "POST",
        url: this.urlString + "media",
      },
      getMediaListByAsset: {
        method: "GET",
        url: this.urlString + "media/getMediaListByAsset/",
      },
      saveOrUpdateShipping: {
        method: "POST",
        url: this.urlString + "media/shipping",
      },
      getShippingByMedia: {
        method: "POST",
        url: this.urlString + "media/getShippingByMedia",
      },
      getMediaBarcodeByType: {
        method: "POST",
        url: this.urlString + "media/getMediaBarcodeByType",
      },
      getRateCardReport: {
        method: "GET",
        url: this.urlString + "rate/ratecard-report/",
      },
      cloneRecord: {
        method: "POST",
        url: this.urlString + "project/clone/",
      },
      cloneBooking: {
        method: "POST",
        url: this.urlString + "project/clone/booking/",
      },
      saveBookingComment: {
        method: "POST",
        url: this.urlString + "booking/comment",
      },
      getBookingComment: {
        method: "GET",
        url: this.urlString + "booking/comment",
      },
      uploadRateCardSheet: {
        method: "POST",
        url: this.urlString + "rate/upload/spreadsheet",
      },
      uploadFunctionSheet: {
        method: "POST",
        url: this.urlString + "function/upload/spreadsheet",
      },
      saveOrUpdateBudgetOption: {
        method: "POST",
        url: this.urlString + "configuration/budget-option",
      },
      getAllBudgetOptionList: {
        method: "GET",
        url: this.urlString + "configuration/budget-option",
      },
      getBudgetOptionById: {
        method: "GET",
        url: this.urlString + "configuration/budget-option/",
      },
      deleteBudgetOption: {
        method: "DELETE",
        url: this.urlString + "configuration/budget-option",
      },
      saveEventCal: {
        method: "POST",
        url: this.urlString + "event/cal-event/recurrence/0",
      },
      getAllCalEvent: {
        method: "GET",
        url: this.urlString + "event/cal-event",
      },
      deleteEventCal: {
        method: "DELETE",
        url: this.urlString + "event/cal-event",
      },
      getWorkOrderByMediaId: {
        method: "POST",
        url: this.urlString + "booking/getWorkOrderByMediaId",
      },
      getTravelOrderPdf: {
        method: "GET",
        url: this.urlString + "project/pdf/travelorder/",
      },
      saveWallCategory: {
        method: "POST",
        url: this.urlString + "configuration/wall-category",
      },
      getAllWallCategory: {
        method: "GET",
        url: this.urlString + "configuration/wall-category",
      },
      deleteWallCategory: {
        method: "DELETE",
        url: this.urlString + "configuration/wall-category",
      },
      updateIsSelectedOnWallCategory: {
        method: "POST",
        url: this.urlString + "configuration/updateIsSelectedOnWallCategory",
      },
      getExtraTravelReport: {
        method: "GET",
        url: this.urlString + "project/exportExtraTravel",
      },
      getExtraTravelData: {
        method: "GET",
        url: this.urlString + "project/getExtraTravelDetails/",
      },
      saveOrUpdateProjectExtra: {
        method: "POST",
        url: this.urlString + "configuration/project-extra",
      },
      getAllProjectExtraList: {
        method: "GET",
        url: this.urlString + "configuration/project-extra",
      },
      getProjectExtraById: {
        method: "GET",
        url: this.urlString + "configuration/project-extra/",
      },
      deleteProjectExtraById: {
        method: "DELETE",
        url: this.urlString + "configuration/project-extra",
      },
      saveProjectWallCardsDetails: {
        method: "POST",
        url: this.urlString + "project/saveProjectWallCardsDetails",
      },
      getProjectWallCardsDetailsByProject: {
        method: "GET",
        url: this.urlString + "project/getProjectWallCardsDetailsByProject/",
      },
      deleteProjectWallCardsDetailsById: {
        method: "DELETE",
        url: this.urlString + "project/deleteProjectWallCardsDetailsById/",
      },
      getProjectBaiscForScheduler: {
        method: "GET",
        url: this.urlString + "project/scheduler/info",
      },
      revokeStaffAccess: {
        method: "POST",
        url: this.urlString + "staff/revoke-access/",
      },
      inviteUser: {
        method: "POST",
        url: this.urlString + "staff/assign-user-credential",
      },
      getDropDownProjectList: {
        method: "GET",
        url: this.urlString + "budget/getDropDownProjectList",
      },
      getAllFunctionQualifiedName: {
        method: "GET",
        url: this.urlString + "function/getAllFunctionQualifiedName",
      },
      getDropDownShowList: {
        method: "GET",
        url: this.urlString + "show/getDropDownShowList",
      },
      getDropDownClientist: {
        method: "GET",
        url: this.urlString + "client/getClientDropDownList",
      },
      createRosterRow: {
        method: "POST",
        url: this.urlString + "roster",
      },
      getRosterRows: {
        method: "GET",
        url: this.urlString + "roster",
      },
      deleteRosterRow: {
        method: "DELETE",
        url: this.urlString + "roster/",
      },
      deleteRosterEvent: {
        method: "DELETE",
        url: this.urlString + "roster/event/",
      },
      saveOrUpdateReport: {
        method: "POST",
        url: this.urlString + "report",
      },
      getReportById: {
        method: "GET",
        url: this.urlString + "report/getReportById/",
      },
      getAllReportName: {
        method: "GET",
        url: this.urlString + "report/getAllReportName",
      },
      getAllReports: {
        method: "GET",
        url: this.urlString + "report",
      },
      deleteReport: {
        method: "DELETE",
        url: this.urlString + "report/",
      },
      addChildRow: {
        method: "POST",
        url: this.urlString + "roster/add-child",
      },
      getReportDataByDate: {
        method: "GET",
        url: this.urlString + "report/getReportDataByDate",
      },
      saveRosterShiftEvent: {
        method: "POST",
        url: this.urlString + "roster/event",
      },
      saveRosterShiftEventList: {
        method: "POST",
        url: this.urlString + "roster/event/list",
      },
      copyRosterEvents: {
        method: "POST",
        url: this.urlString + "roster/event/copy",
      },
      getAllShiftEvents: {
        method: "GET",
        url: this.urlString + "roster/event",
      },
      exportReportDataByDate: {
        method: "POST",
        url: this.urlString + "report/exportReportDataByDate",
      },
      getAllRosterEventsByDate: {
        method: "GET",
        url: this.urlString + "roster/event",
      },
      getCompanyDropdown: {
        method: "GET",
        url: this.urlString + "company/dropdown",
      },
      getNetworks: {
        method: "GET",
        url: this.urlString + "configuration/network",
      },
      saveNetwork: {
        method: "POST",
        url: this.urlString + "configuration/network",
      },
      deleteNetwork: {
        method: "DELETE",
        url: this.urlString + "configuration/network",
      },
      deleteProjectTalent: {
        method: "DELETE",
        url: this.urlString + "budget/deleteProjectTalent",
      },
      deleteProjectNetworkById: {
        method: "DELETE",
        url: this.urlString + "budget/deleteProjectNetworkById",
      },
      shiftProject: {
        method: "POST",
        url: this.urlString + "project/shift/",
      },
      getAllBookingEventStatus: {
        method: "GET",
        url: this.urlString + "event/booking-event-status",
      },
      saveBookingStatus: {
        method: "POST",
        url: this.urlString + "event/booking-event-status",
      },
      deleteBookingStatus: {
        method: "DELETE",
        url: this.urlString + "event/booking-event-status",
      },
      getShowForProject: {
        method: "GET",
        url: this.urlString + "budget/non-archived/show/",
      },
      getCategoryForProject: {
        method: "GET",
        url: this.urlString + "budget/non-archived/category/",
      },
      getCompanyForProject: {
        method: "GET",
        url: this.urlString + "budget/non-archived/company/",
      },
      getAllSchedulerView: {
        method: "GET",
        url: this.urlString + "configuration/schedule-timeline-view",
      },
      saveOrUpdateRoutingOrder: {
        method: "POST",
        url: this.urlString + "configuration/schedule-timeline-view",
      },
      getAllProjectSchedulerView: {
        method: "GET",
        url: this.urlString + "configuration/project-schedule-timeline-view",
      },
      saveOrUpdateProjectRoutingOrder: {
        method: "POST",
        url: this.urlString + "configuration/project-schedule-timeline-view",
      },
      getAllMarketPlace1: {
        method: "GET",
        url: this.urlString + "configuration/marketPlace1",
      },
      saveOrUpdateMarketPlace1: {
        method: "POST",
        url: this.urlString + "configuration/marketPlace1",
      },
      deleteMarketPlace1ById: {
        method: "DELETE",
        url: this.urlString + "configuration/marketPlace1/",
      },
      getMarketPlace1ById: {
        method: "GET",
        url: this.urlString + "asset/marketPlace1/",
      },
      getAllMarketPlace1ForDropDown: {
        method: "GET",
        url: this.urlString + "configuration/getAllMarketPlace1ForDropDown",
      },
      updateEventStatus: {
        method: "POST",
        url: this.urlString + "booking/events/update-status",
      },
      notifyOder: {
        method: "POST",
        url: this.urlString + "notification/order-notification/",
      },
      eventStatusNotification: {
        method: "POST",
        url: this.urlString + "notification/event-status-notification/",
      },
      restoreLineFromBoking: {
        method: "DELETE",
        url: this.urlString + "project/line/restore/",
      },
      temporaryDeleteLineFromBoking: {
        method: "DELETE",
        url: this.urlString + "project/line/temporaryDelete/",
      },
      uploadInternationalizationSheet: {
        method: "POST",
        url: this.urlString + "configuration/upload/language/spreadsheet",
      },
      exportPersonnelList: {
        method: "GET",
        url: this.urlString + "staff/exportAllPersons",
      },
      exportFunctionList: {
        method: "GET",
        url: this.urlString + "function/exportAllFunctions",
      },
      exportClientList: {
        method: "GET",
        url: this.urlString + "client/exportAllClients",
      },
      exportEquipmentList: {
        method: "GET",
        url: this.urlString + "equipment/exportAllEquipments",
      },
      exportVendorList: {
        method: "GET",
        url: this.urlString + "vendor/exportAllVendors",
      },
      exportClientEmptyList: {
        method: "GET",
        url: this.urlString + "client/exportClientEmptyList",
      },
      exportEmptyPersonsFile: {
        method: "GET",
        url: this.urlString + "staff/exportEmptyPersonsFile",
      },
      exportEmptyFunctionFile: {
        method: "GET",
        url: this.urlString + "function/exportEmptyFunctionFile",
      },
      exportVendorEmptyList: {
        method: "GET",
        url: this.urlString + "vendor/exportVendorEmptyList",
      },
      exportEquipmentEmptyList: {
        method: "GET",
        url: this.urlString + "equipment/exportEquipmentEmptyList",
      },
      exportTemplateProject: {
        method: "GET",
        url: this.urlString + "budget/exportTemplateProject",
      },
      exportAllProject: {
        method: "GET",
        url: this.urlString + "budget/exportAllProject",
      },
      exportLanguageList: {
        method: "GET",
        url: this.urlString + "configuration/export/language/",
      },
      exportEmptyLanguageList: {
        method: "GET",
        url: this.urlString + "configuration/exportEmpty/language/",
      },
      deleteSubClient: {
        method: "DELETE",
        url: this.urlString + "client/sub-client/",
      },
      updateProxyPassword: {
        method: "POST",
        url: this.urlString + "proxy-account",
      },
      setSupportPassword: {
        method: "POST",
        url: this.urlString + "staff/support-password",
      },
      revokeSupportAccess: {
        method: "POST",
        url: this.urlString + "staff/revoke-support-access",
      },
      getSoftDeletedBookingByProjectId: {
        method: "GET",
        url: this.urlString + "project/getSoftDeletedBookingByProjectId/",
      },
      updateNotificationTemplateStatus: {
        method: "POST",
        url: this.urlString + "notification/template/",
      },
      getProjectsForCompare: {
        method: "POST",
        url: this.urlString + "budget/getProjectsForCompare",
      },
      getMeetingsByProject: {
        method: "GET",
        url: this.urlString + "project-note/getMeetingByProject/",
      },
      saveOrUpdateMeeting: {
        method: "POST",
        url: this.urlString + "project-note/meeting",
      },
      getMeetingLines: {
        method: "GET",
        url: this.urlString + "project-note/meetingLine/",
      },
      saveOrUpdateMeetingLine: {
        method: "POST",
        url: this.urlString + "project-note/meetingLine",
      },
      saveOrUpdateMeetingAttendees: {
        method: "POST",
        url: this.urlString + "project-note/meetingAttendees",
      },
      getMeetingAttendeesByMeetingId: {
        method: "GET",
        url: this.urlString + "project-note/getMeetingAttendeesByMeetingId/",
      },
      removeAttendees: {
        method: "DELETE",
        url: this.urlString + "project-note/meetingAttendees/",
      },
      removeMeeting: {
        method: "DELETE",
        url: this.urlString + "project-note/meeting/",
      },
      removeMeetingLine: {
        method: "DELETE",
        url: this.urlString + "project-note/meetingLine/",
      },
      getMeetingDetailsByUserId: {
        method: "GET",
        url: this.urlString + "project-note/getMeetingDetailsByUserId",
      },
      saveNoteNotificationToggle: {
        method: "POST",
        url: this.urlString + "project-note/saveNoteNotificationToggle",
      },
      downloadMeetingDetails: {
        method: "POST",
        url: this.urlString + "project-note/download-note-details",
      },
      getAllHolidays: {
        method: "GET",
        url: this.urlString + "configuration/holiday",
      },
      saveOrUpdateHoliday: {
        method: "POST",
        url: this.urlString + "configuration/holiday",
      },
      deleteHoliday: {
        method: "DELETE",
        url: this.urlString + "configuration/holiday/",
      },
      unAssignBudgetingLine: {
        method: "GET",
        url: this.urlString + "budget/unAssignBudgetingLine/",
      },
      unAssignBookingLine: {
        method: "GET",
        url: this.urlString + "booking/unAssignBookingLine/",
      },
      findAllInProgressProjects: {
        method: "GET",
        url: this.urlString + "project/project-board",
      },
      getAllPlanningEvent: {
        method: "GET",
        url: this.urlString + "event/planning-event",
      },
      savePlanningEvent: {
        method: "POST",
        url: this.urlString + "event/planning-event",
      },
      deletePlanningEvent: {
        method: "DELETE",
        url: this.urlString + "event/planning-event",
      },
      saveEventPlaceholder: {
        method: "POST",
        url: this.urlString + "event/event-placeholder",
      },
      deletePlaceholder: {
        method: "DELETE",
        url: this.urlString + "event/event-placeholder/",
      },
      deleteEvent: {
        method: "GET",
        url: this.urlString + "booking/unAssignBookingEvent/",
      },
      deleteEventPhaseOwner: {
        method: "GET",
        url: this.urlString + "booking/unAssignBookingLine/",
      },
      revokePhaseOwner: {
        method: "POST",
        url: this.urlString + "booking/revoke/phaseowner",
      },
      getPlanningEventsByProject: {
        method: "GET",
        url: this.urlString + "project-planning/getEventByProject/",
      },
      getEventByProjectForBooking: {
        method: "GET",
        url: this.urlString + "project-planning/booking/",
      },
      saveOrUpdatePlanningEvent: {
        method: "POST",
        url: this.urlString + "project-planning/event",
      }, saveOrUpdateBilling: {
        method: "POST",
        url: this.urlString + "project-planning/update-billable/",
      },
      saveOrUpdateEventManager: {
        method: "POST",
        url: this.urlString + "project-planning/update-phase-manager",
      },
      addLineForPhase: {
        method: "POST",
        url: this.urlString + "event/phase-manager",
      },
      addEventBumper: {
        method: "POST",
        url: this.urlString + "event/add-bumper",
      },
      deleteEventById: {
        method: "DELETE",
        url: this.urlString + "project-planning/event/",
      },
      getPlanningEventById: {
        method: "GET",
        url: this.urlString + "project-planning/getEventById/",
      },
      getCompanyTimeZonebyRecordId: {
        method: "GET",
        url: this.urlString + "budget/getCompanyTimeZonebyRecordId/",
      },
      getAllProjectPlanningEvent: {
        method: "GET",
        url: this.urlString + "project-planning/getAllProjectPlanningEvent",
      },
      checkedItsPlanningOrNotByRecordId: {
        method: "GET",
        url:
          this.urlString +
          "project-planning/checkedItsPlanningOrNotByRecordId/",
      },
      getProjectPlanningDetails: {
        method: "GET",
        url: this.urlString + "project-planning/getProjectPlanningDetails",
      },
      getLinesInParts: {
        method: "GET",
        url: this.urlString + "project/booking/",
      },
      getBudgetLinesInParts: {
        method: "GET",
        url: this.urlString + "budget/booking/",
      },
      getshowRequestLinesInParts: {
        method: "GET",
        url: this.urlString + "show/request/",
      },
      getshowBookingLinesInParts: {
        method: "GET",
        url: this.urlString + "show/booking/",
      },
      getPlanningBookings: {
        method: "GET",
        url: this.urlString + "project/booking/planning/",
      },
      updateLine: {
        method: "POST",
        url: this.urlString + "budget/booking/",
      },
      customizedRequest: {
        method: "POST",
        url: this.urlString + "project/customizedbooking/",
      },
      customizedbooking: {
        method: "POST",
        url: this.urlString + "budget/customizedbooking/",
      },
      bookingLineService: {
        method: "GET",
        url: this.urlString + "function/services",
      },
      updateBudgetLine: {
        method: "POST",
        url: this.urlString + "project/booking/",
      },
      getAllPlanningProject: {
        method: "GET",
        url: this.urlString + "project-planning/getAllPlanningProject",
      },
      exportMeetingNoteReport: {
        method: "GET",
        url: this.urlString + "project-note/download/report/",
      },
      getGroupListForDropDown: {
        method: "GET",
        url: this.urlString + "configuration/getGroupListForDropDown",
      },
      exportProjectUpdateGroup: {
        method: "GET",
        url: this.urlString + "report/exportProjectUpdateGroup/",
      },
      getBookingTotal: {
        method: "GET",
        url: this.urlString + "project/booking/total/",
      },
      getShowTotal: {
        method: "GET",
        url: this.urlString + "show/total/",
      },
      restoreBooking: {
        method: "POST",
        url: this.urlString + "booking/restore/",
      },
      exportWeekTimeGroupReport: {
        method: "GET",
        url: this.urlString + "report/exportWeekTimeGroupReport",
      },
      exportAnnualTimeGroupReport: {
        method: "GET",
        url: this.urlString + "report/exportAnnualTimeGroupReport",
      },
      exportBookingHistoryGroupReport: {
        method: "GET",
        url: this.urlString + "project/exportGroupHistoryReport/export",
      },
      saveOrUpdateDarkMode: {
        method: "POST",
        url: this.urlString + "staff/saveOrUpdateDarkMode",
      },
      saveBookingTags: {
        method: "POST",
        url: this.urlString + "project/booking-exception",
      },
      removeBookingTag: {
        method: "DELETE",
        url: this.urlString + "project/booking-exception/",
      },
      getAllBookingTags: {
        method: "GET",
        url: this.urlString + "project/booking-exception/",
      },
      saveProjectColumnDetails: {
        method: "POST",
        url: this.urlString + "project/column-details",
      },
      saveMediaColumnDetails: {
        method: "POST",
        url: this.urlString + "project/media/column-details",
      },
      getBookingColumn: {
        method: "GET",
        url: this.urlString + "project/column-details",
      },
      requestColumnDetails: {
        method: "POST",
        url: this.urlString + "project/request-column-details",
      },
      getRequestColumn: {
        method: "GET",
        url: this.urlString + "project/request-column-details",
      },
      uploadProjectSheet: {
        method: "POST",
        url: this.urlString + "report/upload/projectSpreadsheet",
      },
      getThemeForScheduling: {
        method: "GET",
        url: this.urlString + "budget/getThemeForScheduling/",
      },
      toggle2FA: {
        method: "GET",
        url: this.urlString + "configuration/toggle_2fa",
      },
      verify_2fa_new: {
        method: "POST",
        url: this.urlString + "configuration/verify_2fa_new/",
      },
      verify_2fa: {
        method: "POST",
        url: this.urlString + "configuration/verify_2fa/",
      },
      "2fa-status": {
        method: "GET",
        url: this.urlString + "user/2fa-status/",
      },
      saveResourceUnavalibility: {
        method: "POST",
        url: this.urlString + "resource/unavailbility",
      },
      deleteResourceUnavalibility: {
        method: "DELETE",
        url: this.urlString + "resource/unavailbility/",
      },
      saveActualPriceCost: {
        method: "POST",
        url: this.urlString + "project/line/save-actual-price-cost",
      },
      saveKitViewConfiguration: {
        method: "POST",
        url: this.urlString + "configuration/kit-view-configuration",
      },
      getKitViewConfiguration: {
        method: "GET",
        url: this.urlString + "configuration/kit-view-configuration",
      },
      addPlanning: {
        method: "POST",
        url: this.urlString + "project/booking/planning/",
      },
      getSavedFilters: {
        method: "GET",
        url: this.urlString + "filter",
      },
      saveFilter: {
        method: "POST",
        url: this.urlString + "filter",
      },
      removeFilter: {
        method: "DELETE",
        url: this.urlString + "filter/",
      },
      saveAlphaBudget: {
        method: "POST",
        url: this.urlString + "budget/save-alhpa-budget",
      },
      saveAlphaBooking: {
        method: "POST",
        url: this.urlString + "project/save-alhpa-booking",
      },
      saveRecordNetwork: {
        method: "POST",
        url: this.urlString + "project/save-record-network",
      },
      getRecordNetwork: {
        method: "GET",
        url: this.urlString + "project/get-record-neworks/",
      },
      removeRecordNetwork: {
        method: "DELETE",
        url: this.urlString + "project/record-network/",
      },
      updateRecordTheme: {
        method: "POST",
        url: this.urlString + "project/update-project-theme",
      },
      updateRecordThemeShow: {
        method: "POST",
        url: this.urlString + "show/update-project-theme",
      },
      updateEquipmentTheme: {
        method: "POST",
        url: this.urlString + "equipment/update-theme",
      },
      updatePersonalTheme: {
        method: "POST",
        url: this.urlString + "staff/user-theme/",
      },
      saveRecordFromat: {
        method: "POST",
        url: this.urlString + "project/deliverable",
      },
      removeRecordFormat: {
        method: "DELETE",
        url: this.urlString + "project/deliverable/",
      },
      getRecordFromat: {
        method: "GET",
        url: this.urlString + "project/deliverable/",
      },
      updateRecordStatus: {
        method: "POST",
        url: this.urlString + "project/update-project-status",
      },
      updateRecordSubStatus: {
        method: "POST",
        url: this.urlString + "project/update-project-sub-status",
      },
      updateLineIndex: {
        method: "POST",
        url: this.urlString + "booking/update-line-index",
      },
      changeWorkOrderCompletion: {
        method: "POST",
        url: this.urlString + "booking/change-work-order-completion/",
      },
      getRequestStatus: {
        method: "GET",
        url: this.urlString + "configuration/request-status/",
      },
      saveRequestStatus: {
        method: "POST",
        url: this.urlString + "configuration/request-status/",
      },
      convertRequestToProjectInParts: {
        method: "POST",
        url: this.urlString + "project/planning/booking-request",
      },
      convertRequestToProject: {
        method: "POST",
        url: this.urlString + "project/booking-request",
      },
      updateBookingException: {
        method: "POST",
        url: this.urlString + "project/update-booking-exception",
      },
      convertRequestToProjectInPartsByFunction: {
        method: "POST",
        url: this.urlString + "project/function/booking-request",
      },
      setRecordContact: {
        method: "POST",
        url: this.urlString + "project/set-record-contacts",
      },
      getProjectBookingStatus: {
        method: "GET",
        url: this.urlString + "budget/booking-status/",
      },
      staffListByCompanyId: {
        method: "GET",
        url: this.urlString + "staff/company/staff-list/",
      },
      saveOrUpdateMileStone: {
        method: "POST",
        url: this.urlString + "budget/milestone",
      },
      getAllMilestoneByRecord: {
        method: "GET",
        url: this.urlString + "budget/milestone/",
      },
      removeMilestoneById: {
        method: "DELETE",
        url: this.urlString + "budget/milestone/",
      },
      saveProjectInvoiceType: {
        method: "POST",
        url: this.urlString + "invoice/invoice-type",
      },
      getProjectInvoiceType: {
        method: "GET",
        url: this.urlString + "invoice/invoice-type/",
      },
      getBasisAndFunction: {
        method: "GET",
        url: this.urlString + "budget/dropdown/function-basis",
      },
      savePack: {
        method: "POST",
        url: this.urlString + "configuration/pack",
      },
      getAllPack: {
        method: "GET",
        url: this.urlString + "configuration/pack",
      },
      removePack: {
        method: "DELETE",
        url: this.urlString + "configuration/pack/",
      },
      removePackItem: {
        method: "DELETE",
        url: this.urlString + "configuration/pack-info/",
      },
      getAllPackByCompany: {
        method: "GET",
        url: this.urlString + "configuration/pack/",
      },
      addPack: {
        method: "POST",
        url: this.urlString + "budget/add-pack/",
      },
      addMultiService: {
        method: "POST",
        url: this.urlString + "budget/add-services/",
      },
      addPackRequest: {
        method: "POST",
        url: this.urlString + "project/add-pack/",
      },
      getAllLayoutTemplate: {
        method: "GET",
        url: this.urlString + "layout-template",
      },
      applyLayout: {
        method: "POST",
        url: this.urlString + "layout-template/apply/",
      },
      addNewLayout: {
        method: "POST",
        url: this.urlString + "layout-template/",
      },
      saveOrUpdateRecordTask: {
        method: "POST",
        url: this.urlString + "record-task/",
      },
      getAllRecordTask: {
        method: "GET",
        url: this.urlString + "record-task/",
      },
      addTaskBooking: {
        method: "POST",
        url: this.urlString + "project/booking-task/",
      },
      getTaskLines: {
        method: "GET",
        url: this.urlString + "project/booking-task/",
      },
      addResourceToTask: {
        method: "POST",
        url: this.urlString + "project/booking-task/apply/",
      },
      setStatusToTask: {
        method: "POST",
        url: this.urlString + "project/booking-task/status/",
      },
      getAllBookingTask: {
        method: "GET",
        url: this.urlString + "dashboard/process-dashboard-task",
      },
      getAllBacklogTask: {
        method: "GET",
        url: this.urlString + "dashboard/backlog/process-dashboard-task",
      },
      getProjectPlanningScheduler: {
        method: "GET",
        url: this.urlString + "project-planning/scheduler/planning-events/",
      },
      getAllRosterDuties: {
        method: "GET",
        url: this.urlString + "configuration/roster-duty",
      },
      saveRosterDuty: {
        method: "POST",
        url: this.urlString + "configuration/roster-duty",
      },
      deleteRosterDuty: {
        method: "DELETE",
        url: this.urlString + "configuration/roster-duty/",
      },
      createRosterTimelineEvent: {
        method: "POST",
        url: this.urlString + "roster/timeline-event",
      },
      getAllRosterTimelineByGroup: {
        method: "GET",
        url: this.urlString + "roster/timeline-event/",
      },
      getResurceDuty: {
        method: "GET",
        url: this.urlString + "roster/timeline-event/resource/",
      },
      getAllLeavesByGroup: {
        method: "GET",
        url: this.urlString + "user-leave-record/by-group/",
      },
      deleteTimelineEvent: {
        method: "DELETE",
        url: this.urlString + "roster/timeline-event/",
      },
      getAllBookedProjects: {
        method: "GET",
        url: this.urlString + "budget/task/booked-projects",
      },
      getAllRecordTaskDropodown: {
        method: "GET",
        url: this.urlString + "record-task/dropdown/",
      },
      addOrRemoveFuncion: {
        method: "POST",
        url: this.urlString + "staff/staff-function/",
      },
      addOrRemoveFuncionEquip: {
        method: "POST",
        url: this.urlString + "equipment/equipment-function/",
      },
      getNewYearDateConfig: {
        method: "GET",
        url: this.urlString + "configuration/new-year-date",
      },
      setNewYearDateConfig: {
        method: "POST",
        url: this.urlString + "configuration/new-year-date",
      },
      updateProjectTitle: {
        method: "POST",
        url: this.urlString + "project/update-project-title",
      },
      updateProjectClient: {
        method: "POST",
        url: this.urlString + "project/update-project-client",
      },
      updateUserRoles: {
        method: "POST",
        url: this.urlString + "staff/user-role",
      },
      getAllClientsBasic: {
        method: "GET",
        url: this.urlString + "client/dropdown/basic",
      },
      getUserPreffredClientAndCompany: {
        method: "GET",
        url: this.urlString + "staff/logged-in-user-preff",
      },
      getRequestTemplate: {
        method: "GET",
        url: this.urlString + "budget/template-list",
      },
      getAllRosterTimeLineEventsByDate: {
        method: "POST",
        url: this.urlString + "roster/timeline-event/by-range",
      },
      resetTimeOfAllRosterEvents: {
        method: "POST",
        url: this.urlString + "roster/timeline-event/update-time",
      },
      getPersonnelLastBooking: {
        method: "GET",
        url: this.urlString + "staff/",
      },
      deleteUserKit: {
        method: "DELETE",
        url: this.urlString + "configuration/kit/user/",
      },
      getAllRosterTimeLineEventsByResources: {
        method: "POST",
        url: this.urlString + "roster/timeline-event/by-resources",
      },
      getResourceModel: {
        method: "GET",
        url: this.urlString + "equipment/resource-model",
      },
      setResourceModel: {
        method: "POST",
        url: this.urlString + "equipment/resource-model",
      },
      saveAlternatives: {
        method: "POST",
        url: this.urlString + "equipment-alternative",
      },
      getAllEquipmentAlternative: {
        method: "GET",
        url: this.urlString + "equipment-alternative/",
      },
      deleteAlternatives: {
        method: "DELETE",
        url: this.urlString + "equipment-alternative/",
      },
      placeBidding: {
        method: "POST",
        url: this.urlString + "order/vendrors-bidding",
      },
      checkVendor: {
        method: "GET",
        url: this.urlString + "user/check-vendor",
      },
      getLoggedInVendorData: {
        method: "GET",
        url: this.urlString + "vendor/vendors-booking",
      },
      getBookingsByTable: {
        method: "GET",
        url: this.urlString + "project/project-checkout/",
      },
      getAllTaxes: {
        method: "GET",
        url: this.urlString + "tax",
      },
      saveOrUpdateTax: {
        method: "POST",
        url: this.urlString + "tax",
      },
      deleteTaxById: {
        method: "DELETE",
        url: this.urlString + "tax/",
      },
      setBookingRatting: {
        method: "POST",
        url: this.urlString + "booking/set-booking-rating",
      },
      getRecentBookingByResource: {
        method: "GET",
        url: this.urlString + "staff/",
      },
      getEquipmentRecentBooking: {
        method: "GET",
        url: this.urlString + "equipment/recent-booking/",
      },
      updateProjectClientContactAndSubClient: {
        method: "POST",
        url: this.urlString + "project/sub-client-and-contact-update",
      },
      deleteFunctionDescription: {
        method: "DELETE",
        url: this.urlString + "function/function-description/",
      },
      getDashboardPreff: {
        method: "GET",
        url: this.urlString + "dashboard/work-order-dashboard-preff",
      },
      addOrRemoveStatus: {
        method: "POST",
        url: this.urlString + "dashboard/work-order-dashboard-preff",
      },
      updateShowFinancialStatus: {
        method: "POST",
        url: this.urlString + "show/update-financial-status",
      },
      printPackingList: {
        method: "GET",
        url: this.urlString + "budget/pdf/packing-list/",
      },
      printPackageList: {
        method: "GET",
        url: this.urlString + "budget/pdf/package-list/",
      },
      billOfOrder: {
        method: "GET",
        url: this.urlString + "budget/pdf/packing-list/qty/",
      },
    };
  }
}
