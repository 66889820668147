import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import moment from 'moment-timezone';
import { CommonUtilService } from '../common-util-service';
import { UserPreferencesService } from '../user-preferences.service';
import { HttpService } from '../http.service';
import { ToastrTranslateService } from '../toastr-translate.service';
import * as Msal from "msal";
import { debounceTime, distinctUntilChanged, shareReplay, throttleTime } from 'rxjs/operators';
import { Router,NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DayPilot } from "daypilot-pro-angular";



@Injectable({
  providedIn: 'root'
})
export class SharedService {
  showBasicDetails: any = undefined;
  CurrentUserFcmToken:any = undefined;
  colorData: any = undefined;
  projectStatusData: any = undefined;
  bolbData: any = undefined
  userRole: any = undefined
  public calenderItems:any =[]
  public calenderData:any =[]
  public trackerPackgeList:any=[]

  public selectedFilterArr:any=[]
  public loggedInUserData: any;
  allTimeZone:any;
  allClient:any;
  CompanyDropdownForRole:any;
  kanbanOffSet: any = undefined;
  kanbanToDoOffSet: any = undefined;
  kanbanDoneOffSet: any = undefined;
  projectDesk:any =false
  filterSelectedObj:any={}


   CLIENT_ID:any = null;
   API_KEY:any = null;

   googleData:any ={
    "googleClientkey": null,
    "googleAPIkey": null,
    "clientSecretKey":null,
    "isGoogleKeyActive": false,
    "isGoogleMeetEnabled": false,
    "isGoogleSheetEnabled": false,
    "isAirTableEnabled": false,
    "isAsanaEnabled": false,
    "isMDocEnabled": false,
    "isSpreadSheetEnabled": false,
   };




  kanbanData: any = undefined;
  kanbanToDoData:any =undefined
  kanbanDoneData:any=undefined
  reportDatesData: any = undefined
  genericFunctionCatalog: Boolean = undefined;
  public googleSheetFlag: boolean = false;
  public projectGoogleSheetObj:any=undefined;
  public clientGoogleSheetObj:any=undefined;
  public resourceGoogleSheetObj:any=undefined;
  public personalGoogleSheetObj:any=undefined; 
  public userFilters:any=undefined; 




  genericNotificationFlag:Boolean = undefined;
  genericLeaveApplied:Boolean = undefined;
  fromHeader:boolean=false;

  genericProjectDetailOpen: Boolean = undefined;

  genericTableCopy: Boolean = false;
  genericChatBox: Boolean = undefined;
  leaveEvents: any = [];
  public checkInRedirect: Boolean = false;
  myMsal:any = undefined
   accessToken:any = undefined
  msalCalendar:any=[]
  oulookLoggedIn:any=false;

  theme = new BehaviorSubject("light-mode");

  private cancelPendingRequests$ = new Subject<void>()

  public colorDataObs = new Subject<any>();
  colorgroupListDataObservable$ = this.colorDataObs.asObservable();

  public projectStatusDataObs = new Subject<any>();
  projectStatusDataObservable$ = this.projectStatusDataObs.asObservable();

  public functionCatalog = new Subject<any>();
  functionCatalog$ = this.functionCatalog.asObservable();

  
  public notificationFlag = new Subject<any>();
  notificationFlag$ = this.notificationFlag.asObservable();

  public LeaveApplied = new Subject<any>();
  LeaveApplied$ = this.LeaveApplied.asObservable();

  public ProjectDetailOpen = new Subject<any>();
  ProjectDetailOpen$ = this.ProjectDetailOpen.asObservable();

  public Tablecopy = new Subject<any>();
  Tablecopy$ = this.Tablecopy.asObservable();

  public chatBox = new Subject<any>();
  chatBox$ = this.chatBox.asObservable();

  public notificationRecieved: Subject<any> = new Subject<any>();
  notificationRecieved$ =  this.notificationRecieved.asObservable();

  private projectSubstatus = new BehaviorSubject([]);
  projectSubstatus$ = this.projectSubstatus.asObservable();

  private globalClients = new BehaviorSubject([]);
  globalClients$ = this.globalClients.asObservable();

  private globalClientsDropdown = new BehaviorSubject({});
  globalClientsDropDown$ = this.globalClientsDropdown.asObservable();

  private globalVendor = new BehaviorSubject([]);
  globalVendor$ = this.globalVendor.asObservable();

  private globalVendorDropdown = new BehaviorSubject([]);
  globalVendorDropDown$ = this.globalVendorDropdown.asObservable();

  private globalRateCard = new BehaviorSubject([]);
  globalRateCard$ = this.globalRateCard.asObservable();

  private globalRateCardDropdown = new BehaviorSubject([]);
  globalRateCardDropDown$ = this.globalRateCardDropdown.asObservable();

  private globalPersonel = new BehaviorSubject([]);
  globalPersonel$ = this.globalPersonel.asObservable();

  private globalFunction = new BehaviorSubject([]);
  globalFunction$ = this.globalFunction.asObservable();

  private globalKeywords = new BehaviorSubject([]);
  globalKeywords$ = this.globalKeywords.asObservable();

  private globalPersonelDropdown = new BehaviorSubject([]);
  globalPersonelDropDown$ = this.globalPersonelDropdown.asObservable();

  private globalConfigGroup = new BehaviorSubject([]);
  globalConfigGroup$ = this.globalConfigGroup.asObservable();

  private allMarketPlace = new BehaviorSubject([]);
  allMarketPlace$ = this.allMarketPlace.asObservable();

  private getActiveRole = new BehaviorSubject([]);
  getActiveRole$ = this.getActiveRole.asObservable();

  private globalEquipment = new BehaviorSubject([]);
  globalEquipment$ = this.globalEquipment.asObservable();

  private globalEquipmentDropdown = new BehaviorSubject([]);
  globalEquipmentDropDown$ = this.globalEquipmentDropdown.asObservable();

  private ProjectsForUserDefined = new BehaviorSubject([]);
  ProjectsForUserDefined$ = this.ProjectsForUserDefined.asObservable();

  private AllAvailableFunction = new BehaviorSubject([]);
  AllAvailableFunction$ = this.AllAvailableFunction.asObservable();

  private ProjectInfo = new BehaviorSubject([]);
  ProjectInfo$ = this.ProjectInfo.asObservable();

  private AllLinkEventID = new BehaviorSubject([]);
  AllLinkEventID$ = this.AllLinkEventID.asObservable();

  private AllKitListByLoggedInUser = new BehaviorSubject([]);
  AllKitListByLoggedInUser$ = this.AllKitListByLoggedInUser.asObservable();

  private AllGlobalKitView = new BehaviorSubject([]);
  AllGlobalKitView$ = this.AllGlobalKitView.asObservable();

  private AllBookingEventStatus = new BehaviorSubject([]);
  AllBookingEventStatus$ = this.AllBookingEventStatus.asObservable();

  private KitViewConfiguration = new BehaviorSubject([]);
  KitViewConfiguration$ = this.KitViewConfiguration.asObservable();

  private BudgetEvent = new BehaviorSubject([]);
  BudgetEvent$ = this.BudgetEvent.asObservable();

  private BudgetDropDown = new BehaviorSubject([]);
  BudgetDropDown$ = this.BudgetDropDown.asObservable();

  private ShowListForScheduler = new BehaviorSubject([]);
  ShowListForScheduler$ = this.ShowListForScheduler.asObservable();

  private ProjectBasicDetails = new BehaviorSubject([]);
  ProjectBasicDetails$ = this.ProjectBasicDetails.asObservable();

  private ProjectResources = new BehaviorSubject([]);
  ProjectResources$ = this.ProjectResources.asObservable();

  private ProjectsEvent = new BehaviorSubject([]);
  ProjectsEvent$ = this.ProjectsEvent.asObservable();

  private MilestonesByProjectId = new BehaviorSubject([]);
  MilestonesByProjectId$ = this.MilestonesByProjectId.asObservable();

  private AllPhaseBookings = new BehaviorSubject([]);
  AllPhaseBookings$ = this.AllPhaseBookings.asObservable();

  private AllProjectSchedulerView = new BehaviorSubject([]);
  AllProjectSchedulerView$ = this.AllProjectSchedulerView.asObservable();

  private PlanningEventStatus = new BehaviorSubject([]);
  PlanningEventStatus$ = this.PlanningEventStatus.asObservable();

  private getAllProject = new BehaviorSubject([]);
  getAllProject$ = this.getAllProject.asObservable();

  private AllNetwork = new BehaviorSubject([]);
  AllNetwork$ = this.AllNetwork.asObservable();

  private AllService = new BehaviorSubject([]);
  AllService$ = this.AllService.asObservable();

  private packList = new BehaviorSubject([]);
  packList$ = this.packList.asObservable();

  clientChanged: Subject<any> = new Subject<any>();
  equipmentChanged: Subject<any> = new Subject<any>();
  personalChanged: Subject<any> = new Subject<any>();
  functionChanged: Subject<any> = new Subject<any>();
  vendorChanged: Subject<any> = new Subject<any>();
  





  constructor(private commonUtilService: CommonUtilService, private userPrefService: UserPreferencesService,private router:Router ,private httpService: HttpService, private toastr: ToastrTranslateService) {
    this.clientChanged.pipe(throttleTime(1000)).subscribe((value) => {
      this.setglobalClients(value);
    });
    this.equipmentChanged.pipe(throttleTime(1000)).subscribe((value) => {
      this.setglobalEquipment(value);
    });
    this.personalChanged.pipe(throttleTime(1000)).subscribe((value) => {
      this.setglobalPersonel(value);
    });
    this.functionChanged.pipe(throttleTime(1000)).subscribe((value) => {
      this.setglobalFuncions(value);
    });
    this.vendorChanged.pipe(throttleTime(1000)).subscribe((value) => {
      this.setglobalVendors(value);
    });

    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)).subscribe(event => {
        this.filterSelectedObj = {};
      
    })
  }

  private headerValueSubject = new BehaviorSubject<string>('');

  headerValue$ = this.headerValueSubject.asObservable();

  private taskValueSubject = new BehaviorSubject<string>('');

  taskValue$ = this.taskValueSubject.asObservable();

  setHeaderValue(value: string) {
    this.headerValueSubject.next(value);
  }

  settaskValue(value: string) {
    this.taskValueSubject.next(value);
  }

  getfilterArr(copName){
    if(copName == 'board'){
      return ['Date Range','Business Unit','Project','Client','Project Owner','Phase Owner','Account Manager','Country','Region','Category']
    } else if(copName == 'schedule'){
      return ['Business Unit','Project','Client','Project Owner','Phase Owner','Account Manager','Country','Region','Category','Actualized']
    } else if(copName == 'equipment'){
      return ['Business Unit','Function','Model','Location','Type','Active']
    } else if(copName == 'personal'){
      return ['Business Unit','Function','Skill','Personal Type','Location','Active','Group','UserType']
    } else if (copName == 'client'){
      return ['Business Unit','Category','City','Country']
    } else if (copName == 'reports'){
      return ['Business Unit','Project','Client','Personal Type','Function','Export','Push To Financial','Phase Owner','Account Manager','Pool','Time Off']
      // 'Time Off'
    } else if (copName == 'reportResource'){
      return ['Business Unit','Project','Client','Phase Owner','Pool','Time Off']
      // 'Time Off'
    } else if (copName == 'userAccount'){
      return ['Business Unit','UserType']
      // 'Time Off'
    }
  }

  setDyanmicFilter(filter){
   let index =  this.selectedFilterArr.findIndex(el => el == filter)
    if(index != -1){
      this.selectedFilterArr.splice(index,1)
    } else {
      this.selectedFilterArr.push(filter)
    }
    this.setGloabalFilters();
  }

  checkFilterPresent(filter){
    let index =  this.selectedFilterArr.findIndex(el => el == filter)
    if(index != -1){
      return true
    } else {
      return false
    }
  }

  async setGloabalFilters() {
    try {
      let userid = sessionStorage.getItem("userId");
      let filterData =  this.getuserFilters();
      if (filterData?.staffMemberId == null) {
        filterData.staffMemberId = userid;
      }
     

      filterData.values["GLOBALFILTER"] = [
        {
          "mapId": "GLOBALFILTER",
          "recordKey": "LISTFILTERARR",
          "recordValue": this.selectedFilterArr.toString()
        }
        
      ]

      const res: any = await this.commonUtilService.setfilters(filterData);
      if (
        res.staffMemberId != undefined &&
        res.staffMemberId != null
      ) {
        if (res.values != null) {
          for (const property in res.values) {
            console.log(res.values[property]);
            for (let i = 0; i < res.values[property].length; i++) {
              if (res.values[property][i].filterId != undefined) {
                delete res.values[property][i].filterId;
              }

              if (res.values[property][i].id != undefined) {
                delete res.values[property][i].id;
              }
            }
          }
        }
      }
      this.setuserFilters(res);
    } catch (error) {
      console.log(error);
    }
  }

  public cancelPendingRequests() {
    this.cancelPendingRequests$.next()
  }

  public onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable()
  }

  hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    // return {r, g, b} // return an object
    return [r, g, b]
  }
  setContrast(hexCode) {
    if (hexCode == 'black') {
      return '#ffffff'
    }
    if (hexCode == undefined || hexCode == null  || hexCode == '' || !hexCode.toString().includes("#")) {
      return '#000000'
    }
    let rgb: any
    if (hexCode.includes('#')) {
      rgb = this.hex2rgb(hexCode)
    } else {
      return '#000000'
    }
    const brightness = Math.round(((parseInt(rgb[0]) * 299) +
      (parseInt(rgb[1]) * 587) +
      (parseInt(rgb[2]) * 114)) / 1000);
    const textColour = (brightness > 125) ?  '#000000' : '#ffffff';
    return textColour
  }

  getcoloOfTheme(color:string){
    if(color == '' || color ==  null ){
      color = '#007EBE'
    }
    return this.lightenColor(color,.8)
  }
 
   lightenColor(color: string, percent: number): string {
    let num: number;
    if(color ==  undefined || color == '' || color ==  null || color == '#ffffff' ){
      color = '#007EBE'
    }
    if (color.startsWith("#")) {
        num = parseInt(color.slice(1), 16);
    } else if (color.startsWith("rgb")) {
        const rgbValues = color.match(/\d+/g);
        num = (parseInt(rgbValues[0]) << 16) + (parseInt(rgbValues[1]) << 8) + parseInt(rgbValues[2]);
    } else {
      color =  '#007EBE'
      num = parseInt(color.slice(1), 16);
        // throw new Error("Invalid color format. Please use hexadecimal or RGB format.");
    }

    const amt = Math.round(2.55 * percent);
    const R = (num >> 16);
    const G = ((num >> 8) & 0x00ff);
    const B = (num & 0x0000ff);
    return `rgb(${R}, ${G}, ${B},${percent})`;
}

getFormattedText(text): string {
  return text.replace(/\n/g, '<br>');
}

isRangeLoaded(from: any, to: any,lastLoadedRange): boolean {
  if (!lastLoadedRange) {
    return false;
  }

  const isStartInRange = from >= lastLoadedRange.from && from <= lastLoadedRange.to;
  const isEndInRange = to >= lastLoadedRange.from && to <= lastLoadedRange.to;

  if (!isStartInRange || !isEndInRange ) {
    return   false
  } else {
    return   true
}
}

// splitAndAddEvent(event: any, mainArray: any[],timezone):any[] {
//   const start = moment(event.startTime).subtract(event.startBumper ? event.startBumper : 0 ,"hours").tz(timezone).format("YYYY-MM-DD HH:mm:ss"); // Use moment to parse the start date
//   const end =  moment(event.endTime).add(event.endBumper ? event.endBumper : 0,"hours").tz(timezone).format("YYYY-MM-DD HH:mm:ss"); // Use moment to parse the end date

//   let current = moment(start); // Initialize the current day to the start date
//   let currentEnd = moment(end); // Initialize the current day to the start date

//   const isDifferentDate = !current.isSame(currentEnd, "day");

//   const startHour = current.hour()
//   const startMin = current.minute()
//   const startSecond = current.second()

//   const endHour = currentEnd.hour()
//   const endMin = currentEnd.minute()
//   const endSecond = currentEnd.second()

//   while (current.isBefore(moment(end))) {
//     const dayStart = current.clone().set({
//       hour: startHour,
//       minute: startMin,
//       second: startSecond,
//     }).format("YYYY-MM-DD HH:mm:ss");

//     const dayEnd = current.clone().set({
//       hour: endHour,
//       minute: endMin,
//       second: endSecond,
//     }).format("YYYY-MM-DD HH:mm:ss");

    
//     // let dayStart = current.clone().set({
//     //   hour: moment(event.startTime).hour(),
//     //   minute: moment(event.startTime).minute(),
//     //   second: moment(event.startTime).second(),
//     // });

//     // let dayEnd = current.clone().set({
//     //   hour: moment(event.endTime).hour(),
//     //   minute: moment(event.endTime).minute(),
//     //   second: moment(event.endTime).second(),
//     // });

  
//     // Determine the actual start and end of this segment
//     const segmentStart =  dayStart;
//     const segmentEnd = dayEnd;
    
    
//     let newEvent = {
//       ...event, // Shallow copy of the event object
//       eventId: event.id, // Keep original ID
//       id: `${event.id}-${current.format('YYYY-MM-DD')}`, // Unique ID for the segment
//       start: new DayPilot.Date(dayStart),
//       end: new DayPilot.Date(dayEnd),
//       tempStart: moment(dayStart),
//       tempEnd: moment(dayEnd),
//       phases: this.returnPhases(event, timezone),
//       startTime: moment(event.startTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
//       endTime: moment(event.endTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
//       moveVDisabled: false,
//       moveHDisabled: false,
//       continue: isDifferentDate,
//     };

//     // Check if this segment is already in the main array
//     const isDuplicate = mainArray.some(
//       (existingEvent) =>
//         existingEvent.id === newEvent.id &&
//         moment(existingEvent.start).isSame(event.tempStart) &&
//         moment(existingEvent.end).isSame(event.tempEnd)
//     );

//     // Add to the main array only if it's not a duplicate
//     if (!isDuplicate) {
//       mainArray.push(newEvent);
//     }

//     // Move to the next day
//     current.add(1, 'day').set({
//       hour: startHour,
//       minute: startMin,
//       second: startSecond,
//     }); // Increment current by one day

//   }


//   return mainArray;
// }

splitAndAddEvent(event: any, mainArray: any[], timezone: string): any[] {
  const start = moment(event.startTime).tz(timezone);
  const end = moment(event.endTime).tz(timezone);

  if(!event.continuousEvent){
    let newEvent = {
      ...event,
      eventId: event.id,
      id: `${event.id}-${start.format("YYYY-MM-DD")}`,
      start: new DayPilot.Date(start.format("YYYY-MM-DD HH:mm:ss")),
      end: new DayPilot.Date(end.format("YYYY-MM-DD HH:mm:ss")),
      tempStart: start.tz(timezone).clone(),
      tempEnd: end.tz(timezone).clone(),
      phases: this.returnPhases(event,start.tz(timezone).clone(),end.tz(timezone).clone(), timezone),
      startTime: moment(event.startTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(event.endTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
      moveVDisabled: false,
      moveHDisabled: false,
    };

    const isDuplicate = mainArray.some(
      (existingEvent) =>
        existingEvent.id === newEvent.id &&
        moment(existingEvent.start).isSame(newEvent.tempStart) &&
        moment(existingEvent.end).isSame(newEvent.tempEnd)
    );

    if (!isDuplicate) {
      mainArray.push(newEvent);
    }
  } else {
      // Determine booking type
  const isNightShift = start.hour() > end.hour();
  const totalDays = end.diff(start, "days") + (isNightShift ? 1 : 0);

  for (let i = 0; i <= totalDays; i++) {
    const currentStart = start.clone().add(i, "days");
    const currentEnd = currentStart.clone();

    // Set start and end times
    currentStart.set({
      hour: start.hour(),
      minute: start.minute(),
      second: start.second(),
    });

    // Handle night shifts
    if (isNightShift) {
      currentEnd.add(1, "days"); // Increment end date for night shifts
    }

    currentEnd.set({
      hour: end.hour(),
      minute: end.minute(),
      second: end.second(),
    });

    // Ensure we don't create segments beyond the final end time
    if (currentStart.isAfter(end)) break;

    // Fix issue with same-day bookings
    if (totalDays === 0) {
      currentEnd.set({
        hour: end.hour(),
        minute: end.minute(),
        second: end.second(),
      });
    }

    // Ensure last day is included in multi-day bookings
    if (i === totalDays && !isNightShift) {
      currentEnd.set({
        hour: end.hour(),
        minute: end.minute(),
        second: end.second(),
      });
    }

    let newEvent = {
      ...event,
      eventId: event.id,
      id: `${event.id}-${currentStart.format("YYYY-MM-DD")}`,
      start: new DayPilot.Date(currentStart.format("YYYY-MM-DD HH:mm:ss")),
      end: new DayPilot.Date(currentEnd.format("YYYY-MM-DD HH:mm:ss")),
      tempStart: currentStart.tz(timezone).clone(),
      tempEnd: currentEnd.tz(timezone).clone(),
      phases: this.returnPhases(event,currentStart.tz(timezone).clone(),currentEnd.tz(timezone).clone(), timezone),
      startTime: moment(event.startTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment(event.endTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
      moveVDisabled: false,
      moveHDisabled: false,
      continue: i < totalDays,
    };

    const isDuplicate = mainArray.some(
      (existingEvent) =>
        existingEvent.id === newEvent.id &&
        moment(existingEvent.start).isSame(newEvent.tempStart) &&
        moment(existingEvent.end).isSame(newEvent.tempEnd)
    );

    if (!isDuplicate) {
      mainArray.push(newEvent);
    }
  }
  }



  return mainArray;
}












returnPhases(element,start,end,timezone){
  try {
   
    let phases = []
    let startTime =  start
    let endTime =  end
     if(element.bookingId == 33162){
      console.log(element)
      console.log(start.format("YYYY-MM-DD HH:mm:ss"))
      console.log(end)
      console.log(new DayPilot.Date(moment(startTime).subtract(element.startBumper,'hours').format("YYYY-MM-DD HH:mm:ss")))
    }

    if((element.startBumper && element.startBumper != 0) ||(element.endBumper && element.endBumper != 0) ){
      if(element.startBumper && element.startBumper != 0){
        let obj ={
          start: new DayPilot.Date(moment(startTime).subtract(element.startBumper,'hours').format("YYYY-MM-DD HH:mm:ss")),
          end:  new DayPilot.Date(moment(startTime).format("YYYY-MM-DD HH:mm:ss")),
          html:'',
          cssClass:'',
          main:false
        }
        phases.push(obj)
      }

      let obj ={
        start: new DayPilot.Date(moment(startTime).format("YYYY-MM-DD HH:mm:ss")),
        end: new DayPilot.Date(moment(endTime).format("YYYY-MM-DD HH:mm:ss")),
        html:'',
        background:'',
        cssClass:'',
        main:true
      }
      phases.push(obj)


      if(element.endBumper && element.endBumper != 0){
        let obj ={
          start: new DayPilot.Date(moment(endTime).format("YYYY-MM-DD HH:mm:ss")),
          end: new DayPilot.Date(moment(endTime).add(element.endBumper,'hours').format("YYYY-MM-DD HH:mm:ss")),
          background:'',
          html:'',
          cssClass:'',
          main:false
        }
        phases.push(obj)
      }

    }
    return phases
  } catch (error) {
    console.log(error)
  }


}




areArraysDifferent(array1: any[], array2: any[]): boolean {
if (array1.length !== array2.length) {
  return true;
}
const allElementsPresent = array2.every(elem => array1.includes(elem));
if (allElementsPresent) {
  return false;
}

for (let i = 0; i < array1.length; i++) {
  if (array1[i] !== array2[i]) {
    return true;
  }
}

return false;
}
 transformName(name: string): string {
  if (!name) {
    return '';
  }

  const parts = name.split(' ');
  if (parts.length === 1) {
    return name;
  }

  const firstNameInitial = parts[0].charAt(0).toUpperCase();
  const lastName = parts[parts.length - 1].toLowerCase();
  const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

  return `${firstNameInitial} ${formattedLastName}`;
}

changeBoxShadowColor(color) {
  let num: number;
  // Assuming the input color is in hex format, convert it to RGB
  if(color == '' ){
    color = '#007EBE'
  }
  if (color.startsWith("#")) {
      num = parseInt(color.slice(1), 16);
  } else if (color.startsWith("rgb")) {
      const rgbValues = color.match(/\d+/g);
      num = (parseInt(rgbValues[0]) << 16) + (parseInt(rgbValues[1]) << 8) + parseInt(rgbValues[2]);
  } else {
      throw new Error("Invalid color format. Please use hexadecimal or RGB format.");
  }

  const R = (num >> 16);
  const G = ((num >> 8) & 0x00ff);
  const B = (num & 0x0000ff);
  let newColor = `rgb(${R}, ${G}, ${B})`;
  let rgbColor = `rgb(${R + 30}, ${G + 30}, ${B + 30},0.25)`;

  return `${rgbColor} 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, ${newColor} 0px -2px 6px 0px inset`;
}

  getPackList(): any {
    return this.packList.getValue();
  }

  setPackList(data: any): void {
    this.packList.next(data);
  }

  setuserFilters(data:any){
    console.log(data)
    this.userFilters =  data;
  }

  getuserFilters(){
   return this.userFilters;
  }

  setProjectGSheet(data:any){
    this.projectGoogleSheetObj =  data;
  }

  getProjectGSheet(){
   return this.projectGoogleSheetObj;
  }

  settrackerPackgeList(arr:any){
    this.trackerPackgeList = arr
  }

  gettrackerPackgeList(){
    return this.trackerPackgeList
  }

  setClientGSheet(data:any){
    this.clientGoogleSheetObj =  data;
  }

  getClientGSheet(){
   return this.clientGoogleSheetObj;
  }

  setResourceGSheet(data:any){
    this.resourceGoogleSheetObj =  data;
  }

  getResourceGSheet(){
   return this.resourceGoogleSheetObj;
  }

  setPersonalGSheet(data:any){
    this.personalGoogleSheetObj =  data;
  }

  getPersonalGSheet(){
   return this.personalGoogleSheetObj;
  }

  getAllService(): any {
    return this.AllService.getValue();
  }

  setAllService(data: any): void {
    this.AllService.next(data);
  }


  getAllNetwork(): any {
    return this.AllNetwork.getValue();
  }

  setAllNetwork(data: any): void {
    this.AllNetwork.next(data);
  }

  getAllProjectData(): any {
    return this.getAllProject.getValue();
  }

  setAllProject(data: any): void {
    this.getAllProject.next(data);
  }

  getnotification(data:any){
    console.log('recevied')

     this.notificationRecieved.next(data);
  }


  getPlanningEventStatus(): any {
    return this.PlanningEventStatus.getValue();
  }

  setPlanningEventStatus(data: any): void {
    this.PlanningEventStatus.next(data);
  }

  getAllProjectSchedulerView(): any {
    return this.AllProjectSchedulerView.getValue();
  }

  setAllProjectSchedulerView(data: any): void {
    this.AllProjectSchedulerView.next(data);
  }

  getAllPhaseBookings(): any {
    return this.AllPhaseBookings.getValue();
  }

  SetAllPhaseBookings(data: any): void {
    this.AllPhaseBookings.next(data);
  }

  getMilestonesByProjectId(): any {
    return this.MilestonesByProjectId.getValue();
  }

  SetMilestonesByProjectId(data: any): void {
    this.MilestonesByProjectId.next(data);
  }

  getProjectsEvent(): any {
    return this.ProjectsEvent.getValue();
  }

  setProjectsEvent(data: any): void {
    this.ProjectsEvent.next(data);
  }

  getProjectResources(): any {
    return this.ProjectResources.getValue();
  }

  setProjectResources(data: any): void {
    this.ProjectResources.next(data);
  }

  getProjectBasicDetails(): any {
    return this.ProjectBasicDetails.getValue();
  }

  setProjectBasicDetails(data: any): void {
    this.ProjectBasicDetails.next(data);
  }

  getShowListForScheduler(): any {
    return this.ShowListForScheduler.getValue();
  }

  setShowListForScheduler(data: any): void {
    this.ShowListForScheduler.next(data);
  }

  getBudgetDropDown(): any {
    return this.BudgetDropDown.getValue();
  }

  setBudgetDropDown(data: any): void {
    this.BudgetDropDown.next(data);
  }

  getBudgetEvent(): any {
    return this.BudgetEvent.getValue();
  }

  setBudgetEvent(data: any): void {
    this.BudgetEvent.next(data);
  }


  getKitViewConfiguration(): any {
    return this.KitViewConfiguration.getValue();
  }

  setKitViewConfiguration(data: any): void {
    this.KitViewConfiguration.next(data);
  }

  getAllBookingEventStatus(): any {
    return this.AllBookingEventStatus.getValue();
  }

  setAllBookingEventStatus(data: any): void {
    this.AllBookingEventStatus.next(data);
  }

  getAllKitListByLoggedInUser(): any {
    return this.AllKitListByLoggedInUser.getValue();
  }

  getAllGlobalKitView(): any {
    return this.AllGlobalKitView.getValue();
  }
  setAllGlobalKitView(data: any): void {
    return this.AllGlobalKitView.next(data);
  }

  setAllKitListByLoggedInUser(data: any): void {
    this.AllKitListByLoggedInUser.next(data);
  }

  getAllLinkEventID(): any {
    return this.AllLinkEventID.getValue();
  }

  setAllLinkEventID(data: any): void {
    this.AllLinkEventID.next(data);
  }

  getProjectInfo(): any {
    return this.ProjectInfo.getValue();
  }

  setProjectInfo(data: any): void {
    this.ProjectInfo.next(data);
  }

  getAllAvailableFunction(): any {
    return this.AllAvailableFunction.getValue();
  }

  setAllAvailableFunction(data: any): void {
    this.AllAvailableFunction.next(data);
  }

  getProjectsForUserDefined(): any {
    return this.ProjectsForUserDefined.getValue();
  }

  setProjectsForUserDefined(data: any): void {
    this.ProjectsForUserDefined.next(data);
  }

  getglobalEquipment(): any {
    return this.globalEquipment.getValue();
  }

  setglobalEquipment(data: any): void {
    this.globalEquipment.next(data);
  }

  getglobalEquipmentDropdown(): any {
    return this.globalEquipmentDropdown.getValue();
  }

  setglobalEquipmentDropDown(data: any): void {
    this.globalEquipmentDropdown.next(data);
  }

  getProjectSubstatus(): any {
    return this.projectSubstatus.getValue();
  }

  setSubStatus(data: any): void {
    this.projectSubstatus.next(data);
  }

  getglobalConfigGroup(): any {
    return this.globalConfigGroup.getValue();
  }

  setglobalConfigGroup(data: any): void {
    this.globalConfigGroup.next(data);
  }

  getAllMarketPlace(): any {
    return this.allMarketPlace.getValue();
  }

  setAllMarketPlace(data: any): void {
    this.allMarketPlace.next(data);
  }

  getGetActiveRole(): any {
    return this.getActiveRole.getValue();
  }

  setGetActiveRole(data: any): void {
    this.getActiveRole.next(data);
  }

  getglobalPersonel(): any {
    return this.globalPersonel.getValue();
  }

  setglobalPersonel(data: any): void {
    let filterData =  data.filter(el => !el.delete)
    this.globalPersonel.next(filterData);
  }

  getglobalFuncions(): any {
    return this.globalFunction.getValue();
  }

  setglobalFuncions(data: any): void {
    this.globalFunction.next(data);
  }

  setglobalKeywords(data: any): void {
    this.globalKeywords.next(data);
  }
  
  getglobalKeywords(): any {
    return this.globalKeywords.getValue();
  }


  getglobalPersonelDropdown(): any {
    return this.globalPersonelDropdown.getValue();
  }

  setglobalPersonelDropDown(data: any): void {
    this.globalPersonelDropdown.next(data);
  }

  getglobalRateCard(): any {
    return this.globalRateCard.getValue();
  }

  setglobalRateCard(data: any): void {
    console.log(data)
    this.globalRateCard.next(data);
  }

  getglobalRateCardDropdown(): any {
    return this.globalRateCardDropdown.getValue();
  }

  setglobalRateCardDropDown(data: any): void {
    this.globalRateCardDropdown.next(data);
  }

  getglobalVendors(): any {
    return this.globalVendor.getValue();
  }

  setglobalVendors(data: any): void {
    this.globalVendor.next(data);
  }

  getglobalVendorDropdown(): any {
    return this.globalVendorDropdown.getValue();
  }

  setglobalVendorDropDown(data: any): void {
    this.globalVendorDropdown.next(data);
  }

  getglobalClients(): any {
    return this.globalClients.getValue();
  }

  multipleCallRemover(value,component){
    if(component == 'clientList'){
      this.clientChanged.next(value);
    } else if(component == 'equipmentList'){
      this.equipmentChanged.next(value);
    } else if(component == 'personalList'){
      this.personalChanged.next(value);
    }else if(component == 'function'){
      this.functionChanged.next(value);
    }
  }

  setglobalClients(data: any): void {
    this.globalClients.next(data);
  }

  getglobalClientsDropdown(): any {
    return this.globalClientsDropdown.getValue();
  }

  setglobalClientsDropDown(data: any): void {
    this.globalClientsDropdown.next(data);
  }

  setFunctionCatalogValue(val: Boolean) {
    this.functionCatalog.next(val);
  }

  setProjectDetail(val: Boolean) {
    this.ProjectDetailOpen.next(val);
  }



  public setgenericFunctionCatalog(val: Boolean) {
    this.genericFunctionCatalog = val;
    this.functionCatalog.next(true);
  }

  getgenericFucntionCatalog() {
    return this.genericFunctionCatalog;
  }

  public setNotificationFlag(val: Boolean) {
    this.genericNotificationFlag = val;
    this.notificationFlag.next(true);
  }

  getNotificationFlag() {
    return this.genericNotificationFlag;
  }

  public setLeaveApplied(val: Boolean) {
   
    this.genericLeaveApplied = val;
    this.LeaveApplied.next(true);
  }

  getLeaveApplied() {
    return this.genericLeaveApplied;
  }

  public setProjectDetailOpen(val: Boolean) {
    this.genericProjectDetailOpen = val;
    this.ProjectDetailOpen.next(true);
  }

  getProjectDetailOpen() {
    return this.genericProjectDetailOpen;
  }

  public setTableCopy(val: Boolean) {
    this.genericTableCopy = val;
    this.Tablecopy.next(true);
  }

  getTableCopy() {
    return this.genericTableCopy;
  }

  public setgenericChatbox(val: Boolean) {

    this.genericChatBox = val;
    this.chatBox.next(true);
  }

  getgenericChatbox() {

    return this.genericFunctionCatalog;
  }

  setShowBasicDetails(data: any) {
    this.showBasicDetails = data;
  }

  getShowBasicDetails(): any {
    return this.showBasicDetails;
  }


  setuserRole(data: any) {
    console.log(data)
    this.userRole = data;
  }

  getuserRole(): any {
    return this.userRole;
  }

  setkanbanData(data: any) {
    this.kanbanData = data;
  }

  setkanbanTODoData(data: any) {
    this.kanbanToDoData = data;
  }

  setkanbanDoneData(data: any) {
    this.kanbanDoneData = data;
  }

  getreportDatesData(): any {
    return this.reportDatesData;
  }

  setreportDatesData(data: any) {
    this.reportDatesData = data;
  }



  getkanbanData(): any {
    return this.kanbanData;
  }

  getkanbanToDoData(): any {
    return this.kanbanToDoData;
  }

  getkanbanDoneData(): any {
    return this.kanbanDoneData;
  }

  setbolbData(data: any) {
    this.bolbData = data;
  }

  getbolbData(): any {
    return this.bolbData;
  }

  setColorListData(data: any) {
    this.colorData = data;
    this.colorDataObs.next(true);
  }

  getColorListData(): any {
    return this.colorData;
  }

  setProjectStatusData(data: any) {
    this.projectStatusData = data;

    this.projectStatusDataObs.next(true);
  }

  getProjectStatusData(): any {
    return this.projectStatusData;
  }

  getLoggedInUserData = () => {
    
      let loggedInUserData = JSON.parse(sessionStorage.getItem('loggedInUserData')) || {};
      this.loggedInUserData = loggedInUserData;
      this.loggedInUserData.locale = this.getLocaleByLanguage( this.loggedInUserData.uiLanguage);
      return this.loggedInUserData || {};
    
  }

  getAllTimeZone = () => {
    return this.allTimeZone || []
  }

  getAllClient = () => {
   return  this.allClient || []
  }

  getAllCompanyDropdownForRole = () => {
    return  this.CompanyDropdownForRole || []
   }

  

  getLocaleByLanguage(lang) {

    if (lang==null || lang == undefined) 
    return"en-us";
    if (lang === "English")
      return "en-us";
    if (lang === "French")
      return "fr-fr";
    if (lang === "Italian")
      return "it-it";
    if (lang === "Arabian")
      return "en-us";
    if (lang === "Chinese")
      return "en-us";
    if (lang === "German")
      return "en-us";
    if (lang === "Spanish")
      return "en-us";

  }

  setLoggedInUserData(userData) {
    sessionStorage.setItem('loggedInUserData', JSON.stringify(userData));
    this.loggedInUserData = userData;
    if(userData.darkMode){
      this.theme.next("dark-mode");
    } else {
      this.theme.next("light-mode");
    }
  }

  

  setAllTimeZone = (userData) => {
    this.allTimeZone = userData;
  }

  setAllClient = (userData) => {
    this.allClient = userData;
  }

  setAllCompanyDropdownForRole = (userData) => {
      this.CompanyDropdownForRole  = userData
   }



  async setFcmToken() {
    try {
     let userId =  sessionStorage.getItem('userId')
      if(this.CurrentUserFcmToken == undefined || this.CurrentUserFcmToken == undefined || this.CurrentUserFcmToken == ''){
      return
      }
   
      const res: any = await this.commonUtilService.setFCMToken(this.CurrentUserFcmToken,userId);
      if(res){
        let userData = this.getLoggedInUserData()
        userData.fcmToken = this.CurrentUserFcmToken
        sessionStorage.setItem('loggedInUserData', JSON.stringify(userData));
        this.loggedInUserData = userData;
      }
     
    } catch (error) {
      console.log(error)
      this.toastr.warning(error.error.message);
      
    }
  }

  tempplateNotification(MessageTitle:string,MessageBody:string,userId:number){
    let staffList = []
    staffList = this.globalPersonel.getValue()
    if(staffList.length == 0){
      return
    }
  
    for (let i = 0; i < staffList.length; i++) {
          if(staffList[i].id == userId ){
            if(staffList[i].fcmToken != undefined && staffList[i].fcmToken != null){
              let obj = { 
                "notification": {
                 "title": MessageTitle, 
                 "body": MessageBody
                },
                "to" : staffList[i].fcmToken
               }
               console.log(obj)
              this.sendNotification(obj)
            }
          }
  
    }
     
  }

   sendNotification(UserData:any) {
    try {
      this.commonUtilService.sendPushNotification(UserData);
      
    } catch (error) {
      console.log(error)
      this.toastr.warning(error.error.message);
    }
  }

  getBusinessDatesCount(startDate, endDate) {
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    // alert(count);
    return count;
  }





  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }



  weekendEvents = [];
  weekendHolidays = []

  getWeekendEvents(startDate, endDate, id, num) {
    let checkhalfDay = false;
    if (num % 1 != 0) {
      checkhalfDay = true
    }
    for (var i = startDate; i <= endDate; i.add(1, 'd')) {
      let s = i.clone();
      let e = s.add(1, 'd');
      if (i.day() == 0 || i.day() == 6) {
        let week = i.isoWeek();
        let year = i.isoWeekYear();
        this.weekendEvents.push({ year: year, weekNo: week, start: moment(s), end: moment(e), weekend: true, halfDay: false });
      } else {
        let week = i.isoWeek();
        let year = i.isoWeekYear();
        if (checkhalfDay) {
          this.weekendEvents.push({ year: year, weekNo: week, start: moment(s), end: moment(e), weekend: false, halfDay: true });
        } else {
          this.weekendEvents.push({ year: year, weekNo: week, start: moment(s), end: moment(e), weekend: false, halfDay: false });
        }
        checkhalfDay = false
      }
    }
  }

  getWeekendHoliday(startDate, endDate) {

    for (var i = startDate; i <= endDate; i.add(1, 'd')) {
      if (i.day() == 0 || i.day() == 6) {
        // let d = new Date(i.getTime());
        let week = i.isoWeek();
        let year = i.isoWeekYear();
        this.weekendHolidays.push({ year: year, weekNo: week, date: moment(i).format(), weekend: true });
      } else {
        let week = i.isoWeek();
        let year = i.isoWeekYear();
        this.weekendHolidays.push({ year: year, weekNo: week, date: moment(i).format(), weekend: false });
      }
    }
  }

  overLapped = (a, b, c, timezone) => {
    let d = 0;
    if (a.overLap) {
      var rsd = moment(a.startTime).tz(timezone).valueOf();
      var red = moment(a.endTime).tz(timezone).valueOf();

      let ax = Math.max(0, Math.min(red, c) - Math.max(rsd, b));
      let h = (ax / (60000 * 60));
      d = h;
    }
    return d;
  }

  getOverLappedHolidays(hs, he, timezone, events) {

    let overLappedHolidays = events.filter(l => l.leave).filter(h => {
      let ls = moment(h.startTime).tz(timezone).valueOf();
      let le = moment(h.endTime).tz(timezone).valueOf();
      if ((hs < ls && he < ls) || (hs > le && he > le)) {
        return false;
      } else {
        h.overLap = true;
        return true;
      }
    }).map(a => this.overLapped(a, hs, he, timezone)).reduce((a, b) => a + b, 0);

    return overLappedHolidays;
  }

  durationFormater(seconds: number): string {
    let minutes = Math.floor((seconds / 60) | 0);
    let hours = Math.floor((minutes / 60) | 0);
    minutes = minutes % 60;
    seconds = seconds % 60;
    let result = hours + "H " + Math.abs(minutes) + "M";
    return result;

  }

  // syncGoogle(){
  //   gapi.load('client:auth2', () => {
  //     this.googleSync = new GoogleSync(this.userPrefService, this.httpService, this.toastr);
  //   this.syncGoogleCalender();

  //   });
  // }

  // syncGoogleCalender(){
  //   if(this.googleSync != null){
  //     this.googleSync.startSync()
  //   } else {
  //     setTimeout(() => {
  //       this.syncGoogleCalender()
  //     }, 500);
  //   }
  // }

  checkElementWithArray(value,array,key){
    return array.every(item => item[key] != value);
  }

   checkValueInArray(value, array, key, nestedKey) {
    return array.some(obj => {
        if (nestedKey && obj[key] && typeof obj[key] === 'object') {
            return obj[key][nestedKey] == value;
        } else if (obj[key] != null) {
          return obj[key] == value;
        }
      return false;
    });
}






  convertToSaveTZ(date,TimeZone){
   return moment(date).tz(TimeZone,true).format();
  }

  convertToFetchTZ(date,TimeZone){
    return moment(date).tz(TimeZone).format("YYYY-MM-DD HH:mm:ss");
   }

  async  exchangeCodeForTokens(authCode: string){
    const config = {
      auth: {
        clientId: '5f1fa8e6-dd4e-4613-bd2c-a4e5719e078f',  // Your client ID
        redirectUri: "https://prodigious/teamium/user/user-profile",
        postLogoutRedirectUri: "https://prodigious/teamium/user/user-profile",
        tenantId:'d52c9ea1-7c21-47b1-82a3-33a74b1f74b8',
        codeVerifier:sessionStorage.getItem('pkce_code_verifier')
    }
  };

    try {
      const res:any =  await this.commonUtilService.exchangeCodeForTokens(authCode,config)
      const refreshToken = res['refresh_token'];
      this.setRefreshToken(refreshToken)
    } catch (error) {
      console.log(error)
    }

  }


  async setRefreshToken(refreshToken){
    try {
      let userId =  sessionStorage.getItem('userId')
      const res1: any = await this.commonUtilService.setOutlookFreshToken(refreshToken,userId);
      if(res1){
        let userData = this.getLoggedInUserData()
        userData.outlookRefreshToken = refreshToken
        sessionStorage.setItem('loggedInUserData', JSON.stringify(userData));
        this.loggedInUserData = userData;
      }
    } catch (error) {
      console.log(error)
    }

  }


  generateCodeVerifier() {
    const array = new Uint8Array(32);
    crypto.getRandomValues(array);
    return Array.from(array)
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  }
  
   async generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest("SHA-256", data);
  
    const base64Url = (arrayBuffer) => {
      const base64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
      return base64.replace("+", "-").replace("/", "_").replace(/=+$/, "");
    };
  
    return base64Url(digest);
  }
   

   async signinOutlook() {

    const codeVerifier = this.generateCodeVerifier();
    const codeChallenge = await this.generateCodeChallenge(codeVerifier);

    sessionStorage.setItem('pkce_code_verifier', codeVerifier);

    const config = {
      auth: {
        clientId: '5f1fa8e6-dd4e-4613-bd2c-a4e5719e078f',  // Your client ID
        redirectUri: "https://prodigious/teamium/user/user-profile",
        postLogoutRedirectUri: "https://prodigious/teamium/user/user-profile",
        tenantId:'d52c9ea1-7c21-47b1-82a3-33a74b1f74b8'
    }
  };

  const  scopes = "Calendars.Read.Shared Calendars.ReadWrite Calendars.ReadWrite.Shared openid profile User.Read User.Read.All User.ReadBasic.All email offline_access"
     
  
  // offline_access to get a refresh token
  const authUrl = `https://login.microsoftonline.com/${config.auth.tenantId}/oauth2/v2.0/authorize?client_id=${config.auth.clientId}&response_type=code&redirect_uri=${encodeURIComponent(config.auth.redirectUri)}&response_mode=query&scope=${encodeURIComponent(scopes)}&state=12345&code_challenge=${encodeURIComponent(codeChallenge)}&code_challenge_method=S256`;
  // const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${config.auth.clientId}&response_type=code&redirect_uri=${encodeURIComponent(config.auth.redirectUri)}&response_mode=query&scope=${encodeURIComponent(scopes)}&state=12345`;
  
  window.location.href = authUrl;
    
}
  




   async getAccessTokenOutlook(refresh){
    const config = {
      auth: {
          clientId: '5f1fa8e6-dd4e-4613-bd2c-a4e5719e078f',  // Your client ID
          redirectUri: "https://prodigious/teamium/user/user-profile",
          postLogoutRedirectUri: "https://prodigious/teamium/user/user-profile",
          tenantId:'d52c9ea1-7c21-47b1-82a3-33a74b1f74b8'
      }
  };

  try {
    const res:any = await this.commonUtilService.refreshAccessToken(refresh,config)
    const newRefreshToken = res['refresh_token'] || refresh;
    this.setRefreshToken(newRefreshToken)
    return res['access_token'];
  } catch (error) {
    console.log(error)
  }
   }







  async getAllCalendars(refresh) {
    try {
      let token =  refresh
      const res:any = await this.commonUtilService.getAllCalendars(token)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  async createCalendars(calendarName,refresh) {
    try {
      let token =  refresh
      const newCalendar = {
        name: calendarName,
      };
      const res:any = await this.commonUtilService.createCalendars(token,newCalendar)
      return res
    } catch (error) {
      console.log(error)
    }
  }

createOrGetCalendar(calendarName: string,refresh) {
  return this.getAllCalendars(refresh).then((calendars) => {
    const existingCalendar = calendars.value.find(
      (calendar) => calendar.name === calendarName
    );

    if (existingCalendar) {
      console.log('Calendar already exists:', existingCalendar);
      return existingCalendar;
    } else {
      console.log('Calendar not found, creating a new one.');
      return this.createCalendars(calendarName,refresh);
    }
  });
}

async msalSignOut(){
  const config = {
    auth: {
        clientId: '21d3ace4-0ba4-4851-9ace-e856e43f0095',
        redirectUri: "http://localhost:4200/",
        postLogoutRedirectUri: "http://localhost:4200/",
    }
}

const myMsal = new Msal.UserAgentApplication(config);
myMsal.logout()
}

  async createMsalEvent(body,refresh){
    try {
      const url = window.location.hostname; 
      const domainParts = url.split('.');
      let subdomain = ''
      // If the URL has more than one part (subdomain exists)
      if (domainParts.length > 2) {
      subdomain = domainParts[0]; // First part should be the subdomain (e.g., 'prodigious')
      }
  
      const calendarName =  `Teamium x ${subdomain.toUpperCase()}`;
      let token = await this.getAccessTokenOutlook(refresh)
     let calendarId = await this.createOrGetCalendar(calendarName,token)
      // console.log(token)
      if(token != null && token != undefined){
        const res = await this.commonUtilService.createMsalEvent(token,body,calendarId);
      }
      
    } catch (error) {
      console.log(error)
      
    }
  }



  async deleteMsalEvent(eventid,refresh){
    try {
      
      const url = window.location.hostname; 
      const domainParts = url.split('.');
      let subdomain = ''
      // If the URL has more than one part (subdomain exists)
      if (domainParts.length > 2) {
      subdomain = domainParts[0]; // First part should be the subdomain (e.g., 'prodigious')
      }
  
      const calendarName =  `Teamium x ${subdomain.toUpperCase()}`;
      let token = await  this.getAccessTokenOutlook(refresh)
     let calendarId = await  this.createOrGetCalendar(calendarName,token)
      if(token != null && token != undefined){
      const res:any = await this.commonUtilService.deleteMsalEvent(token,eventid,calendarId)
      }
    } catch (error) {
      console.log(error)
      
    }
  }


  // shiprecAuth

 










 

  async signIn() {

 
        const res: any = await this.commonUtilService.getCalendarKey();
        this.googleData = res;

        if(this.googleData != undefined && this.googleData != null){
          if(this.googleData.googleClientkey == null || this.googleData.googleAPIkey == null || this.googleData.clientSecretKey == null ){
            return this.toastr.warning("Please set configuration data","Google")
          }
        }
   
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest","https://sheets.googleapis.com/$discovery/rest?version=v4"];
    var SCOPES = "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/spreadsheets";

    gapi.load('client', () => {
      gapi.client.init({
        'apiKey': this.googleData.googleAPIkey,
        // clientId and scope are optional if auth is not required.
        'clientId': this.googleData.googleClientkey,
        'scope': 'profile email ' + SCOPES,
        'discoveryDocs': DISCOVERY_DOCS,

      }).then(() => {
      
        let userData = this.getLoggedInUserData()
        if(userData.googleSync && userData.googleRefreshToken != undefined && userData.googleRefreshToken != null){
          this.getAccessToken(userData.googleRefreshToken)
      this.getCalender()

          
        } else{
        this.login()

        }
      }, e => {
        this.toastr.warning('Google sync', 'Unable to login or not enough permissions');
      console.log('Google sync',e);

       
      });

      gapi.client.load('calender', 'v3', () => console.log(''))
      
    })

  }

  async login() {
    
    const googleAuth =  gapi.auth2.getAuthInstance();
    googleAuth.grantOfflineAccess()
    .then((res) => {
      this.fetchToken(res.code)
      this.getCalender()

       console.log(res);
      
    });
    // if(googleAuth.isSignedIn.get()){
      
    //   this.getCalender()
    // } else {
    //    const googleuser = await googleAuth.signIn()
    //    this.getCalender()
    // }
  }

  async fetchToken(code:any){
    try {
      let body ={
        grant_type:'authorization_code',
        code:code,
        client_id: this.googleData.googleClientkey,
        client_secret:this.googleData.clientSecretKey,
        redirect_uri:`${window.location.protocol}//${window.location.hostname}`
      }
      if(window.location.hostname == 'localhost'){
        body.redirect_uri = `${window.location.protocol}//${window.location.hostname}:4200`
      }

      const res:any = await this.commonUtilService.fetchGoogleToken(body)
      sessionStorage.setItem('google_access_token',res.access_token)
      sessionStorage.setItem('google_refresh_token',res.refresh_token)
      this.setGoogleRefershToken(res.refresh_token)
    } catch (error) {
      console.log(error)
    }
  }

  async setGoogleRefershToken(refresh_token:any){
    try {
      let userId =  sessionStorage.getItem('userId')
      const res:any = await this.commonUtilService.setGoogleFreshToken(refresh_token,userId)
      
    } catch (error) {
      console.log(error) 
    }
  }

  async getAccessToken(refresh_token:String){
    try {
      console.log(this.googleData)
        // const res1: any = await this.commonUtilService.getCalendarKey();
        // this.googleData = res1;

      let body ={
        grant_type:'refresh_token',
        client_id: this.googleData.googleClientkey,
        client_secret:this.googleData.clientSecretKey,
        refresh_token: refresh_token
      }
     

      const res:any = await this.commonUtilService.fetchGoogleToken(body);
      gapi.client.setToken({access_token: res.access_token})

    } catch (error) {
      console.log(error)
    }
  }

  async getcalenderListwithToken(userbooking:any,refresh_token:String){
    try {
      console.log('recevore')
      await this.getAccessToken(refresh_token);

      const url = window.location.hostname; 
      const domainParts = url.split('.');
      let subdomain = ''
      // If the URL has more than one part (subdomain exists)
      if (domainParts.length > 2) {
      subdomain = domainParts[0]; // First part should be the subdomain (e.g., 'prodigious')
      }
  
      const calendarName =  `Teamium x ${subdomain.toUpperCase()}`;
     
      let customCalendarId = await this.getOrCreateCustomCalendar(calendarName);

      var request = await gapi.client['calendar'].events.insert({
        "sendNotifications": true,
        "sendUpdates": "all",
        'calendarId': customCalendarId,
        'resource': userbooking,
        
      });

    
     
    } catch (error) {
      if(error.status == '409'){
        this.updateBooking(userbooking)
      }
      console.log(error)
    }
  }

  // Helper function to create/retrieve the custom calendar
async getOrCreateCustomCalendar(calendarName: string): Promise<string> {
  // Step 1: Get the list of existing calendars
  const calendars = await gapi.client['calendar'].calendarList.list({});
  
  // Step 2: Check if the custom calendar already exists
  const existingCalendar = calendars.result.items.find((cal: any) => cal.summary === calendarName);

  if (existingCalendar) {
    // Custom calendar exists, return its ID
    return existingCalendar.id;
  } else {
    // Step 3: Create a new custom calendar
    const newCalendar = await gapi.client['calendar'].calendars.insert({
      'summary': calendarName,
        timeZone: this.loggedInUserData.userSettingDTO.timezone  
    });

    console.log('Custom calendar created:', newCalendar);
    return newCalendar.result.id;
  }
}






  async logout(){
    const googleAuth = gapi.auth2.getAuthInstance()
    const signout = await googleAuth.signOut()
  }

  async getCalender() {
    if(gapi.client != undefined){
    let date = new Date()
    let start_date = new Date(date.getFullYear(), date.getMonth() -2, 1)
    let end_date = new Date(date.getFullYear(), date.getMonth()+2, 1);
    const events: any = await gapi.client['calendar'].events.list({
      'calendarId': 'primary', "singleEvents": true, "orderBy": "startTime",
      "timeMin": start_date.toISOString(),
      "timeMax": end_date.toISOString(),
    })

    this.calenderData = events.result.items;
    sessionStorage.setItem('calenderData',JSON.stringify(this.calenderData))
  }
  }

  async specificEventStatus(Eventid:any,refresh_token:String) {
    try {
      if(gapi.client != undefined){
        await this.getAccessToken(refresh_token);
        const events: any = await gapi.client['calendar'].events.get({
          "calendarId": "primary",
          "eventId": Eventid
        })
        console.log(events)
  
        return events.result
      } else {
        let arr = []
        return arr
      }
  

    } catch (error) {
      console.log(error)
    }
   

    
  }

   checkOverlap(start1, end1, start2, end2) {
    return start1.isBefore(end2) && start2.isBefore(end1);
}


  async setMeetingLink(userbooking:any,refresh_token:String){
    if(gapi.client != undefined){
    try {
      await this.getAccessToken(refresh_token);
      var request = await gapi.client['calendar'].events.insert({
        "sendNotifications": true,
        "sendUpdates": "all",
        'calendarId': 'primary',
        'resource': userbooking,
        "conferenceDataVersion": 1,
        
      });

  
    } catch (error) {
      console.log(error)
    }
  }
  }




  async InsertData(userBooking: any,reload) {
    if(gapi.client != undefined){
    var request = await gapi.client['calendar'].events.insert({
      'calendarId': 'primary',
      'resource': userBooking
    });
    if(reload == true){
      this.getCalender()
    }
  }
    

  }

  async bulkPush(events: any) {
    if(gapi.client != undefined){
    const batch = gapi.client.newBatch();
    console.log(batch)
    events.map((r, j) => {
      batch.add(gapi.client['calendar'].events.insert({
        'calendarId': 'primary',
        'resource': events[j]
      }))
    })
 
  }

  }

  async syncPreviousBooking(events,agendaDate){
   
    
    const url = window.location.hostname; 
    const domainParts = url.split('.');
    let subdomain = ''
    // If the URL has more than one part (subdomain exists)
    if (domainParts.length > 2) {
    subdomain = domainParts[0]; // First part should be the subdomain (e.g., 'prodigious')
    }

    const calendarName =  `Teamium x ${subdomain.toUpperCase()}`;
   
    let customCalendarId = await this.getOrCreateCustomCalendar(calendarName);

    let date = new Date(agendaDate)
    let start_date = new Date(date.getFullYear(), date.getMonth() -2, 1)
    let end_date = new Date(date.getFullYear(), date.getMonth()+2, 1);
    const eventsData: any = await gapi.client['calendar'].events.list({
      'calendarId': customCalendarId, "singleEvents": true, "orderBy": "startTime",
      "timeMin": start_date.toISOString(),
      "timeMax": end_date.toISOString(),
    })

    const teamiumEvents =  eventsData.result.items.filter((cal: any) => cal.id.includes("tmbooking")) || [];
    console.log(teamiumEvents,events)
    let batch = gapi.client.newBatch(); // Initialize the batch request
    for (let i = 0; i < events.length; i++) {
      if(events[i].event.bookingId && events[i].event.meetingLineId == undefined){
        let index =  teamiumEvents.findIndex(el => el.id.split("tmbooking")[1] == events[i].event.bookingId)
        if(index == -1){
        let  event = {
            id: `tmbooking${events[i].event.bookingId}`,
            summary: events[i].heading,
            description: events[i].finalText,
            start: {
              dateTime: moment(events[i].event.startTime)
                .tz(events[i].event.timeZone)
                .format(),
              timeZone: events[i].event.timeZone,
            },
            end: {
              dateTime: moment(events[i].event.endTime)
                .tz(events[i].event.timeZone)
                .format(),
              timeZone: events[i].event.timeZone,
            },
          
          };
          batch.add(gapi.client['calendar'].events.insert({
            'calendarId': customCalendarId,
            'resource': event,
          }));

        }
      }
      
    }

    batch.execute(function (responseMap) {
      console.log("Batch execution response:", responseMap);
    });



  }



  async deleteApi(organizerIdData,eventId){
    if (organizerIdData.googleSync && organizerIdData.googleRefreshToken != undefined) {
      if(gapi.client != undefined){
        await this.getAccessToken(organizerIdData.googleRefreshToken);
  
        const url = window.location.hostname; 
        const domainParts = url.split('.');
        let subdomain = ''
        // If the URL has more than one part (subdomain exists)
        if (domainParts.length > 2) {
        subdomain = domainParts[0]; // First part should be the subdomain (e.g., 'prodigious')
        }
    
        const calendarName =  `Teamium x ${subdomain.toUpperCase()}`;
       
        let customCalendarId = await this.getOrCreateCustomCalendar(calendarName);
       
          const resAny =  await gapi.client['calendar'].events.delete({
            'calendarId': customCalendarId,
            'eventId':  `tmbooking${eventId}`
          });
        
      }
    }
 

  }

  async updateBooking(userBooking:any){
    if(gapi.client != undefined){

      
      const url = window.location.hostname; 
      const domainParts = url.split('.');
      let subdomain = ''
      // If the URL has more than one part (subdomain exists)
      if (domainParts.length > 2) {
      subdomain = domainParts[0]; // First part should be the subdomain (e.g., 'prodigious')
      }
  
      const calendarName =  `Teamium x ${subdomain.toUpperCase()}`;
     
      let customCalendarId = await this.getOrCreateCustomCalendar(calendarName);

    const resAny =  await gapi.client['calendar'].events.update({
      'calendarId': customCalendarId,
      'eventId': userBooking.id,
      'resource': userBooking
    });

    
    
  

}

  }

  removeDuplicates(array, property) {
    const uniqueMap = new Map();
  
    for (const item of array) {
      const key = item[property];
  
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, item);
      }
    }
  
    const uniqueArray = [...uniqueMap.values()];
  
    return uniqueArray;
  }

  isEvenDay(date: Date): boolean {
    const day = date.getDate();
    return day % 2 === 0;
  }

   mergeObjects = (...objects) => {
    const result = {};

    
    for (const obj of objects) {
      if(obj != undefined)
      for (const [key, value] of Object.entries(obj)) {
        if (value != null && !(key in result)) {
          if(Array.isArray(value)){
            if(value.length != 0)
            result[key] = value;
          } else {
            result[key] = value;
            
          }
        }
      }
    }
    return result;
  };


  getPersonnelDetailById(id,data,summary,description) {
    this.httpService
      .callApi("getPersonnelById", { pathVariable: id })
      .subscribe(
        (response) => {
          this.setBookingGoogleCal(response,data,summary,description)
          this.setmsalbooking(response,data,summary,description)
        },
        (error) => {
          console.log(error)
        }
      );
  }

  getPersonnelDetailByIdDelete(id,data) {
    this.httpService
      .callApi("getPersonnelById", { pathVariable: id })
      .subscribe(
        (response) => {
          this.deleteApi(response,data)
        },
        (error) => {
          console.log(error)
        }
      );
  }

  setBookingGoogleCal(staff: any, assignedData: any,summary,description){
    let event = null;
        let organizerIdData = staff
        console.log(assignedData)
        if (organizerIdData) {
          event = {
            id: `tmbooking${assignedData.bookingId}`,
            summary: summary,
            description: description,
            start: {
              dateTime: moment(assignedData.startTime)
                .tz(assignedData.timeZone)
                .format(),
              timeZone: assignedData.timeZone,
            },
            end: {
              dateTime: moment(assignedData.endTime)
                .tz(assignedData.timeZone)
                .format(),
              timeZone: assignedData.timeZone,
            },
          
          };

          if (organizerIdData.googleSync && organizerIdData.googleRefreshToken != undefined) {
            this.getcalenderListwithToken(
              event,
              organizerIdData.googleRefreshToken
            );
          }
        }
      
    
  }

  setmsalbooking(staff: any, assignedData: any,summary,description) {
    let event = {};

      event = {
        subject: summary,
        body: {
          contentType: "HTML",
          content: description,
        },
        start: {
          dateTime: moment(assignedData.startTime)
          .tz(assignedData.timeZone)
          .format(),
          timeZone: assignedData.timeZone,
        },
        end: {
          dateTime: moment(assignedData.endTime)
          .tz(assignedData.timeZone)
          .format(),
          timeZone: assignedData.timeZone,
        },
        allowNewTimeProposals: true,
        id: `tmbooking${assignedData.bookingId}`,
      };
      if (staff.outlookSync) {
      
          this.createMsalEvent(
            event,staff.outlookRefreshToken);
        
      }
     
    

    // this.sharedService.createMsalEvent(event);
  }


}




